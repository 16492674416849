import { storyblokEditable } from "@storyblok/react";
import Text from "../../../Core/Text";
import * as styles from './TableHead.module.scss';

const TableHead = ({ blok }) => {
  return (
    <th
      data-id={blok._uid}
      {...storyblokEditable(blok)}
      scope="col"
      className="px-4 py-3 lg:px-6 lg:py-4"
    >
      <Text
        as="body2"
        className={`${styles.sTableHead} s-tableHead w-full`}
      >
        {blok.value}
      </Text>
    </th>
  );
};

export default TableHead;
