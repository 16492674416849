export const SKY_STATUS_MAPPING = {
  UNDEF: {
    fr: "Non Indéfini",
    icon: "",
  },
  SUNNY: {
    fr: "Soleil",
    icon: "icon-sunny",
  },
  MOSTLY_SUNNY: {
    fr: "Soleil voilé",
    icon: "icon-mostly-sunny",
  },
  FAIR_WEATHER_PARTLY_CLOUDY: {
    name: "Belles éclaircies, passages nuageux",
    icon: "icon-faire-weather-partly-cloudy",
  },
  MOSTLY_CLOUDY_SOME_SUN: {
    name: "Très nuageux, courtes éclaircies",
    icon: "icon-mostly-cloudy-some-sun",
  },
  CLOUDY_LIGHT_RAIN: {
    name: "Ciel variable, faibles averses de pluies",
    icon: "icon-cloudy-light-rain",
  },
  MOSTLY_CLOUDY_LIGHT_RAIN: {
    name: "Couvert, pluies faibles",
    icon: "icon-cloudy-light-rain",
  },
  CLOUDY: {
    name: "Couvert",
    icon: "icon-cloudy",
  },
  VARIABLE_SKY_LIGHT_SNOWFALL: {
    name: "Ciel Variable, faibles chutes de neige",
    icon: "icon-variable-light-snowfall",
  },
  CLOUDY_LIGHT_SNOWFALL: {
    name: "Couvert, faibles chutes de neige",
    icon: "icon-cloudy-light-snowfall",
  },
  CLOUDY_MODERATE_HEAVY_SNOWFALL: {
    name: "Couvert, chutes de neige modérées ou fortes",
    icon: "icon-cloudy-moderate-heavy-snowfall",
  },
  CLOUDY_MODERATE_HEAVY_RAIN: {
    name: "Couvert, pluies modérées ou fortes",
    icon: "icon-cloudy-moderate-heavy-rain",
  },
  FOG: {
    name: "Brouillards",
    icon: "icon-fog",
  },
  THUNDERSTORMS: {
    name: "Orages",
    icon: "icon-thunderstorms",
  },
  VARIABLE_SKY_SCATTERED_THUNDERSTORMS: {
    name: "Ciel variable, orages isolés",
    icon: "icon-variable-scattered-thunderstorms",
  },
};

export const getSkyStatus = (status) => {
  return SKY_STATUS_MAPPING[status];
};

export const AVALANCHE_RISK_MAPPING = {
  UNDEF: {
    fr: "Non Indéfini",
    level: 0,
    icon: "",
  },
  LOW: {
    fr: "Faible",
    level: 1,
    icon: "icon-avalanche",
  },
  MODERATE: {
    fr: "Limité",
    level: 2,
    icon: "icon-avalanche",
  },
  CONSIDERABLE: {
    fr: "Marqué",
    level: 3,
    icon: "icon-avalanche",
  },
  HIGH: {
    fr: "Fort",
    level: 4,
    icon: "icon-avalanche",
  },
  VERY_HIGH: {
    fr: "Très fort",
    level: 5,
    icon: "icon-avalanche",
  },
};

export const getAvalancheRisk = (risk) => {
  return AVALANCHE_RISK_MAPPING[risk];
};

export const LIFT_TYPE_MAPPING = {
  UNDEF: {
    fr: "Non Indéfini",
    icon: "",
  },
  GONDOLA: {
    fr: "Télécabine",
    icon: "icon-piste-24",
  },
  CABRIOLET_GONDOLA: {
    fr: "Télécabine",
    icon: "icon-piste-24",
  },
  CHAIRLIFT: {
    fr: "Télésiège",
    icon: "icon-tele-siege",
  },
  DETACHABLE_CHAIRLIFT: {
    fr: "Télésiège débrayable",
    icon: "icon-tele-siege",
  },
  DETACHABLE_BUBBLE_CHAIRLIFT: {
    fr: "Télésiège débrayable à bulle",
    icon: "icon-tele-siege",
  },
  ROPE_TOW: {
    fr: "Tire-fesse",
    icon: "icon-tire-fesse",
  },
  MAGIC_CARPET: {
    fr: "Tapis roulant",
    icon: "icon-tapis-roulant",
  },
  SURFACE_LIFT: {
    fr: "Téléski",
    icon: "icon-tire-fesse",
  },
};

export const getLiftTypeIcon = (type) => {
  return LIFT_TYPE_MAPPING[type];
};
