import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Head from "next/head";
import React from "react";
import ImageHeader from "@/public/webcam-bg.png";
import ImageBGBottom from "@/public/relief.png";
import ImageBGTop from "@/public/relief-beige.png";
import Text from "../../../Core/Text";
import * as styles from "./Cms.module.scss";
import Image from "next/image";
import dynamic from "next/dynamic";
import LangSwitchBlock from "@/common/components/LangSwitchBlock";
import useTranslation from "next-translate/useTranslation";
const DynamicFollowBlock = dynamic(
  () => import("@/common/components/FollowBlock"),
  {
    loading: () => <p>Loading...</p>,
  }
);

export default function CmsPage({ blok, settings, dataConfig, nestedData }) {
  const { t } = useTranslation("common");

  let title =
    blok.hero && blok.hero[1]
      ? blok.hero[1]?.title + ` - ${t("courchevel")}`
      : blok.hero && blok.hero[0]
      ? blok.hero[0].title + ` - ${t("courchevel")}`
      : t("courchevel");
  let description =
    blok.hero && blok.hero[1]
      ? blok.hero[1]?.title + ` - ${t("courchevel")}`
      : blok.hero && blok.hero[0]
      ? blok.hero[0].title + ` - ${t("courchevel")}`
      : t("courchevel");
  let robots = "";
  if (dataConfig?.meta_title) {
    title = dataConfig.meta_title;
  }
  if (dataConfig?.meta_description) {
    description = dataConfig.meta_description;
  }
  if (dataConfig?.hide_from_search) {
    robots = dataConfig.hide_from_search
      ? "noindex, nofollow"
      : "index, follow";
  }
  //console.log("cmsblok", blok, dataConfig);

  const faq = blok?.faq && blok.faq[0];

  return (
    <div className={styles.cmsPage} {...storyblokEditable(blok)}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        {robots && <meta name="robots" content={robots} />}
      </Head>
      <div className={`${styles.cmsPageContent} relative`}>
        <div
          className="absolute top-[30%] md:top-[55%] lg-bottom- bottom-[10%] left-0 right-0 z-0 bg-no-repeat bg-background-beigeDark bg-contain"
          style={{
            backgroundImage: `url(${ImageBGBottom.src})`,
            backgroundPosition: "bottom center",
          }}
        >
          <Image
            className={`absolute top-0 left-0 object-contain w-full h-auto -translate-y-[100%]`}
            src={ImageBGTop}
            alt={"missing alt"}
          />
        </div>

        <div className={`${styles.heroSingle} relative`}>
          <div
            className={`${styles.heroSingleSlide} relative lg:rounded-2xl overflow-hidden`}
          >
            <Image
              className={`absolute top-0 left-0 object-cover w-full h-full`}
              src={
                blok?.hero[0]?.Image?.filename
                  ? blok.hero[0].Image.filename
                  : ImageHeader
              }
              width={1920}
              height={720}
              alt={"missing alt"}
            />
          </div>
        </div>
        <div className="container relative">
          <Text as="h2" tag="h1" className="text-h1 my-6 lg:mt-12 lg:mb-16">
            {blok?.hero[0]?.title ? blok.hero[0].title : dataConfig.name}
          </Text>
          {blok.showLang && <LangSwitchBlock />}
          <div className={"max-w-[675px] m-auto pb-20"}>
            {blok.content &&
              blok.content.map((blok, index) => {
                return <StoryblokComponent key={blok._uid} blok={blok} />;
              })}
            {/* <div className={`${styles.cms}`}>
              <Text as="body2" tag="div">
                <p>
                  Nom du site web :{" "}
                  <a href="https://www.courchevel.com">www.courchevel.com</a>{" "}
                  <br />
                  Adresse :{" "}
                  <a href="https://www.courchevel.com">
                    https://www.courchevel.com
                  </a>{" "}
                  <br />
                  Propriétaire : Courchevel Tourisme <br />
                  Responsable de la publication : Alexia LAINE <br />
                  Conception : <a href="www.wiz.fr">WIZ</a> <br />
                  Hébergement : <a href="www.ovh.com">OVH</a> <br />
                </p>
              </Text>
              <Text as="h4" tag="h2" className="mt-10 lg:mt-20 mb-2">
                <p>Titre 1</p>
              </Text>
              <Text as="body1" tag="div">
                <p>
                  Vous connaissez les pistes bleues, les pistes rouges ou encore
                  les pistes noires. Mais connaissez-vous la piste haute en
                  couleurs, tracée par des artistes chevronnés ? C’est celle que
                  les guides du Pays d’art et d’histoire avec la Fondation Facim
                  vous invitent à découvrir.
                </p>
              </Text>
              <Text as="body2" tag="div">
                <p>
                  Vous connaissez les pistes bleues, les pistes rouges ou encore
                  les pistes noires. Mais connaissez-vous la piste haute en
                  couleurs, tracée par des artistes chevronnés ? C’est celle que
                  les guides du Pays d’art et d’histoire avec la Fondation Facim
                  vous invitent à découvrir.
                </p>
                <p>
                  Facile et accessible, cette promenade à pied au cœur de la
                  station est balisée par des sculptures monumentales de Lorenzo
                  Quinn, et des œuvres de street-art sur le domaine skiable et
                  dans le centre-station de Courchevel 1850. Exposées en plein
                  air, façon musée à ciel ouvert, elles proposent des reflets et
                  des contours changeants en fonction des conditions météo, pour
                  mieux vous surprendre au tournant.
                </p>
              </Text>
              <Text as="h4" tag="h2" className="mt-10 lg:mt-20 mb-2">
                <p>Titre 2</p>
              </Text>
              <Text as="body1" tag="div">
                <p>
                  Vous connaissez les pistes bleues, les pistes rouges ou encore
                  les pistes noires. Mais connaissez-vous la piste haute en
                  couleurs, tracée par des artistes chevronnés ? C’est celle que
                  les guides du Pays d’art et d’histoire avec la Fondation Facim
                  vous invitent à découvrir.
                </p>
              </Text>
              <Text as="body2" tag="div">
                <p>
                  Facile et accessible, cette promenade à pied au cœur de la
                  station est balisée par des sculptures monumentales de Lorenzo
                  Quinn, et des œuvres de street-art sur le domaine skiable et
                  dans le centre-station de Courchevel 1850. Exposées en plein
                  air, façon musée à ciel ouvert, elles proposent des reflets et
                  des contours changeants en fonction des conditions météo, pour
                  mieux vous surprendre au tournant.Vous connaissez les pistes
                  bleues, les pistes rouges ou encore les pistes noires. Mais
                  connaissez-vous la piste haute en couleurs, tracée par des
                  artistes chevronnés ? C’est celle que les guides du Pays d’art
                  et d’histoire avec la Fondation Facim vous invitent à
                  découvrir. Facile et accessible, cette promenade à pied au
                  cœur de la station est balisée par des sculptures monumentales
                  de Lorenzo Quinn, et des œuvres de street-art sur le domaine
                  skiable et dans le centre-station de Courchevel 1850. Exposées
                  en plein air, façon musée à ciel ouvert, elles proposent des
                  reflets et des contours changeants en fonction des conditions
                  météo, pour mieux vous surprendre au tournant.
                </p>
                <p>
                  Facile et accessible, cette promenade à pied au cœur de la
                  station est balisée par des sculptures monumentales de Lorenzo
                  Quinn, et des œuvres de street-art sur le domaine skiable et
                  dans le centre-station de Courchevel 1850. Exposées en plein
                  air, façon musée à ciel ouvert, elles proposent des reflets et
                  des contours changeants en fonction
                </p>
              </Text>
              <Text as="h4" tag="h2" className="mt-10 lg:mt-20 mb-2">
                <p>Titre 3</p>
              </Text>
              <Text as="body1" tag="div">
                <p>
                  Vous connaissez les pistes bleues, les pistes rouges ou encore
                  les pistes noires. Mais connaissez-vous la piste haute en
                  couleurs, tracée par des artistes chevronnés ? C’est celle que
                  les guides du Pays d’art et d’histoire avec la Fondation Facim
                  vous invitent à découvrir.
                </p>
              </Text>
              <Text as="body2" tag="div">
                <p>
                  Facile et accessible, cette promenade à pied au cœur de la
                  station est balisée par des sculptures monumentales de Lorenzo
                  Quinn, et des œuvres de street-art sur le domaine skiable et
                  dans le centre-station de Courchevel 1850. Exposées en plein
                  air, façon musée à ciel ouvert, elles proposent des reflets et
                  des contours changeants en fonction des conditions météo, pour
                  mieux vous surprendre au tournant.Vous connaissez les pistes
                  bleues, les pistes rouges ou encore les pistes noires. Mais
                  connaissez-vous la piste haute en couleurs, tracée par des
                  artistes chevronnés ? C’est celle que les guides du Pays d’art
                  et d’histoire avec la Fondation Facim vous invitent à
                  découvrir. Facile et accessible, cette promenade à pied au
                  cœur de la station est balisée par des sculptures monumentales
                  de Lorenzo Quinn, et des œuvres de street-art sur le domaine
                  skiable et dans le centre-station de Courchevel 1850. Exposées
                  en plein air, façon musée à ciel ouvert, elles proposent des
                  reflets et des contours changeants en fonction des conditions
                  météo, pour mieux vous surprendre au tournant.
                </p>
                <p>
                  Facile et accessible, cette promenade à pied au cœur de la
                  station est balisée par des sculptures monumentales de Lorenzo
                  Quinn, et des œuvres de street-art sur le domaine skiable et
                  dans le centre-station de Courchevel 1850. Exposées en plein
                  air, façon musée à ciel ouvert, elles proposent des reflets et
                  des contours changeants en fonction
                </p>
              </Text>
            </div> */}
          </div>
        </div>
      </div>

      {blok.cross_content &&
        blok.cross_content.map((crossContentBlock) => (
          <StoryblokComponent
            blok={crossContentBlock}
            key={`ccbi-${crossContentBlock._uid}`}
            nestedData={nestedData}
          />
        ))}

      {/* <DynamicFollowBlock /> */}

      {faq && <StoryblokComponent blok={faq} />}
    </div>
  );
}
