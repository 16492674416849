import React, { useEffect, useState } from "react";
import Image from "next/image";
import * as styles from "./GuideLink.module.scss";
import * as stylesCard from "../Card.module.scss";
import Text from "../../Core/Text";
import ArrowLink from "../../Core/ArrowLink";
import Link from "next/link";
import { SEASON_WINTER } from "@/utils/global";
import { storyblokEditable } from "@storyblok/react";
import CustomLink from "../../CustomLink";

export default function GuideLink(props) {
  const { blok, className } = props;

  const [imageBySeason, setImageBySeason] = useState(null);

  useEffect(() => {
    let seasonMode = SEASON_WINTER;

    if (typeof window !== "undefined") {
      seasonMode = localStorage.getItem("seasonMode");
    }

    const images = blok.image?.length > 0 ? blok.image : [blok.image];
    const image =
      seasonMode === SEASON_WINTER
        ? images[0]
        : images.length > 1
          ? images[1]
          : images[0];

    setImageBySeason(image);
  }, [blok.image]);

  return (
    <CustomLink
      link={blok.CTA[0]?.link}
      className={`${className} ${stylesCard.card} ${styles.GuideLink}} border-b border-filet relative flex-1 lg:flex flex-row-reverse justify-between lg:gap-x-3 lg:pt-6 lg:pb-6`}
      {...storyblokEditable(blok)}
    >
      <div
        className={`${stylesCard.cardImage} ${styles.cardImage} relative overflow-hidden rounded-md mb-4 bg-gray-100 lg:mb-0 lg:col-span-4`}
      >
        {imageBySeason?.filename && (
          <Image
            src={imageBySeason.filename}
            alt={imageBySeason.alt || "missing alt"}
            fill
            sizes="(max-width: 768px) 80vw, (max-width: 1024px) 50vw, 20vw"
            className="object-cover"
          />
        )}
      </div>
      <div className="pb-6 lg:pb-2 lg:flex flex-col lg:col-span-4 lg:pl-4">
        <div className="lg:max-w-md">
          <Text as="h6" tag="h3" className={`${styles.cardTitle} mb-2`}>
            {blok.title || ""}
          </Text>
          <Text
            as="body2"
            className={`pr-6 line-clamp-3 text-text-h4 lg:line-clamp-none`}
          >
            {blok?.Description || ""}
          </Text>
        </div>
        {blok.CTA && blok.CTA[0] && (
          <ArrowLink className="mt-4 lg:mt-auto text-buttonColor-primary">
            {blok.CTA[0].label}
          </ArrowLink>
        )}
      </div>
    </CustomLink>
  );
}
