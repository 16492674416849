import React from "react";
import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";
import Text from "@/common/components/Core/Text";
import * as styles from "../InfoBar.module.scss";
import CustomLink from "@/common/components/CustomLink";

export default function InfoBarItem(props) {
  const { blok } = props;
  //console.log(blok)

  return (
    <div className={`${styles.infoBarItem} py-2`} {...storyblokEditable(blok)}>
      {blok.cta && blok.cta.cached_url ? (
        <CustomLink link={blok?.cta} alt={blok.cta.label}>
          <Text as="ui2">{blok.title}</Text>
        </CustomLink>
      ) : (
        <Text as="ui2">{blok.title}</Text>
      )}
    </div>
  );
}
