import { createRef, useCallback, useEffect, useState } from "react";
import Image from "next/image";
import { storyblokEditable } from "@storyblok/react";
import Text from "../../Core/Text";
import ArrowLink from "../../Core/ArrowLink";
import sampleBgImage from "@/public/webcam-bg.png";
import * as styles from "./LivecamsBlock.module.scss";
import useEmblaCarousel from "embla-carousel-react";
import { upperCaseFirstLetter, getLangIsoCode } from "@/utils/format";
import Icon from "@/utils/Icon";
import { flushSync } from "react-dom";
import { getAvalancheRisk, getSkyStatus } from "@/utils/weather";
import useTranslation from "next-translate/useTranslation";
import { SEASON_SUMMER, SEASON_WINTER } from "@/utils/global";
import Link from "next/link";
import { useDrawers } from "@/common/components/DrawerStack";
import dynamic from "next/dynamic";
import CustomLink from "../../CustomLink";
import {
  CLOSED_STATUS,
  ISSUE_STATUS,
  OPEN_STATUS,
} from "../Pages/DrawerPage/TrailModule";
import { useRouter } from "next/router";

const DynamicDrawerMeteo = dynamic(
  () => import("@/common/components/Modals/DrawerMeteo"),
  {
    ssr: false,
  }
);
const DynamicDrawerWebcam = dynamic(
  () => import("@/common/components/Modals/DrawerWebcam"),
  {
    ssr: false,
  }
);
const DynamicDrawerTrail = dynamic(
  () => import("@/common/components/Modals/DrawerTrail"),
  {
    ssr: false,
  }
);

const TWEEN_FACTOR = 2;
const numberWithinRange = (number, min, max) =>
  Math.min(Math.max(number, min), max);

const LivecamsBlock = ({ blok, settings }) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const [selectedCam, setSelectedCam] = useState("0");
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false });
  const [tweenValues, setTweenValues] = useState([]);
  const [weatherForecast, setWeatherForecast] = useState(new Map());
  const [snowForecast, setSnowForecast] = useState(new Map());
  const [sectorsData, setSectorsData] = useState([]);
  const [selectedSector, setSelectedSector] = useState("default");
  const [selectedList, setSelectedList] = useState(0);
  const [dataSectorsPois, setDataSectorsPois] = useState(new Map());
  const [loadingWeather, setLoadingWeather] = useState(true);
  const [loadingSnow, setLoadingSnow] = useState(true);
  const [loadingSectors, setIsLoadingSectors] = useState(false);
  const [loadingSectorPois, setIsLoadingSectorPois] = useState(false);
  const [webcams, setWebcams] = useState([]);
  const [seasonMode, setSeasonMode] = useState(SEASON_WINTER);
  const { openDrawer } = useDrawers();

  const onScroll = useCallback(() => {
    if (!emblaApi) return;

    const engine = emblaApi.internalEngine();
    const scrollProgress = emblaApi.scrollProgress();

    const styles = emblaApi.scrollSnapList().map((scrollSnap, index) => {
      let diffToTarget = scrollSnap - scrollProgress;

      if (engine.options.loop) {
        engine.slideLooper.loopPoints.forEach((loopItem) => {
          const target = loopItem.target();
          if (index === loopItem.index && target !== 0) {
            const sign = Math.sign(target);
            if (sign === -1) diffToTarget = scrollSnap - (1 + scrollProgress);
            if (sign === 1) diffToTarget = scrollSnap + (1 - scrollProgress);
          }
        });
      }
      return diffToTarget * (-1 / TWEEN_FACTOR) * 100;
    });
    setTweenValues(styles);
  }, [emblaApi, setTweenValues]);

  const fetchWebcams = useCallback(async () => {
    const response = await fetch("/api/lumiplan/get-webcams");
    const data = await response.json();

    if (data?.content?.resorts?.length > 0) {
      setWebcams(data.content.resorts[0]?.webcams || []);
    }
  }, []);

  const fetchSectorPois = useCallback(async (sectorId) => {
    setIsLoadingSectorPois(true);
    const response = await fetch(
      `/api/lumiplan/get-sector-pois?sectorId=${sectorId}&operating=all`
    );
    const data = await response.json();

    if (data?.content?.sectors && data?.content?.sectors[0]) {
      // console.log("fetchSectorPois", data?.content?.sectors[0]);

      setIsLoadingSectorPois(false);
      return data?.content?.sectors[0];
    }

    setIsLoadingSectorPois(false);
  }, []);

  const onChangeSector = useCallback(
    async (event) => {
      const sectorId = event.target ? event.target.value : event;

      if (sectorId !== "default") {
        setSelectedSector(sectorId);

        if (dataSectorsPois.get(sectorId)) {
          console.log("Data already fetched");
          setIsLoadingSectorPois(false);
        } else {
          // TODO: Call API getResortSectorPois here and add data to the dataSectorsPois state
          const sectorPois = await fetchSectorPois(sectorId);
          setDataSectorsPois(
            new Map(
              dataSectorsPois.set(sectorId, {
                lifts: sectorPois?.lifts || [],
                trails: sectorPois?.trails || [],
              })
            )
          );
        }

        setSelectedList(0);
      }
    },
    [fetchSectorPois, dataSectorsPois]
  );

  const fetchResort = useCallback(async () => {
    setIsLoadingSectors(true);
    const response = await fetch(
      "/api/lumiplan/get-resort-sectors?operating=all"
    );
    const data = await response.json();

    if (data?.content?.resorts?.length > 0) {
      const sectors = data.content.resorts[0]?.sectors || [];

      setIsLoadingSectors(false);

      if (sectors && sectors.length > 0) {
        setSectorsData(sectors);
      }
    }
    setIsLoadingSectors(false);
  }, []);

  const fetchSnow = useCallback(async () => {
    if (!snowForecast || snowForecast.size > 0) {
      return;
    }

    setLoadingSnow(true);

    const responseSnow = await fetch("/api/lumiplan/get-snow");
    if (!responseSnow.ok) {
      console.error("Error fetchSnow", responseSnow);
      return;
    }

    const dataSnow = await responseSnow.json();
    if (dataSnow?.content?.resorts?.length > 0) {
      const resortDataSnow = dataSnow?.content?.resorts[0];

      const snowZonesMap = new Map();
      let highestAltitude = 0;
      let highestAltitudeSnowZone = null;
      for (let i = 0; i < resortDataSnow?.snowZones?.length; i++) {
        const snowZone = resortDataSnow?.snowZones[i];

        if (snowZone?.altitude?.value > highestAltitude) {
          highestAltitude = snowZone?.altitude?.value;
          highestAltitudeSnowZone = snowZone;
        }

        if (snowZone.name?.match(/COURCHEVEL/i)) {
          snowZonesMap.set("station", snowZone);
        }
      }
      if (highestAltitudeSnowZone) {
        snowZonesMap.set("summit", highestAltitudeSnowZone);
      }

      setSnowForecast(snowZonesMap);
    }

    setLoadingSnow(false);
  }, [snowForecast]);

  const fetchWeather = useCallback(async () => {
    if (!weatherForecast || weatherForecast.size > 0) {
      return;
    }

    setLoadingWeather(true);

    const response = await fetch("/api/lumiplan/get-weather-forecast");
    if (!response.ok) {
      console.error("Error fetchWeatherForecast", response);
      return;
    }

    const data = await response.json();
    if (data?.content?.resorts?.length > 0) {
      const resortData = data?.content?.resorts[0];

      const weatherZonesMap = new Map();
      let highestAltitude = 0;
      let highestAltitudeWeatherZone = null;
      for (let i = 0; i < resortData?.weatherZones?.length; i++) {
        const weatherZone = resortData?.weatherZones[i];

        // Find the element with the highest altitude
        // Then, after the loop, push the element to the weatherZones array
        if (weatherZone?.altitude?.value > highestAltitude) {
          highestAltitude = weatherZone?.altitude?.value;
          highestAltitudeWeatherZone = weatherZone;
        }

        if (weatherZone.name?.match(/COURCHEVEL/i)) {
          weatherZonesMap.set("station", weatherZone);
        }
      }

      if (highestAltitudeWeatherZone) {
        weatherZonesMap.set("summit", highestAltitudeWeatherZone);
      }

      setWeatherForecast(weatherZonesMap);
    }
    setLoadingWeather(false);
  }, [weatherForecast]);

  const fetchLumiplanData = useCallback(async () => {
    // await fetchWebcams()
    if (blok?.active_modules?.includes("weather")) {
      await fetchWeather();
    }

    if (seasonMode === SEASON_WINTER) {
      await fetchSnow();
      await fetchResort();
    }
  }, [fetchSnow, fetchWeather, fetchResort, seasonMode]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            fetchLumiplanData();
            observer.disconnect();
          }
        });
      },
      { threshold: 0.5 }
    );

    let seasonMode = SEASON_WINTER;
    if (typeof window !== "undefined") {
      seasonMode = localStorage.getItem("seasonMode");
    }
    setSeasonMode(seasonMode);

    observer.observe(document.querySelector(`.${styles.livecamsBlock}`));
  }, [fetchLumiplanData]);

  useEffect(() => {
    if (!emblaApi) return;

    onScroll();
    emblaApi.on("scroll", () => {
      flushSync(() => onScroll());
    });
    emblaApi.on("reInit", onScroll);
  }, [emblaApi, onScroll]);

  const onSelectLivecam = (e) => {
    const index = e.target.closest("li").getAttribute("data-index");
    setSelectedCam(index);
    if (emblaApi) {
      emblaApi.scrollTo(index);
    }
  };

  const onChangeNextLivecam = () => {
    const nextIndex = parseInt(selectedCam) + 1;
    if (nextIndex < 5) {
      setSelectedCam(nextIndex.toString());
      if (emblaApi) {
        emblaApi.scrollTo(nextIndex);
      }
    }
  };

  const onChangePrevLivecam = () => {
    const prevIndex = parseInt(selectedCam) - 1;
    if (prevIndex >= 0) {
      setSelectedCam(prevIndex.toString());
      if (emblaApi) {
        emblaApi.scrollTo(prevIndex);
      }
    }
  };

  const onOpenDrawerWebcam = (id) => {
    const linkDrawerWebcam = blok?.explore_cta[0]?.link;

    // window.history.pushState({}, "", linkDrawerWebcam?.story?.full_slug || "");
    openDrawer(
      DynamicDrawerWebcam,
      {
        title: t("common:webcam.drawer.title"),
        drawerKey: "webcam",
        link: linkDrawerWebcam,
        id_lumiplan: id,
      },
      { nodeRef: createRef(null), targetElement: "#drawer-webcam" }
    );
  };

  const onOpenDrawerMeteo = () => {
    openDrawer(
      DynamicDrawerMeteo,
      {
        title: t("common:weather.drawer.title"),
        drawerKey: "meteo",
      },
      { nodeRef: createRef(null), targetElement: "#drawer-meteo" }
    );
  };
  let lang = "fr-FR";
  if (locale) {
    lang = getLangIsoCode(locale);
  }
  const todaysDate = new Date();
  const todaysDayOfMonth = todaysDate.toLocaleString(lang, {
    day: "numeric",
  });
  const todaysDayOfWeek = todaysDate.toLocaleString(lang, {
    weekday: "long",
  });
  const todaysMonthAndYear = todaysDate.toLocaleString(lang, {
    month: "long",
    year: "numeric",
  });

  const stationData = weatherForecast?.get("station");
  const summitData = weatherForecast?.get("summit");
  const stationDataDay = stationData?.weatherInfos
    ? stationData?.weatherInfos[0]
    : null;
  const summitDataDay = summitData?.weatherInfos
    ? summitData?.weatherInfos[0]
    : null;

  const stationSnowData = snowForecast?.get("station");
  const summitSnowData = snowForecast?.get("summit");

  const currentSectorPois = dataSectorsPois?.get(selectedSector);
  let lastModified =
    currentSectorPois?.trails?.length > 0
      ? currentSectorPois?.trails[0]?.lastModified
      : null;
  if (lastModified) {
    lastModified = new Date(lastModified);
    lastModified = lastModified.toLocaleString(
      `${locale}-${locale.toUpperCase()}`,
      {
        day: "numeric",
        month: "long",
        hour: "numeric",
        minute: "numeric",
      }
    );
    if (locale === "fr" && lastModified.match(/:/)) {
      lastModified = lastModified.replace(":", "h");
    }
  }

  const getOpeningStatus = (openingStatus) => {
    if (OPEN_STATUS.includes(openingStatus)) {
      return "OPEN";
    } else if (ISSUE_STATUS.includes(openingStatus)) {
      return "ISSUE";
    } else if (CLOSED_STATUS.includes(openingStatus)) {
      return "CLOSED";
    } else {
      return "CLOSED";
    }
  };

  const weatherContent = settings?.content?.weather_content;

  const SeasonSnowBlock = () => {
    if (!blok?.active_modules?.includes("snow")) {
      return (
        <div
          className={`rounded-xl py-4 px-6 bg-white group/item cursor-pointer bg-white/70`}
        ></div>
      );
    }

    if (seasonMode === SEASON_SUMMER) {
      if (!(weatherContent && weatherContent[0])) return;

      const BlockTag =
        weatherContent[0]?.cta && weatherContent[0].cta[0] ? Link : "div";

      return (
        <BlockTag
          href={
            weatherContent[0]?.cta && weatherContent[0]?.cta[0]?.link
              ? weatherContent[0]?.cta[0]?.link?.cached_url
              : ""
          }
          className={`rounded-xl py-4 px-6 bg-white group/item cursor-pointer`}
        >
          <div className="flex justify-between items-center mb-3">
            <Text as="h6">{weatherContent[0]?.title}</Text>
            <Icon
              icon="icon-arrow-up-right-16"
              size={16}
              className="invisible group-hover/item:visible text-buttonColor-primary"
            />
          </div>
          {weatherContent[0]?.description ? (
            <Text as="body2" className={`${styles.pushCardContent}`}>
              {weatherContent[0]?.description}
            </Text>
          ) : (
            <table
              className={`${styles.livecamsTrailsWidget} text-left w-full`}
            >
              <thead>
                <tr>
                  <th scope="col" className="w-12">
                    {" "}
                  </th>
                  {weatherContent[0]?.content?.map((weatherCard) => (
                    <th scope="col" key={`th-${weatherCard._uid}`} className="">
                      <Text as="ui2" className="uppercase lg:mr-3">
                        {weatherCard.title}
                      </Text>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th
                    scope="row"
                    className={`${styles.trailsLastUpdated} text-text-h4 pr-4`}
                  ></th>
                  {weatherContent[0]?.content?.map((weatherCard) => (
                    <td key={`th-${weatherCard._uid}`} className="">
                      <p className={`${styles.todaysDate} font-light`}>
                        {weatherCard.number}
                      </p>
                      <Text as="ui1" className="text-text-h4">
                        {weatherCard.label}
                      </Text>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          )}
        </BlockTag>
      );
    } else {
      return (
        <div
          className={`rounded-xl py-4 px-6 bg-white group/item cursor-pointer ${loadingSnow ? "bg-white/70 cursor-wait" : ""
            }`}
          onClick={onOpenDrawerMeteo}
        >
          <div className="flex justify-between items-center mb-3">
            <Text as="h6">
              {t("common:lumiplan.livecams.widget-snow.title")}
            </Text>
            <Icon
              icon="icon-arrow-up-right-16"
              size={16}
              className="invisible group-hover/item:visible text-buttonColor-primary"
            />
          </div>
          <table className={`${styles.livecamsSnowWidget} text-left w-full`}>
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">
                  <Text as="ui2" className="uppercase lg:mr-3">
                    {t("common:lumiplan.station")}
                  </Text>
                </th>
                <th scope="col">
                  <Text as="ui2" className="uppercase">
                    {t("common:lumiplan.summit")}
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" className="text-text-h4  ">
                  <Text as="ui1" className="">
                    {t("common:lumiplan.weather-snow-depth")}
                  </Text>
                </th>
                <td className="">
                  <Text as="ui1" className="">
                    {stationSnowData?.snowTotalDepth?.value
                      ? stationSnowData?.snowTotalDepth?.value + " cm"
                      : "-"}
                  </Text>
                </td>
                <td className="">
                  <Text as="ui1" className="">
                    {summitSnowData?.snowTotalDepth?.value
                      ? summitSnowData?.snowTotalDepth?.value + " cm"
                      : "-"}
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="text-text-h4">
                  <Text as="ui1" className="">
                    {t("common:lumiplan.weather-snowslide-risk")}
                  </Text>
                </td>
                <td className="">
                  <Text as="ui1" className="">
                    {stationSnowData?.avalancheRisk
                      ? getAvalancheRisk(stationSnowData?.avalancheRisk)?.level
                      : ""}{" "}
                    (/5)
                  </Text>
                </td>
                <td className="">
                  <Text as="ui1" className="">
                    {summitSnowData?.avalancheRisk
                      ? getAvalancheRisk(summitSnowData?.avalancheRisk)?.level
                      : ""}{" "}
                    (/5)
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  };

  const SeasonTrailsBlock = () => {
    if (!blok?.active_modules?.includes("sectorspois")) {
      return (
        <div
          className={`rounded-xl py-4 px-6 bg-white group/item cursor-pointer bg-white/70`}
        ></div>
      );
    }

    if (seasonMode === SEASON_SUMMER) {
      if (!(weatherContent && weatherContent[1])) return;

      const BlockTag =
        weatherContent[1]?.cta && weatherContent[1]?.cta[0] ? Link : "div";

      return (
        <BlockTag
          href={
            weatherContent[1]?.cta && weatherContent[1]?.cta[0]?.link
              ? weatherContent[1]?.cta[0]?.link?.cached_url
              : ""
          }
          className={`rounded-xl py-4 px-6 bg-white group/item`}
        >
          <div className="flex justify-between items-center mb-3">
            <Text as="h6">{weatherContent[1]?.title}</Text>
            <Icon
              icon="icon-arrow-up-right-16"
              size={16}
              className="invisible group-hover/item:visible text-buttonColor-primary"
            />
          </div>
          {weatherContent[1]?.description ? (
            <Text as="body2" className={`${styles.pushCardContent}`}>
              {weatherContent[1]?.description}
            </Text>
          ) : (
            <table
              className={`${styles.livecamsTrailsWidget} text-left w-full`}
            >
              <thead>
                <tr>
                  <th scope="col" className="w-12">
                    {" "}
                  </th>
                  {weatherContent[1]?.content?.map((weatherCard) => (
                    <th scope="col" key={`th-${weatherCard._uid}`} className="">
                      <Text as="ui2" className="uppercase lg:mr-3">
                        {weatherCard.title}
                      </Text>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th
                    scope="row"
                    className={`${styles.trailsLastUpdated} text-text-h4 pr-4`}
                  ></th>
                  {weatherContent[1]?.content?.map((weatherCard) => (
                    <td key={`th-${weatherCard._uid}`} className="">
                      <p className={`${styles.todaysDate} font-light`}>
                        {weatherCard.number}
                      </p>
                      <Text as="ui1" className="text-text-h4">
                        {weatherCard.label}
                      </Text>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          )}
        </BlockTag>
      );
    } else {
      const allLifts = [];
      const allTrails = [];
      for (let i = 0; i < sectorsData?.length; i++) {
        const sector = sectorsData[i];
        if (sector?.lifts?.length > 0) {
          allLifts.push(...sector?.lifts);
        }
        if (sector?.trails?.length > 0) {
          allTrails.push(...sector?.trails);
        }
      }
      if (allLifts.length === 0 && allTrails.length === 0) {
        return (
          <div></div>
        );
      }

      return (
        <div
          className={`rounded-xl py-4 px-6 bg-white group/item ${loadingSectors ? "cursor-wait" : ""
            }`}
        >
          <div className="flex justify-between items-center 3">
            <div className="flex items-center">
              <Text as="h6" className="flex-1 whitespace-nowrap mr-2">
                {t("common:lumiplan.livecams.widget-trail.title")}
              </Text>
              {/*<select
                onChange={onChangeSector}
                value={selectedSector}
                className={`${styles.livecamsSectorsSelect} ${selectedSector === 'default' ? 'text-buttonColor-primary' : ''} cursor-pointer group-hover/item:text-buttonColor-primary`}
              >
                <option value="default" disabled>{t("common:lumiplan.choice-sector")}</option>
                {sectorsData.map((sector, index) => (
                  <option
                    key={`sdo${index}`}
                    value={sector.id}
                    className={`${selectedSector === sector.id ? "" : "opacity-50"
                      } hover:opacity-100`}
                  >
                    {upperCaseFirstLetter(sector.name)}
                  </option>
                ))}
              </select>*/}
            </div>
          </div>
          <table
            className={`${styles.livecamsTrailsWidget} text-left w-full ${selectedSector === "default" ? "" : ""
              }`}
          >
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">
                  <Text as="ui2" className="uppercase lg:mr-3">
                    {t("common:lumiplan.trails")}
                  </Text>
                </th>
                <th scope="col">
                  <Text as="ui2" className="uppercase">
                    {t("common:lumiplan.lifts")}
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th
                  scope="row"
                  className={`${styles.trailsLastUpdated} text-text-h4 pr-4`}
                >
                  <Text as="ui1" className="opacity-0">
                    {t("common:lumiplan.last-update")}
                    {/* {lastModified ?? "-"} */}
                  </Text>
                </th>
                <td className="">
                  <p className={`${styles.todaysDate} font-light`}>
                    {
                      allTrails?.filter(
                        (lift) =>
                          getOpeningStatus(lift.openingStatus) === "OPEN"
                      ).length
                    }
                  </p>
                  <Text as="ui1" className="text-text-h4">
                    {allTrails ? `sur ${allTrails?.length}` : ""}
                  </Text>
                </td>
                <td className="">
                  <p className={`${styles.todaysDate} font-light`}>
                    {
                      allLifts?.filter(
                        (trail) =>
                          getOpeningStatus(trail.openingStatus) === "OPEN"
                      ).length
                    }
                  </p>
                  <Text as="ui1" className="text-text-h4">
                    {allLifts ? `sur ${allLifts?.length}` : ""}
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  };

  return (
    <div
      className={`${styles.livecamsBlock} column livecamsBlock relative overflow-hidden flex flex-col justify-between`}
      {...storyblokEditable(blok)}
    >
      <div
        className={`absolute w-full h-full ${styles.livecamsBg} bg-text-h1 text-white`}
      >
        <div className="embla overflow-hidden" ref={emblaRef}>
          <div className="embla__container flex">
            {blok.list &&
              blok.list.map((livecamItem, livecamItemIndex) => {
                const illustration = livecamItem?.content?.illustration
                  ? seasonMode === SEASON_WINTER
                    ? livecamItem?.content?.illustration[0]
                    : livecamItem?.content?.illustration.length > 1
                      ? livecamItem?.content?.illustration[1]
                      : livecamItem?.content?.illustration[0]
                  : null;

                if (livecamItem.uuid) {
                  return (
                    <div
                      key={`slide-${livecamItemIndex}`}
                      className={`${styles.livecamSlide} embla__slide ${livecamItemIndex === parseInt(selectedCam) - 1
                        ? styles.livecamSlideCurrent
                        : livecamItemIndex > parseInt(selectedCam)
                          ? styles.livecamSlideNext
                          : livecamItemIndex === parseInt(selectedCam) - 1
                            ? styles.livecamSlidePrev
                            : ""
                        }`}
                    >
                      <div className="embla__parallax">
                        <div
                          className={`${styles.livecamSlideParallaxLayer} embla__parallax__layer`}
                          style={{
                            ...(tweenValues.length && {
                              transform: `translateX(${tweenValues[livecamItemIndex]}%)`,
                            }),
                          }}
                        >
                          <Image
                            src={
                              illustration
                                ? illustration?.filename
                                : sampleBgImage
                            }
                            fill
                            alt={
                              illustration
                                ? illustration?.alt
                                : "default illustration"
                            }
                            className={`${styles.bgImage} w-full h-full object-cover`}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </div>
        <div className="absolute inset-0 bg-text-h1 bg-opacity-20"></div>
      </div>
      <div className="container relative z-10 pt-14 pb-8 text-white">
        <Text as="ui2" className="uppercase">
          {t("common:lumiplan.livecams.title")}
        </Text>
        <ul className="mt-3 lg:mt-4 w-3/4 lg:w-1/2">
          {blok.list &&
            blok.list.map(
              (livecamItem, livecamItemIndex) =>
                livecamItem.uuid && (
                  <CustomLink
                    key={`lci${livecamItem.uuid}`}
                    className={`opacity-50 inline-block mr-2 hover:opacity-100`}
                    // onClick={() =>
                    //   onOpenDrawerWebcam(
                    //     livecamItem.content?.id_lumiplan
                    //   )
                    // }
                    link={blok?.explore_cta && blok?.explore_cta[0]?.link}
                    params={{ id: livecamItem.content?.id_lumiplan }}
                    data-index={livecamItemIndex}
                  >
                    <button>
                      <Text as="h3">
                        {livecamItem.content?.title}
                        {livecamItemIndex < blok.list.length - 1 ? "," : ""}
                      </Text>
                    </button>
                  </CustomLink>
                )
            )}
        </ul>
        {blok?.explore_cta && blok?.explore_cta[0]?.link && (
          <ArrowLink
            tag="button"
            className={`${styles.livecamsBlockExplore} mt-6 lg:mt-10 hover:text-white`}
            link={blok?.explore_cta && blok?.explore_cta[0]?.link}
          >
            {blok?.explore_cta[0]?.label || t("common:lumiplan.livecams.cta")}
          </ArrowLink>
        )}
      </div>

      <div className="container relative z-10 pb-10 flex flex-col">
        {/* <div className="flex items-center self-end">
          <ArrowButton direction="left" action={onChangePrevLivecam} />
          <ArrowButton direction="right" action={onChangeNextLivecam} />
        </div> */}
        <div
          className={`${styles.livecamsBlockFreshData} hidden mt-6 lg:flex gap-x-3`}
        >
          <div className="flex flex-col items-between justify-end rounded-xl py-4 px-6 bg-text-h1 bg-ete text-white">
            <Text as="h6" className="mb-auto">
              {upperCaseFirstLetter(todaysDayOfWeek)}
            </Text>
            <p className={`${styles.todaysDate} font-light`}>
              {todaysDayOfMonth < 10
                ? `0${todaysDayOfMonth}`
                : todaysDayOfMonth}
            </p>
            <Text as="ui1" className={`${styles.todaysMonthYear} text-text-h4`}>
              {upperCaseFirstLetter(todaysMonthAndYear)}
            </Text>
          </div>
          {blok?.active_modules?.includes("weather") ? (
            <div
              className={`rounded-xl py-4 px-6 bg-white group/item cursor-pointer ${loadingWeather ? "bg-white/70 cursor-wait" : ""
                }`}
              onClick={onOpenDrawerMeteo}
            >
              <div className="flex justify-between items-center mb-3">
                <Text as="h6">
                  {t("common:lumiplan.livecams.widget-weather.title")}
                </Text>
                <Icon
                  icon="icon-arrow-up-right-16"
                  size={16}
                  className="invisible group-hover/item:visible text-buttonColor-primary"
                />
              </div>
              <table
                className={`${styles.livecamsMeteoWidget} text-left w-full`}
              >
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">
                      <Text as="ui2" className="uppercase lg:mr-3">
                        {t("common:lumiplan.station")}
                      </Text>
                    </th>
                    <th scope="col">
                      <Text as="ui2" className="uppercase">
                        {t("common:lumiplan.summit")}
                      </Text>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" className="text-text-h4">
                      <Text as="ui1" className="">
                        {t("common:lumiplan.morning")}
                      </Text>
                    </th>
                    <td className="pl-3">
                      {stationDataDay?.am?.temperature && (
                        <Icon
                          icon={
                            getSkyStatus(stationDataDay?.am?.skyStatus)?.icon
                          }
                          size={28}
                          className={`${styles.smallWeatherIcon}`}
                        />
                      )}
                    </td>
                    <td className="pl-3">
                      {summitDataDay?.am?.temperature && (
                        <Icon
                          icon={
                            getSkyStatus(summitDataDay?.am?.skyStatus)?.icon
                          }
                          size={28}
                          className={`${styles.smallWeatherIcon}`}
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-text-h4">
                      <Text as="ui1" className="">
                        {t("common:lumiplan.afternoon")}
                      </Text>
                    </th>
                    <td className="pl-3">
                      {stationDataDay?.am?.temperature && (
                        <Icon
                          icon={
                            getSkyStatus(stationDataDay?.am?.skyStatus)?.icon
                          }
                          size={28}
                          className={`${styles.smallWeatherIcon}`}
                        />
                      )}
                    </td>
                    <td className="pl-3">
                      {summitDataDay?.pm?.temperature && (
                        <Icon
                          icon={
                            getSkyStatus(summitDataDay?.am?.skyStatus)?.icon
                          }
                          size={28}
                          className={`${styles.smallWeatherIcon}`}
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div
              className={`rounded-xl py-4 px-6 bg-white group/item cursor-pointer bg-white/70`}
            ></div>
          )}
          <SeasonSnowBlock />
          <SeasonTrailsBlock />
        </div>
      </div>
    </div>
  );
};

export default LivecamsBlock;
