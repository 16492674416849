import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import {
  MARK_LINK,
  MARK_TEXT_STYLE,
  NODE_HEADING,
  NODE_EMOJI,
  render,
} from "storyblok-rich-text-react-renderer";
import Text from "../../Core/Text";
import ArrowLink from "../../Core/ArrowLink";
import * as styles from "./Paragraph.module.scss";
import Link from "next/link";
import * as emoji from "node-emoji";

export const storyblokContentRenderOptions = {
  markResolvers: {
    [MARK_LINK]: (children, props) => {
      const { linktype, href, story } = props;

      if (linktype === "email") {
        // Email links: add `mailto:` scheme and map to <a>
        return <a href={`mailto:${href}`}>{children}</a>;
      }
      if (href?.match(/^(https?:)?\/\//) || linktype === "url") {
        const linkIsScript = href?.match(/^javascript?:/)
        // External links: map to <a>
        return (
          <a
            href={story ? story.full_slug : href}
            target={linkIsScript ? null : "_blank"}
            className="underline text-buttonColor-primary"
          >
            {children}
          </a>
        );
      }
      // Internal links: map to <Link>
      return (
        <Link
          href={story ? story.full_slug : href}
          className="underline text-buttonColor-primary"
        >
          {children}
        </Link>
      );
    },
    [MARK_TEXT_STYLE]: (children, props) => {
      if (props?.color && props?.color !== "") {
        const { color, ...rest } = props;
        return (
          <span {...rest} style={{ color: color }}>
            {children}
          </span>
        );
      }

      if (props) {
        const { ...rest } = props;
        return <span {...rest}>{children}</span>;
      }

      return <span>{children}</span>;
    },
  },
  nodeResolvers: {
    [NODE_HEADING]: (children, props) => {
      return (
        <Text
          as={`h${props.level}`}
          tag={`h${props.level}`}
          className="mb-5 mt-10 lg:mt-12"
        >
          {children}
        </Text>
      );
    },
    [NODE_EMOJI]: (children, props) => {
      const emojiName = props?.name;
      const emojiCharacter = emoji.get(emojiName);
      return <span>{emojiCharacter}</span>;
    },
  },
  blokResolvers: {
    ["CTA"]: (props) => {
      return (
        <ArrowLink
          tag="a"
          link={props.link}
          href={
            props.link
              ? props.link.story
                ? props.link.story.url
                : props.link.cached_url
              : ""
          }
          className="mb-4 text-buttonColor-primary"
          target={props.link.target}
        >
          {props.label}
        </ArrowLink>
      );
    },
  },
  defaultBlokResolver: (name, props) => {
    const blok = { ...props, component: name };
    return <StoryblokComponent blok={blok} key={props._uid} />;
  },
};

const Paragraph = ({ blok, className, inFooter }) => {
  return (
    <div
      className={`flex flex-col mb-6 lg:mb-10 last:mb-0 ${className}`}
      {...storyblokEditable(blok)}
    >
      {blok.title && (
        <Text
          as={inFooter ? "ui2" : "h4"}
          className={inFooter ? "uppercase mb-4" : "mb-4"}
        >
          {blok.title}
        </Text>
      )}
      <div className={styles.sParagraph}>
        {render(blok.Contenu, storyblokContentRenderOptions)}
      </div>
    </div>
  );
};

export default Paragraph;
