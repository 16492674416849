import {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import dynamic from "next/dynamic";
import useTranslation from "next-translate/useTranslation";
import * as stylesStory from "../Story.module.scss";
import * as styles from "./PushBlock.module.scss";
import Text from "../../Core/Text";
import ArrowLink from "../../Core/ArrowLink";
import Icon from "@/utils/Icon";
import { getSkyStatus } from "@/utils/weather";
import { upperCaseFirstLetter } from "@/utils/format";
import { SEASON_WINTER } from "@/utils/global";
import { useDrawers } from "../../DrawerStack";
import {
  CLOSED_STATUS,
  ISSUE_STATUS,
  OPEN_STATUS,
} from "../Pages/DrawerPage/TrailModule";
import { useRouter } from "next/router";

const DynamicDrawerMeteo = dynamic(() => import("../../Modals/DrawerMeteo"), {
  ssr: false,
});
const DynamicDrawerWebcam = dynamic(() => import("../../Modals/DrawerWebcam"), {
  ssr: false,
});
const DynamicDrawerTrail = dynamic(() => import("../../Modals/DrawerTrail"), {
  ssr: false,
});

const PushBlock = ({ blok, data }) => {
  const { t } = useTranslation("common");
  const { openDrawer } = useDrawers();
  const pushBlockRef = useRef(null);
  const { locale } = useRouter();
  const [largerPush, setLargerPush] = useState(2);
  const [isVisible, setVisible] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [snow, setSnow] = useState([]);
  const [weather, setWeather] = useState([]);
  const [weatherForecast, setWeatherForecast] = useState(new Map());
  const [snowForecast, setSnowForecast] = useState(new Map());
  const [sectorsData, setSectorsData] = useState([]);
  const [selectedSector, setSelectedSector] = useState("default");
  const [selectedList, setSelectedList] = useState(0);
  const [dataSectorsPois, setDataSectorsPois] = useState(new Map());
  const [isLoadingWeather, setIsLoadingWeather] = useState(false);
  const [isLoadingSnow, setIsLoadingSnow] = useState(false);
  const [isLoadingSectors, setIsLoadingSectors] = useState(false);
  const [isLoadingSectorPois, setIsLoadingSectorPois] = useState(false);
  const [seasonMode, setSeasonMode] = useState(SEASON_WINTER);
  const weatherContent = useMemo(() => data?.weather_content || [], [data]);
  const displayWeatherContent = data?.display_weather_content;

  console.log("displayWeatherContent", displayWeatherContent);
  console.log("data", data);

  const onHandleMouseEnter = (e, fetchData = true) => {
    const index = e.currentTarget.getAttribute("data-index");
    const parsedIndex = parseInt(index);

    setLargerPush(parsedIndex);

    if (fetchData) {
      if (parsedIndex === 0) {
        if (
          data?.active_modules?.includes("weather") &&
          displayWeatherContent
        ) {
          fetchWeather();
        }
      } else if (parsedIndex === 1) {
        if (data?.active_modules?.includes("snow") && displayWeatherContent) {
          fetchSnow();
        }
      } else if (parsedIndex === 2) {
        // fetchSectors();
      }
    }
  };

  const onHandleMouseLeave = (e) => {
    //console.log('onHandleMouseLeave')
    setLargerPush(2);
  };

  const fetchSnow = async () => {
    if (snowForecast.size > 0) {
      return;
    }

    setIsLoadingSnow(true);

    const responseSnow = await fetch("/api/lumiplan/get-snow");
    if (!responseSnow.ok) {
      console.error("Error fetchSnow", responseSnow);
      setIsLoadingSnow(false);
      return;
    }

    const dataSnow = await responseSnow.json();
    if (dataSnow?.content?.resorts?.length > 0) {
      const resortDataSnow = dataSnow?.content?.resorts[0];
      //console.log("resortDataSnow", resortDataSnow);

      const snowZonesMap = new Map();
      let highestAltitude = 0;
      let highestAltitudeSnowZone = null;
      for (let i = 0; i < resortDataSnow?.snowZones?.length; i++) {
        const snowZone = resortDataSnow?.snowZones[i];

        if (snowZone?.altitude?.value > highestAltitude) {
          highestAltitude = snowZone?.altitude?.value;
          highestAltitudeSnowZone = snowZone;
        }

        if (snowZone.name?.match(/COURCHEVEL/i)) {
          snowZonesMap.set("station", snowZone);
        }
      }
      if (highestAltitudeSnowZone) {
        snowZonesMap.set("summit", highestAltitudeSnowZone);
      }

      setSnowForecast(snowZonesMap);
    }

    setIsLoadingSnow(false);
  };

  const fetchWeather = async () => {
    if (weatherForecast.size > 0) {
      return;
    }

    setIsLoadingWeather(true);

    const response = await fetch("/api/lumiplan/get-weather-forecast");
    if (!response.ok) {
      console.error("Error fetchWeatherForecast", response);
      setIsLoadingWeather(false);
      return;
    }

    const data = await response.json();
    if (data?.content?.resorts?.length > 0) {
      const resortData = data?.content?.resorts[0];

      const weatherZonesMap = new Map();
      let highestAltitude = 0;
      let highestAltitudeWeatherZone = null;
      for (let i = 0; i < resortData?.weatherZones?.length; i++) {
        const weatherZone = resortData?.weatherZones[i];

        // Find the element with the highest altitude
        // Then, after the loop, push the element to the weatherZones array
        if (weatherZone?.altitude?.value > highestAltitude) {
          highestAltitude = weatherZone?.altitude?.value;
          highestAltitudeWeatherZone = weatherZone;
        }

        if (weatherZone.name?.match(/COURCHEVEL/i)) {
          weatherZonesMap.set("station", weatherZone);
        }
      }

      if (highestAltitudeWeatherZone) {
        weatherZonesMap.set("summit", highestAltitudeWeatherZone);
      }

      setWeatherForecast(weatherZonesMap);
    }

    setIsLoadingWeather(false);
  };

  const fetchSectorPois = useCallback(async (sectorId) => {
    setIsLoadingSectorPois(true);
    // const response = await fetch(
    //   `/api/lumiplan/get-sector-pois?sectorId=${sectorId}&operating=all`
    // );
    const response = await fetch(`/api/lumiplan/get-resort-sector`);
    const data = await response.json();

    if (data?.content?.resorts?.length > 0) {
      const sectors = data.content.resorts[0]?.sectors || [];

      if (sectors && sectors.length > 0) {
        setIsLoadingSectorPois(false);
        return data?.content?.sectors[0];
      }
    }

    setIsLoadingSectorPois(false);
  }, []);

  const onChangeSector = useCallback(
    async (event) => {
      const sectorId = event.target ? event.target?.value : event;
      setSelectedSector(sectorId);

      if (dataSectorsPois.get(sectorId)) {
        console.log("Data already fetched");
        setIsLoadingSectorPois(false);
      } else {
        // TODO: Call API getResortSectorPois here and add data to the dataSectorsPois state
        const sectorPois = await fetchSectorPois(sectorId);
        setDataSectorsPois(
          new Map(
            dataSectorsPois.set(sectorId, {
              lifts: sectorPois?.lifts || [],
              trails: sectorPois?.trails || [],
            })
          )
        );
      }

      setSelectedList(0);
    },
    [fetchSectorPois, dataSectorsPois]
  );

  const fetchResort = useCallback(async () => {
    setIsLoadingSectors(true);
    const response = await fetch(
      "/api/lumiplan/get-resort-sectors?operating=all"
    );
    const data = await response.json();

    if (data?.content?.resorts?.length > 0) {
      const sectors = data.content.resorts[0]?.sectors || [];

      setIsLoadingSectors(false);

      if (sectors && sectors.length > 0) {
        setSectorsData(sectors);
        // setResort(data.content?.resorts[0]);
        // setSelectedSector(data.content?.resorts[0]);
        setSelectedSector(sectors[0].id);
      }
    }
    setIsLoadingSectors(false);
  }, []);

  useEffect(() => {
    if (data?.active_modules && data.active_modules.length > 0) {
      if (data?.active_modules?.includes("weather")) {
        fetchWeather();
      }
      if (data?.active_modules?.includes("snow")) {
        fetchSnow();
      }
      // fetchSectors();
    }
  }, [data]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            fetchResort();
            observer.disconnect();
          }
        });
      },
      { threshold: 0.5 }
    );

    let sMode = SEASON_WINTER;

    if (typeof window !== "undefined") {
      sMode = localStorage.getItem("seasonMode");
    }
    setSeasonMode(sMode);

    if (displayWeatherContent) {
      observer.observe(pushBlockRef.current);
    }
  }, [fetchResort]);

  const stationData = weatherForecast.get("station");
  const summitData = weatherForecast.get("summit");
  const stationDataDay = stationData?.weatherInfos
    ? stationData?.weatherInfos[0]
    : null;
  const summitDataDay = summitData?.weatherInfos
    ? summitData?.weatherInfos[0]
    : null;

  const stationSnowData = snowForecast.get("station");
  const summitSnowData = snowForecast.get("summit");

  const currentSectorPois = dataSectorsPois.get(selectedSector);
  let lastModified =
    currentSectorPois?.trails?.length > 0
      ? currentSectorPois?.trails[0]?.lastModified
      : null;
  if (lastModified) {
    lastModified = new Date(lastModified);
    lastModified = lastModified.toLocaleString(
      `${locale}-${locale.toUpperCase()}`,
      {
        day: "numeric",
        month: "long",
        hour: "numeric",
        minute: "numeric",
      }
    );

    if (locale === "fr" && lastModified.match(/:/)) {
      lastModified = lastModified.replace(":", "h");
    }
  }

  const getOpeningStatus = (openingStatus) => {
    if (OPEN_STATUS.includes(openingStatus)) {
      return "OPEN";
    } else if (ISSUE_STATUS.includes(openingStatus)) {
      return "ISSUE";
    } else if (CLOSED_STATUS.includes(openingStatus)) {
      return "CLOSED";
    } else {
      return "CLOSED";
    }
  };

  const MiddleBlock = () => {
    if (seasonMode === SEASON_WINTER) {
      return (
        <>
          <Text as="h5" className="mb-auto">
            {t("lumiplan.push.title-snow")}
          </Text>

          {stationSnowData && (
            <div
              className={`${styles.pushCardContent} flex items-center opacity-0`}
            >
              <div className="mr-14">
                <Text as="ui2" className="uppercase mb-2">
                  {t("lumiplan.station")}
                </Text>
                <div className={`${styles.pushCardBigNumber}`}>
                  <span>{stationSnowData.snowTotalDepth?.value}</span>
                  <Text as="ui1" className="opacity-50">
                    cm ({stationSnowData.snowQuality})
                  </Text>
                </div>
              </div>
              <div className="">
                <Text as="ui2" className="uppercase mb-2">
                  {t("lumiplan.summit")}
                </Text>
                <div className={`${styles.pushCardBigNumber}`}>
                  <span>{summitSnowData.snowTotalDepth?.value}</span>
                  <Text as="ui1" className="opacity-50">
                    cm ({summitSnowData.snowQuality})
                  </Text>
                </div>
              </div>
            </div>
          )}

          <ArrowLink
            noColorChange
            className="mt-14"
            link={
              data?.header[0]?.weather_link &&
              data?.header[0]?.weather_link[0].link
            }
          >
            {t("actions.explore")}
          </ArrowLink>
        </>
      );
    }

    if (weatherContent?.length > 0) {
      return (
        <>
          <Text as="h5" className="mb-auto">
            {weatherContent[0].title}
          </Text>

          {weatherContent[0].description && (
            <div className={`${styles.pushCardContent}`}>
              {weatherContent[0].description}
            </div>
          )}

          <div
            className={`${styles.pushCardContent} flex items-center opacity-0`}
          >
            {weatherContent[0].content?.map((weatherCard) => (
              <div key={weatherCard._uid} className="mr-14">
                {weatherCard.title && (
                  <Text as="ui2" className="uppercase mb-2">
                    {weatherCard.title}
                  </Text>
                )}
                <div className={`${styles.pushCardBigNumber}`}>
                  <span>{weatherCard.number}</span>
                  {weatherCard.label && (
                    <Text as="ui1" className="opacity-50">
                      {weatherCard.label}
                    </Text>
                  )}
                </div>
              </div>
            ))}
          </div>

          {weatherContent[0].cta && weatherContent[0].cta[0] && (
            <ArrowLink
              className="mt-14"
              link={weatherContent[0].cta[0]?.link}
              noColorChange
            >
              {weatherContent[0].cta[0]?.label || t("actions.explore")}
            </ArrowLink>
          )}
        </>
      );
    }

    return <></>;
  };

  const LastBlock = () => {
    if (seasonMode === SEASON_WINTER) {
      const allLifts = [];
      const allTrails = [];
      for (let i = 0; i < sectorsData?.length; i++) {
        const sector = sectorsData[i];
        if (sector?.lifts?.length > 0) {
          allLifts.push(...sector?.lifts);
        }
        if (sector?.trails?.length > 0) {
          allTrails.push(...sector?.trails);
        }
      }

      return (
        <>
          <Text as="h5" className="mb-auto">
            {t("lumiplan.push.title-trail")}
          </Text>

          {/* <select
            onChange={onChangeSector}
            value={selectedSector}
            className={`${styles.pushSectorsSelect} ${
              selectedSector === "default" ? "" : ""
            } self-start bg-transparent color-white cursor-pointer group-hover/item:text-buttonColor-primary`}
          >
            <option value="default" disabled>
              {t("lumiplan.choice-sector")}
            </option>
            {sectorsData.map((sector, index) => (
              <option
                key={`bpo${index}`}
                value={sector.id}
                className={`${
                  selectedSector === sector.id ? "" : "opacity-50"
                } hover:opacity-100`}
              >
                {upperCaseFirstLetter(sector.name)}
              </option>
            ))}
          </select>
          <Text
            as="ui1"
            className={`mb-8 mt-2 ${
              selectedSector === "default" ? "opacity-0" : "opacity-50"
            }`}
          >
            {t("lumiplan.last-update")} {lastModified ?? "-"}
          </Text> */}
          <div
            className={`${styles.pushCardContent} flex items-center opacity-0`}
          >
            {selectedSector !== "default" && (
              <>
                <div className="mr-14">
                  <Text as="ui2" className="uppercase mb-2">
                    {t("lumiplan.trails")}
                  </Text>
                  <p className={`${styles.pushCardBigNumber} mb-2`}>
                    {currentSectorPois?.trails?.filter(
                      (trail) =>
                        getOpeningStatus(trail.openingStatus) === "OPEN"
                    ).length || 0}
                  </p>
                  <Text as="ui1" className="opacity-50">
                    {currentSectorPois?.trails
                      ? `sur ${currentSectorPois?.trails?.length} remontées`
                      : ""}
                  </Text>
                </div>
                <div className="">
                  <Text as="ui2" className="uppercase mb-2">
                    {t("lumiplan.lifts")}
                  </Text>
                  <p className={`${styles.pushCardBigNumber} mb-2`}>
                    {currentSectorPois?.lifts?.filter(
                      (lift) => getOpeningStatus(lift.openingStatus) === "OPEN"
                    ).length || 0}
                  </p>
                  <Text as="ui1" className="opacity-50">
                    {currentSectorPois?.lifts
                      ? `sur ${currentSectorPois?.lifts?.length} pistes`
                      : ""}
                  </Text>
                </div>
              </>
            )}
          </div>

          <ArrowLink
            noColorChange
            className="mt-14"
            link={
              data?.header[0]?.trail_link && data?.header[0]?.trail_link[0].link
            }
          >
            {t("actions.explore")}
          </ArrowLink>
        </>
      );
    }

    if (weatherContent?.length > 1) {
      return (
        <>
          <Text as="h5" className="mb-auto">
            {weatherContent[1].title}
          </Text>

          {weatherContent[1].description && (
            <Text as="body1" className={`${styles.pushCardContent} opacity-0`}>
              {weatherContent[1].description}
            </Text>
          )}

          <div
            className={`${styles.pushCardContent} flex items-center opacity-0`}
          >
            {weatherContent[1].content?.map((weatherCard) => (
              <div key={weatherCard._uid} className="mr-14">
                {weatherCard.title && (
                  <Text as="ui2" className="uppercase mb-2">
                    {weatherCard.title}
                  </Text>
                )}
                <div className={`${styles.pushCardBigNumber}`}>
                  <span>{weatherCard.number}</span>
                  {weatherCard.label && (
                    <Text as="ui1" className="opacity-50">
                      {weatherCard.label}
                    </Text>
                  )}
                </div>
              </div>
            ))}
          </div>

          {weatherContent[1].cta && weatherContent[1].cta[0] && (
            <ArrowLink
              className="mt-14"
              link={weatherContent[1].cta[0].link}
              noColorChange
            >
              {weatherContent[1].cta[0].label || t("actions.explore")}
            </ArrowLink>
          )}
        </>
      );
    }

    return <></>;
  };

  if (displayWeatherContent) {
    return (
      <div
        className={`${stylesStory.column} column PushBlock ${styles.pushBlock}`}
        {...storyblokEditable(blok)}
        ref={pushBlockRef}
      >
        <div className={`container mb-12 lg:mb-0 z-2`}>
          <div
            className={`${styles.pushBlockContainer} flex lg:block pb-5 lg:pb-0`}
          >
            <div className="flex lg:flex-row-reverse flex-nowrap">
              <div
                className={`${styles.pushCard} ${styles.pushTrails} ${
                  styles.pushCardLarger
                } ${
                  isLoadingSectors || isLoadingSectors ? "cursor-progress" : ""
                }`}
                data-index="2"
              >
                <LastBlock />
              </div>
              <div
                className={`${styles.pushCard} ${styles.pushSnow} ${
                  styles.pushCardLarger
                } ${isLoadingSnow ? "cursor-progress" : ""}`}
                data-index="1"
              >
                <MiddleBlock />
              </div>
              <div
                className={`${styles.pushCard} ${styles.pushMeteo} ${
                  styles.pushCardLarger
                } ${isLoadingWeather ? "cursor-progress" : ""}`}
                data-index="0"
                onMouseEnter={onHandleMouseEnter}
                onMouseLeave={onHandleMouseLeave}
              >
                <Text as="h5" className="mb-auto">
                  {t("lumiplan.push.title-weather")}
                </Text>

                {data?.active_modules?.includes("weather") && (
                  <div
                    className={`${styles.pushCardContent} flex items-center opacity-0`}
                  >
                    <div className="mr-14">
                      <Text as="ui2" className="uppercase mb-4">
                        {t("lumiplan.station")}
                      </Text>
                      <div
                        key={`pstationDataDay-am`}
                        className="text-center flex items-end"
                      >
                        <Icon
                          icon={
                            getSkyStatus(stationDataDay?.am?.skyStatus)?.icon
                          }
                          color="#fff"
                          className={`${styles.smallWeatherIcon} mt-3 mb-1`}
                        />
                        <div className="ml-4">
                          <Text as="h5">
                            {stationDataDay?.am?.temperature?.value}&deg;
                          </Text>
                          <Text as="ui1" className={``}>
                            {t("lumiplan.morning")}
                          </Text>
                        </div>
                      </div>
                      <div
                        key={`pstationDataDay-pm`}
                        className="text-center flex items-end"
                      >
                        <Icon
                          icon={
                            getSkyStatus(stationDataDay?.pm?.skyStatus)?.icon
                          }
                          color="#fff"
                          className={`${styles.smallWeatherIcon} mt-3 mb-1`}
                        />
                        <div className="ml-4">
                          <Text as="h5">
                            {stationDataDay?.pm?.temperature?.value}&deg;
                          </Text>
                          <Text as="ui1" className={``}>
                            {t("lumiplan.afternoon")}
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <Text as="ui2" className="uppercase mb-4">
                        {t("lumiplan.summit")}
                      </Text>
                      <div
                        key={`psummitDataDay-am`}
                        className="text-center flex items-end"
                      >
                        <Icon
                          icon={
                            getSkyStatus(summitDataDay?.am?.skyStatus)?.icon
                          }
                          color="#fff"
                          className={`${styles.smallWeatherIcon} mt-3 mb-1`}
                        />
                        <div className="ml-4">
                          <Text as="h5">
                            {summitDataDay?.am?.temperature?.value}&deg;
                          </Text>
                          <Text as="ui1" className={``}>
                            {t("lumiplan.morning")}
                          </Text>
                        </div>
                      </div>
                      <div
                        key={`psummitDataDay-pm`}
                        className="text-center flex items-end"
                      >
                        <Icon
                          icon={
                            getSkyStatus(summitDataDay?.pm?.skyStatus)?.icon
                          }
                          color="#fff"
                          className={`${styles.smallWeatherIcon} mt-3 mb-1`}
                        />
                        <div className="ml-4">
                          <Text as="h5">
                            {summitDataDay?.pm?.temperature?.value}&deg;
                          </Text>
                          <Text as="ui1" className={``}>
                            {t("lumiplan.afternoon")}
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <ArrowLink
                  noColorChange
                  className="mt-14"
                  link={
                    data?.header[0]?.weather_link &&
                    data?.header[0]?.weather_link[0].link
                  }
                >
                  {t("actions.explore")}
                </ArrowLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default PushBlock;
