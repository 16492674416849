import { storyblokEditable } from "@storyblok/react";
import * as stylesCrossContentBlock from "../CrossContentBlock.module.scss";
import CardActivity from "@/common/components/Card/Activity";
import CardEdito from "@/common/components/Card/Edito";
import CardsSlider from "@/common/components/CardsSlider";
import CardPush from "@/common/components/Card/Push";
import Text from "@/common/components/Core/Text";

const InternalBlogArticles = ({ blok, parent, nestedData, className }) => {
  // console.log('internal listing', blok, template);
  const { items } = blok;
  const listItems = items || [];
  let listingItems = listItems;
  if (nestedData) {
    for (let i = 0; i < listItems.length; i++) {
      const listItem = listItems[i];
      const nestedDataItem = nestedData.find(
        (nestedDataItem) => nestedDataItem.uuid === listItem
      );

      if (nestedDataItem) {
        listingItems[i] = nestedDataItem;
      }
    }
  }

  let wrapperClasses;
  let listingContent;

  if (
    listingItems &&
    listingItems.length > 0 &&
    typeof listingItems[0] === "object"
  ) {
    listingItems[0].large = true;
  }
  wrapperClasses = "";
  listingContent = (
    <CardsSlider slideType={"activity"} slides={listingItems} mobileActive />
  );

  return (
    <>
      {parent?.title && (
        <div className="container relative z-2 mt-10 mb-4 lg:mb-6">
          <Text as="h3" tag="h2">
            {parent.title}
          </Text>
        </div>
      )}
      <div className={`container relative z-2`} {...storyblokEditable(blok)}>
        <div
          className={`${stylesCrossContentBlock.crossContentSliderWrapper} InternalBlogArticles ${wrapperClasses} ${className}`}
        >
          {listingContent}
        </div>
      </div>
    </>
  );
};

export default InternalBlogArticles;
