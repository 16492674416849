import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Text from "../../Core/Text";
import * as stylesStory from '../Story.module.scss';
import * as styles from './TextCta.module.scss';
import ArrowLink from "../../Core/ArrowLink";

const TextCta = ({ blok, className }) => {
  return (
    <div
      className={`${styles.sTextCta} s-textCta ${className}`}
      data-id={blok._uid}
      {...storyblokEditable(blok)}
    >
      <Text as="h5" className="lg:max-w-lg">{blok.Chapo}</Text>
      {blok.CTA && blok.CTA[0] && blok.CTA[0].link && <ArrowLink href={blok.CTA[0].link.cached_url} className="text-buttonColor-primary mt-5 lg:mt-8">{blok.CTA[0].label}</ArrowLink>}
    </div>
  );
};

export default TextCta;
