import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import * as styles from "./CardPush.module.scss";
import * as stylesCard from "../Card.module.scss";
import Text from "../../Core/Text";
import { SEASON_SUMMER, SEASON_WINTER } from "@/utils/global";
import { upperCaseFirstLetter } from "@/utils/format";
import { useRouter } from "next/router";

export default function CardPush(props) {
  const { data, className } = props;
  const content = data.content || null;

  const PushTag = data.full_slug ? Link : 'div';

  const { locale } = useRouter();

  const [imageBySeason, setImageBySeason] = useState(null);
  const [season, setSeason] = useState(SEASON_WINTER);

  const dataFromApidae = !!data.identifier;

  useEffect(() => {
    let seasonMode = SEASON_WINTER

    if (typeof window !== 'undefined') {
      seasonMode = localStorage.getItem('seasonMode')
    }

    let heroImages = [];
    if (!!content && content.hero?.length > 0) {
      content.hero?.map((item) => {
        if (item.Image) {
          heroImages.push(item.Image);
        }
      });
    }

    setSeason(seasonMode);

    const images = !!content && content.card_thumbnail?.length > 0 ? content.card_thumbnail : []

    if (images?.length === 0) {
      const image = seasonMode === SEASON_WINTER
        ? heroImages[0]
        : heroImages.length > 1
          ? heroImages[1]
          : heroImages[0];
      setImageBySeason(image);
    } else {
      const image = seasonMode === SEASON_WINTER ? images[0] : images.length > 1 ? images[1] : images[0]
      setImageBySeason(image);
    }

  }, [content]);

  let cardName = data.name;
  if (dataFromApidae) {
    const langKeyTrad = `libelle${upperCaseFirstLetter(locale)}`;
    cardName = getLangValue(data.nom, langKeyTrad);
  } else if (data?.content) {
    const slideBySeason =
      season === SEASON_WINTER
        ? data.content?.hero[0]
        : data.content?.hero.length > 1
          ? data.content?.hero[1]
          : data.content?.hero[0];
    cardName =
      data.content?.hero?.length > 0 ? slideBySeason?.title : data.name;
  }

  return (
    <PushTag
      href={data.full_slug ? `/${data.full_slug}` : ''}
      className={`${className} ${stylesCard.card} ${styles.cardPush} relative text-white`}
    >
      <div
        className={`${stylesCard.cardImage} ${styles.cardImage} relative overflow-hidden rounded-xl bg-gray-100`}
      >
        {imageBySeason?.filename && (
          <Image
            src={imageBySeason.filename}
            alt={imageBySeason.alt}
            fill
            sizes="(max-width: 768px) 80vw, (max-width: 1024px) 33vw, 25vw"
            className="object-cover"
          />
        )}
      </div>
      <Text as="h6" tag="h3" className="absolute bottom-4 left-4 right-4 z-2">
        {cardName || 'Lorem ipsum'}
      </Text>
    </PushTag>
  );
}

