import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import Text from "../../Core/Text";
import ArrowLink from "../../Core/ArrowLink";
import { useEffect, useState } from "react";
import * as styles from "./ColorContrastBlock.module.scss";
import darkBgPattern from "@/public/pattern-smaller.png";
import reliefShape from "@/public/relief.png";
import reliefShapeBeige from "@/public/relief-beige-white.png";
import { SEASON_WINTER } from "@/utils/global";

const ColorContrastBlock = ({ blok }) => {
  const [imageBySeason, setImageBySeason] = useState(null);

  // console.log("ColorContrastBlock", blok);

  useEffect(() => {
    let seasonMode = SEASON_WINTER;

    if (typeof window !== "undefined") {
      seasonMode = localStorage.getItem("seasonMode");
    }

    const images = blok?.image?.length > 0 ? blok.image : [blok?.image];
    const image =
      seasonMode === SEASON_WINTER
        ? images[0]
        : images.length > 1
          ? images[1]
          : images[0];

    setImageBySeason(image);
  }, [blok.image]);

  const backgroundDark = blok.background === "dark";
  const layout = blok.layout || "image_right";

  return (
    <div
      className={`${styles.colorContrastBlock
        } column ColorContrastBlock relative ${backgroundDark ? `${styles.backgroundDark} column--dark` : ""
        }`}
      {...storyblokEditable(blok)}
    >
      <div
        className={`${styles.colorContrastBlockContainer
          } container relative pt-20 pb-12 ${backgroundDark ? "lg:pb-12" : "lg:pb-20"
          } lg:grid grid-cols-12 gap-3 z-2 items-center`}
      >
        <div
          className={`${styles.stickyAside} ${layout === "image_left" ? "order-last lg:items-center" : ""
            } flex flex-col mb-3 lg:mb-0 lg:pr-0 lg:col-span-7`}
        >
          <Text as="h3" tag="h2" className="lg:w-4/6 mb-2 lg:mb-5">
            {blok.title}
          </Text>

          {blok.subtitle && (
            <Text
              as="body1"
              tag="p"
              className={`${backgroundDark ? "" : "text-text-h4"
                } pr-4 lg:w-4/6 mb-5 lg:mb-8`}
            >
              {blok.subtitle}
            </Text>
          )}

          {blok.text?.map((paragraph) => (
            <StoryblokComponent
              blok={paragraph}
              key={paragraph._uid}
              className="lg:w-4/6"
            />
          ))}

          {blok.cta && blok.cta[0] && blok.cta[0].link && (
            <div className="lg:w-4/6">
              <ArrowLink
                href={blok.cta[0].link.cached_url}
                link={blok.link}
                className="text-buttonColor-primary mt-5"
              >
                {blok.cta[0].label}
              </ArrowLink>
            </div>
          )}
        </div>

        <div className={`mt-12 lg:mt-0 flex lg:pb-0 lg:col-span-5`}>
          <div
            className={`${styles.blockImage} w-full relative overflow-hidden rounded-xl mb-5 bg-gray-100 lg:mb-0 lg:col-span-4`}
          >
            {imageBySeason?.filename && (
              <Image
                src={imageBySeason.filename}
                alt={imageBySeason.alt || "missing alt"}
                fill
                sizes="(max-width: 768px) 80vw, (max-width: 1024px) 50vw, 33vw"
                className="object-cover"
              />
            )}
          </div>
        </div>
      </div>
      {blok.background === "dark" ? (
        <>
          <div
            className={styles.darkBgPattern}
            style={{
              backgroundImage: `url(${darkBgPattern.src})`,
            }}
          />
          <div className={`${styles.darBgRelief} bottom-0 w-full`}>
            <Image src={reliefShape} alt="" />
          </div>
        </>
      ) : (
        <>
          <div
            className={`${styles.beigeRelief} absolute hidden lg:block top-0 bottom-0 w-full bg-background-beigeDark`}
          >
            <Image
              src={reliefShapeBeige}
              alt=""
              className="w-full object-contain w-full h-full object-bottom"
              width={800}
              height={200}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ColorContrastBlock;
