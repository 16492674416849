export const upperCaseFirstLetter = (s) => {
  if (!s) return;
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};
// Transform a string to a slug
export const slugify = (str) => {
  if (!str) return;
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};

// Split a string into an array of words by \n and create an array of <p> tags
export const splitParagraphs = (str) => {
  if (!str) return;
  const paragraphs = str.split("\n");
  return paragraphs.map((p, i) => <p key={`p-${i}`}>{p}</p>);
};

export const formatDate = (date, locale = "fr") => {
  const options = { day: "2-digit", month: "long" };
  // const options = { day: "2-digit", month: "long", timeZone: "Europe/Paris" };
  return date.toLocaleDateString(locale, options);
};

export const formatDateDayMonth = (date) => {
  const options = { day: "2-digit", month: "2-digit" };
  return new Date(date).toLocaleDateString("fr-FR", options);
};

export const formatDateToUrl = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const addDays = (date, days) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const addWeek = (date) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 7);
  return newDate;
};

export const subtractWeek = (date) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() - 7);
  return newDate;
};
export const getNearestSaturday = (date) => {
  const day = date.getDay();
  const diff = day === 6 ? 0 : day > 6 ? -day + 6 : -day - 1;
  const previousSaturday = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + diff
  );
  return previousSaturday;
};

export const getNextSaturday = (date) => {
  const day = date.getDay();
  const diff = 6 - day;
  const nextSaturday = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + diff
  );
  return nextSaturday;
};

export const formatDurationFromMinutes = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  return `${hours}h${minutesLeft < 10 ? "0" : ""}${minutesLeft}`;
};

export const getLangIsoCode = (lang) => {
  switch (lang) {
    case "fr":
      return "fr-FR";
    case "en":
      return "en-GB";
    case "de":
      return "de-DE";
    case "es":
      return "es-ES";
    case "it":
      return "it-IT";
    case "pt":
      return "pt-PT";
    case "ru":
      return "ru-RU";
    case "zh":
      return "zh-CN";
    default:
      return "fr-FR";
  }
}
