import { storyblokEditable } from "@storyblok/react";
import Text from "../../../Core/Text";
import * as styles from './Feature.module.scss';

const Feature = ({ blok }) => {
  return (
    <Text
      as="body2"
      className={`${styles.sFeature} s-feature w-full`}
      data-id={blok._uid}
      {...storyblokEditable(blok)}
    >
      {blok.name}
    </Text>
  );
};

export default Feature;
