import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import * as stylesStory from "../Story.module.scss";
import * as stylesCard from "../../Card/Card.module.scss";
import * as styles from "./CrossContentBlock.module.scss";
import Text from "../../Core/Text";
import darkBgPattern from "@/public/pattern-smaller.png";
import reliefShape from "@/public/relief.png";
import Button from "../../Core/Button";
import Link from "next/link";
import { SEASON_WINTER } from "@/utils/global";
import { useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";

const CrossContentBlock = ({ blok, nestedData, settings }) => {
  const { t } = useTranslation("common");
  let crossContent = null;

  // console.log('cross content block', blok);
  if (blok.template === "blocks") {
    crossContent = <ContentBlockBlocks blok={blok} nestedData={nestedData} />;
  } else if (blok.template === "grid") {
    crossContent = (
      <>
        <div className="container relative z-2 mt-10 mb-4 lg:mb-6">
          <Text as="h3" tag="h2">
            {blok.title
              ? blok.title
              : settings?.content?.default_4cards[0].source[0]?.item?.content
                ?.title
                ? settings?.content?.default_4cards[0].source[0]?.item?.content
                  ?.title
                : t("cross-content-default-title")}
          </Text>
        </div>

        <div className={`container relative z-2`}>
          {blok.source && blok.source[0] && (
            <StoryblokComponent
              key={blok._uid}
              blok={blok.source[0]}
              nestedData={nestedData}
              template={blok.template}
              background={blok.background}
            />
          )}
        </div>
      </>
    );
  } else if (blok.template === "4_cards") {
    crossContent = blok.source && blok.source[0] && (
      <StoryblokComponent
        key={blok._uid}
        blok={blok.source[0]}
        nestedData={nestedData}
        parent={blok}
        template={blok.template}
        background={blok.background}
      />
    );
  } else {
    crossContent = (
      <>
        {blok.title ? (
          <div className="container relative z-2 lg:flex items-end justify-between mb-4 lg:mb-6">
            <Text as="h3" tag="h2">
              {blok.title}
            </Text>

            {blok.cta && blok.cta[0] && blok.cta[0].link && (
              <Button
                tag="link"
                link={blok.cta[0].link}
                variant="phantom"
                size="Small"
                className="hidden lg:block mt-2 lg:mt-0 mb-1"
                rounded
              >
                {blok.cta[0].label}
              </Button>
            )}
          </div>
        ) : (
          blok.source &&
          blok.source[0]?.item?.content?.title && (
            <div className="container relative z-2 lg:flex items-end justify-between mb-4 lg:mb-6">
              <Text as="h3" tag="h2">
                {blok.source[0]?.item?.content?.title}
              </Text>

              {blok.source[0]?.item?.full_slug && (
                <Button
                  tag="link"
                  href={blok.source[0]?.item?.full_slug}
                  variant="phantom"
                  size="Small"
                  className="hidden lg:block mt-2 lg:mt-0 mb-1"
                  rounded
                >
                  {t("all-activities-cta")}
                </Button>
              )}
            </div>
          )
        )}

        <div className="container relative z-2">
          {blok.source && blok.source[0] && (
            <StoryblokComponent
              key={blok._uid}
              blok={blok.source[0]}
              nestedData={nestedData}
              template={blok.template}
            />
          )}
        </div>
      </>
    );
  }

  return (
    <div
      className={`${stylesStory.column} column crossContentBlock ${styles[blok.template]
        } relative ${blok.background === "dark"
          ? `${stylesStory.columnDarkBg} column--dark`
          : ""
        }`}
      {...storyblokEditable(blok)}
    >
      {crossContent}
      {blok.background === "dark" && (
        <>
          <div
            className={styles.darkBgPattern}
            style={{
              backgroundImage: `url(${darkBgPattern.src})`,
            }}
          />
          <div className="bottom-0 w-full">
            <Image src={reliefShape} alt="" />
          </div>
        </>
      )}
    </div>
  );
};

const ContentBlockBlocks = ({ blok, nestedData }) => {
  const [imageBySeason, setImageBySeason] = useState(null);
  const blokSource = (blok.source && blok.source[0]) || null;

  useEffect(() => {
    let seasonMode = SEASON_WINTER;

    if (typeof window !== "undefined") {
      seasonMode = localStorage.getItem("seasonMode");
    }

    let heroImages = [];
    if (blok.hero?.length > 0) {
      blok.hero?.map((item) => {
        if (item.Image) {
          heroImages.push(item.Image);
        }
      });
    }

    const images =
      blokSource?.item?.content?.card_thumbnail?.length > 0
        ? blokSource.item.content.card_thumbnail
        : blok.titleImage?.length > 0
          ? blok.titleImage
          : [];
    if (images?.length === 0) {
      const image =
        seasonMode === SEASON_WINTER
          ? heroImages[0]
          : heroImages.length > 1
            ? heroImages[1]
            : heroImages[0];
      setImageBySeason(image);
    } else {
      const image =
        seasonMode === SEASON_WINTER
          ? images[0]
          : images.length > 1
            ? images[1]
            : images[0];
      setImageBySeason(image);
    }
  }, [blok]);

  return (
    <div
      className={`container lg:grid grid-cols-12 gap-x-3 mb-16 lg:mb-20 z-2`}
    >
      <div
        className={`${stylesCard.cardImage} ${styles.titleImage} relative block overflow-hidden rounded-xl mb-5 lg:col-span-5 bg-gray-100 text-white lg:sticky top-0 self-start`}
      >
        {imageBySeason?.filename && (
          <Image
            src={imageBySeason.filename}
            alt={imageBySeason.alt}
            fill
            sizes="(max-width: 768px) 90vw, (max-width: 1024px) 50vw, 33vw"
            className="object-cover"
          />
        )}
        <Text as="h3" tag="h2" className="absolute bottom-0 p-6 lg:p-8 z-10">
          {blok.title}
        </Text>
      </div>

      {blokSource && (
        <StoryblokComponent
          key={blok._uid}
          blok={blokSource}
          nestedData={nestedData}
          template={blok.template}
          className="lg:col-span-7 lg:grid-cols-7"
        />
      )}
    </div>
  );
};

export default CrossContentBlock;
