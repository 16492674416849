import { storyblokEditable } from "@storyblok/react";
import * as stylesCrossContentBlock from "../CrossContentBlock.module.scss";
import CardActivity from "@/common/components/Card/Activity";
import CardEdito from "@/common/components/Card/Edito";
import CardsSlider from "@/common/components/CardsSlider";
import CardPush from "@/common/components/Card/Push";

const InternalListing = ({
  blok,
  background,
  template,
  nestedData,
  className,
}) => {
  // console.log('internal listing', blok, template);
  const { item } = blok;
  const listItems = (item && item.content && item.content.list) || [];
  let listingItems = listItems;
  if (nestedData) {
    for (let i = 0; i < listItems.length; i++) {
      const listItem = listItems[i];
      if (typeof listItem === "object") {
        listingItems[i] = listItem;
      } else {
        const nestedDataItem = nestedData.find(
          (nestedDataItem) => nestedDataItem.uuid === listItem
        );

        if (nestedDataItem) {
          listingItems[i] = nestedDataItem;
        }
      }
    }
  }

  // console.log('listingItems', listingItems, nestedData)

  let wrapperClasses = "flex overflow-x-scroll lg:overflow-hidden";
  let listingContent = (
    <div className={`flex flex-nowrap lg:grid grid-cols-7 gap-x-3 lg:w-full`}>
      {listingItems.map((item, index) => (
        <CardActivity
          key={`d-${item.uuid}`}
          data={item}
          className={`${index % 2 === 0
            ? index === 0
              ? "lg:col-span-4 lg:mt-20"
              : "lg:col-span-4 lg:mt-14"
            : "lg:col-span-3 lg:mb-20"
            }`}
        />
      ))}
    </div>
  );

  if (template === "grid") {
    wrapperClasses = "";

    if (background === "dark") {
      listingContent = (
        <>
          <div
            className={`flex flex-col lg:grid gap-x-3 grid-cols-12 gap-y-10 lg:gap-y-20`}
          >
            {listingItems.slice(0, 3).map((item, index) => (
              <CardActivity
                key={`gdf-${item.uuid}`}
                data={item}
                className={`${stylesCrossContentBlock.crossContentBlockCard} col-span-4`}
              />
            ))}
          </div>
          {listingItems.slice(3).length > 0 && (
            <div
              className={`${stylesCrossContentBlock.crossContentSliderWrapper} flex overflow-x-scroll mt-10 lg:mt-16 lg:overflow-hidden`}
            >
              <div
                className={`flex flex-nowrap lg:w-full lg:grid gap-x-3 grid-cols-12`}
              >
                {listingItems.slice(3).map((item, index) => (
                  <CardActivity
                    key={`gd-${item.uuid}`}
                    data={item}
                    className={`${stylesCrossContentBlock.crossContentBlockCard} col-span-3`}
                  />
                ))}
              </div>
            </div>
          )}
        </>
      );
    } else {
      listingContent = (
        <>
          <div
            className={`flex flex-col lg:grid gap-x-3 grid-cols-12 gap-y-10 lg:gap-y-20`}
          >
            {listingItems.map((item, index) => (
              <CardActivity
                key={`gl-${item.uuid}`}
                data={item}
                className={`${stylesCrossContentBlock.crossContentBlockCard} col-span-4`}
              />
            ))}
          </div>
        </>
      );
    }
  } else if (template === "edito") {
    wrapperClasses = "";
    listingContent = (
      <div className={`flex flex-col gap-y-10 lg:gap-y-0 lg:mb-20 w-full`}>
        {listingItems.slice(0, 3).map((item, index) => (
          <CardEdito
            key={`e-${item.uuid || item}`}
            data={item}
            className={`${index === 0 ? "border-t" : ""}`}
          />
        ))}
      </div>
    );
  } else if (template === "thumbnails") {
    if (
      listingItems &&
      listingItems.length > 0 &&
      typeof listingItems[0] === "object"
    ) {
      listingItems[0].large = true;
    }
    wrapperClasses = "";
    listingContent = (
      <CardsSlider slideType={"activity"} slides={listingItems} mobileActive />
    );
  } else if (template === "4_cards") {
    listingContent = (
      <div className="container">
        <div
          className={`mobile-overflow-container flex overflow-x-scroll hide-scroll-bar lg:overflow-x-hidden`}
        >
          <div className="flex flex-nowrap gap-x-3 lg:grid lg:grid-cols-12 lg:w-full">
            {listingItems.map((item, index) => (
              <CardPush
                key={`iacp${item}-${index}`}
                data={item}
                className={`${stylesCrossContentBlock.crossContentBlockCard} col-span-3`}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${stylesCrossContentBlock.crossContentSliderWrapper} internalListing ${wrapperClasses} ${className}`}
      {...storyblokEditable(blok)}
    >
      {listingContent}
    </div>
  );
};

export default InternalListing;
