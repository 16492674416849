import React, { useEffect, useState } from "react";
import Image from "next/image";
import * as styles from "./CardEdito.module.scss";
import * as stylesCard from "../Card.module.scss";
import Text from "../../Core/Text";
import ArrowLink from "../../Core/ArrowLink";
import Link from "next/link";
import { SEASON_WINTER } from "@/utils/global";
import useTranslation from "next-translate/useTranslation";
import CustomLink from "../../CustomLink";

export default function CardEdito(props) {
  const { t } = useTranslation("common");
  const { data, className, variant } = props;
  const content = data.content || null;

  const [imageBySeason, setImageBySeason] = useState(null);

  useEffect(() => {
    let seasonMode = SEASON_WINTER;

    if (typeof window !== "undefined") {
      seasonMode = localStorage.getItem("seasonMode");
    }

    let heroImages = [];
    if (content.hero?.length > 0) {
      content.hero?.map((item) => {
        if (item.Image) {
          heroImages.push(item.Image);
        }
      });
    }

    const images =
      content?.card_thumbnail?.length > 0 ? content.card_thumbnail : [];

    if (images?.length === 0) {
      const image =
        seasonMode === SEASON_WINTER
          ? heroImages[0]
          : heroImages.length > 1
          ? heroImages[1]
          : heroImages[0];
      setImageBySeason(image);
    } else {
      const image =
        seasonMode === SEASON_WINTER
          ? images[0]
          : images.length > 1
          ? images[1]
          : images[0];
      setImageBySeason(image);
    }
  }, [content]);

  let cardName =
    data.content?.hero?.length > 0 ? data.content?.hero[0]?.title : data.name;
  let cardDescription = content.short_description;

  return (
    <CustomLink
      link={data}
      className={`${className} ${stylesCard.card} ${styles.cardEdito} ${styles[variant]} relative flex-1 lg:grid grid-cols-12 lg:gap-x-3 lg:border-b lg:border-filet lg:pt-8 lg:pb-4`}
    >
      <div
        className={`${stylesCard.cardImage} ${styles.cardImage} relative overflow-hidden rounded-xl mb-5 bg-gray-100 lg:mb-0 lg:col-span-4`}
      >
        {imageBySeason?.filename && (
          <Image
            src={imageBySeason.filename}
            alt={imageBySeason.alt || "missing alt"}
            fill
            sizes="(max-width: 768px) 80vw, (max-width: 1024px) 50vw, 33vw"
            className="object-cover"
          />
        )}
      </div>
      <div className="mb-2 lg:col-span-4 lg:pl-5">
        <Text as="h6" tag="h3" className={styles.cardTitle}>
          {cardName || ""}
        </Text>
      </div>
      <div className="lg:col-span-4">
        {cardDescription && (
          <Text
            as="body2"
            className={`pr-6 line-clamp-3 lg:line-clamp-none mb-4`}
          >
            {cardDescription || ""}
          </Text>
        )}
        {data.full_slug && (cardName || cardDescription) && (
          <ArrowLink className="text-buttonColor-primary">
            {t("actions.explore")}
          </ArrowLink>
        )}
      </div>
    </CustomLink>
  );
}
