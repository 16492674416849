import React, { useRef } from "react";
import * as styles from "./InfoBar.module.scss";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Link from "next/link";
import Text from "../../Core/Text";
import CustomLink from "../../CustomLink";

export default function InfoBar(props) {
  const { blok, fixed } = props;
  const infobarRef = useRef(null);

  const handleMouseEnter = () => {
    if (blok?.is_warning) {
      // Pause the animation
      infobarRef.current.classList.add(styles.infoBarWarningPaused);
    }
  };

  const handleMouseLeave = () => {
    if (blok?.is_warning) {
      // Resume the animation
      infobarRef.current.classList.remove(styles.infoBarWarningPaused);
    }
  };

  // const infobarItem = blok?.items && blok.items[0];
  let customStyle = {};
  if (blok?.is_warning) {
    customStyle = {
      backgroundColor: "#fd0",
      color: "#000",
    };
  }

  return (
    <div
      className={`${styles.infoBar} ${fixed ? styles.infoBarFixed : ""} ${blok?.is_warning ? styles.infoBarWarning : ""
        } ${fixed ? "fixed" : "relative"
        } flex items-center justify-center text-white uppercase w-full bg-text-h1 column--dark`}
      style={customStyle}
      {...storyblokEditable(blok)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={infobarRef}
    >
      {/* {blok.items && blok.items[0] && <StoryblokComponent blok={blok.items[0]} />} */}
      {/* {infobarItem?.cta &&
      (infobarItem.cta?.url !== "" || infobarItem.cta?.cached_url !== "") ? (
        <CustomLink
          link={infobarItem?.cta}
          target={infobarItem.cta?.linktype === "url" ? "_blank" : ""}
          alt={infobarItem.cta.label}
          className="container text-center overflow-hidden py-2"
        >
          <Text as="ui2">{infobarItem.title}</Text>
        </CustomLink>
      ) : (
        <div className="container text-center overflow-hidden py-2">
          <Text as="ui2">{infobarItem?.title}</Text>
        </div>
      )} */}
      {blok?.cta &&
        (blok.cta?.url !== "" || blok.cta?.cached_url !== "") ? (
        <CustomLink
          link={blok?.cta}
          target={blok.cta?.linktype === "url" ? "_blank" : ""}
          alt={blok.cta.label}
          className="container text-center overflow-hidden py-2"
        >
          <Text as="ui2">{blok.title}</Text>
        </CustomLink>
      ) : (
        <div className="container text-center overflow-hidden py-2">
          <Text as="ui2">{blok?.title}</Text>
        </div>
      )}
    </div>
  );
}
