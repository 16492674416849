import Link from "next/link";

const CustomLink = ({ link, className, params, children, ...rest }) => {
  let urlHref = null;
  let linkTarget = "";
  if (link?.story) {
    urlHref = `/${link?.story?.full_slug}`;
  } else if (link?.full_slug) {
    urlHref = `/${link?.full_slug}`;
  } else if (link?.url) {
    urlHref = link.url;
  } else if (link?.cached_url) {
    urlHref = link?.cached_url;
  } else if (link != "" && link != null && typeof link === "string") {
    urlHref = link;
  } else {
    urlHref = "";
  }

  if (urlHref?.match(/(\/home)$/gm)) {
    urlHref = "/";
  }

  if (link?.target) {
    linkTarget = link.target;
  } else if (link?.linktype === "url" && !urlHref?.includes("mailto")) {
    linkTarget = "_blank";
  }

  if (params) {
    // Add params (an object) to the url as query params
    urlHref = {
      pathname: urlHref,
      query: params,
    };
  }

  if (linkTarget === "_blank") {
    return (
      <a
        {...rest}
        target={linkTarget}
        className={`${className}`}
        href={urlHref}
      >
        {children}
      </a>
    );
  } else {
    return (
      <Link
        {...rest}
        target={linkTarget}
        className={`${className}`}
        href={urlHref}
      >
        {children}
      </Link>
    );
  }
};

export default CustomLink;
