import React from "react";
import ArrowHeadLeft from "@/public/icons/arrowhead-left.svg";
import ArrowHeadRight from "@/public/icons/arrowhead-right.svg";

const ArrowButton = (props) => {
  const { direction = 'right', action, variant, disabled, className } = props;
  return (
    <button
      className={`inline-flex items-center justify-center py-2 px-5 rounded-full mr-2 ${variant === 'outline' ? 'border border-white/20 bg-transparent' : 'bg-greyBg'} ${disabled ? 'opacity-20' : variant === 'outline' ? 'hover:bg-white/10' : 'hover:bg-gray-200'} ${className}`}
      onClick={action}
      disabled={disabled}
      aria-label={`arrow ${direction}`}
    >
      {direction == 'right' ? <ArrowHeadRight /> : <ArrowHeadLeft />}
    </button>
  );
};

export default ArrowButton;
