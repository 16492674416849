import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import CardCalendar from "../../Card/Calendar";
import CalendarNav from "../../CalendarNav";
import * as styles from "./CalendarWeekBlock.module.scss";
import dynamic from "next/dynamic";
import Icon from "@/utils/Icon";
import Text from "../../Core/Text";
import Button from "@/common/components/Core/Button";

import {
  formatDate,
  formatDateToUrl,
  addDays,
  addWeek,
  subtractWeek,
  getNearestSaturday,
} from "@/utils/format";

import "react-calendar/dist/Calendar.css";
import { PER_PAGE } from "@/hooks/useEvents";
import useTranslation from "next-translate/useTranslation";
import { getLangValue } from "@/utils/global";

const CalendarWeekBlock = ({ blok }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { locale } = useRouter();
  const path = router.asPath.split("?")[0];

  const { start, end } = router.query;
  const [urlSlug, setUrlSlug] = useState(null);

  const [departureDate, setDepartureDate] = useState(null);
  const [arrivalDate, setArrivalDate] = useState(null);
  const [currentDate, setCurrentDate] = useState(
    start ? getNearestSaturday(new Date(start)) : getNearestSaturday(new Date())
  );
  const [filteredItems, setFilteredItems] = useState(blok.content.items || []);
  const [displayedItems, setDisplayedItems] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCurrentDate(
      start
        ? getNearestSaturday(new Date(start))
        : getNearestSaturday(new Date())
    );
  }, []);

  const [weekDays, setWeekDays] = useState([
    {
      label: t("calendar:day-saturday"),
      tradKey: "calendar:day-saturday",
      slug: t("calendar:day-saturday")?.toLowerCase(),
      date: formatDateToUrl(getNearestSaturday(currentDate)),
    },
    {
      label: t("calendar:day-sunday"),
      tradKey: "calendar:day-sunday",
      slug: t("calendar:day-sunday")?.toLowerCase(),
      date: formatDateToUrl(addDays(getNearestSaturday(currentDate), 1)),
    },
    {
      label: t("calendar:day-monday"),
      tradKey: "calendar:day-monday",
      slug: t("calendar:day-monday")?.toLowerCase(),
      date: formatDateToUrl(addDays(getNearestSaturday(currentDate), 2)),
    },
    {
      label: t("calendar:day-tuesday"),
      tradKey: "calendar:day-tuesday",
      slug: t("calendar:day-tuesday")?.toLowerCase(),
      date: formatDateToUrl(addDays(getNearestSaturday(currentDate), 3)),
    },
    {
      label: t("calendar:day-wednesday"),
      tradKey: "calendar:day-wednesday",
      slug: t("calendar:day-wednesday")?.toLowerCase(),
      date: formatDateToUrl(addDays(getNearestSaturday(currentDate), 4)),
    },
    {
      label: t("calendar:day-thursday"),
      tradKey: "calendar:day-thursday",
      slug: t("calendar:day-thursday")?.toLowerCase(),
      date: formatDateToUrl(addDays(getNearestSaturday(currentDate), 5)),
    },
    {
      label: t("calendar:day-friday"),
      tradKey: "calendar:day-friday",
      slug: t("calendar:day-friday")?.toLowerCase(),
      date: formatDateToUrl(addDays(getNearestSaturday(currentDate), 6)),
    },
    {
      label: t("calendar:day-saturday"),
      tradKey: "calendar:day-saturday",
      slug: t("calendar:day-saturday")?.toLowerCase(),
      date: formatDateToUrl(addDays(getNearestSaturday(currentDate), 7)),
    },
  ]);

  // Add useEffect to update url slug when param changes
  useEffect(() => {
    setUrlSlug(router.query.tag || null);
  }, [router.query.tag]);

  // Add useEffect to update items when slug changes
  useEffect(() => {
    const updatedItems =
      urlSlug && blok.content.items
        ? blok.content.items.filter((item) => item.slug === urlSlug)
        : blok.content.items;
    setFilteredItems(updatedItems);
  }, [urlSlug, blok.content.items]);

  useEffect(() => {
    const updatedItems = urlSlug
      ? blok.content.items.filter((item) => item.slug === urlSlug)
      : blok.content.items;

    const filteredBySlugAndDate = updatedItems
      ? updatedItems.filter((item) => {
          const eventDate = new Date(item.eventDate);
          return (
            (!departureDate || eventDate >= departureDate) &&
            (!arrivalDate || eventDate <= arrivalDate)
          );
        })
      : undefined;

    setFilteredItems(filteredBySlugAndDate);
  }, [urlSlug, blok.content.items, departureDate, arrivalDate]);

  const displayLastWeek = () => {
    const updatedWeekDays = weekDays.map((day, index) => ({
      ...day,
      date: formatDateToUrl(addDays(currentDate, index - 7)),
    }));

    setWeekDays(updatedWeekDays);
    setCurrentDate(subtractWeek(currentDate));

    router.push(
      {
        pathname: `${router.locale}${path}`,
        query: {
          start: formatDateToUrl(subtractWeek(currentDate)),
          end: formatDateToUrl(addDays(subtractWeek(currentDate), 6)),
        },
      },
      undefined,
      { shallow: true }
    );
  };

  const displayNextWeek = () => {
    const updatedWeekDays = weekDays.map((day, index) => ({
      ...day,
      date: formatDateToUrl(addDays(currentDate, index + 7)),
    }));

    setWeekDays(updatedWeekDays);
    setCurrentDate(addWeek(currentDate));

    router.push(
      {
        pathname: `${router.locale}${path}`,
        query: {
          start: formatDateToUrl(addDays(currentDate, 7)),
          end: formatDateToUrl(addDays(addWeek(currentDate), 7)),
        },
      },
      undefined,
      { shallow: true }
    );
  };
  const [page, setPage] = useState(1);

  const onSeeMore = async () => {
    setIsLoading(true);

    try {
      const newPage = page + 1;
      // setPage(newPage);
      const newObjects = await fetch(
        `/api/apidae/get-events?start=${
          start ? start : formatDateToUrl(currentDate)
        }&end=${
          end ? end : start ? start : formatDateToUrl(new Date())
        }&first=${newPage * PER_PAGE}&count=${PER_PAGE}&locales=${
          router.locale
        }`
      );

      const newObjectsJson = await newObjects.json();
      const newObjectsTouristiques = newObjectsJson.objetsTouristiques;

      const items = newObjectsTouristiques?.map((item) => {
        const sortedPeriods = item.ouverture.periodesOuvertures.sort(
          (a, b) =>
            Math.abs(new Date(a.dateDebut) - currentDate) -
            Math.abs(new Date(b.dateDebut) - currentDate)
        );

        const closestPeriod = sortedPeriods[0];
        //console.log("closestPeriod--", closestPeriod);

        return {
          slug: "cultures-et-spectacles",
          tag:
            item.type === "FETE_ET_MANIFESTATION"
              ? item.informationsFeteEtManifestation &&
                item.informationsFeteEtManifestation.typesManifestation
                ? item.informationsFeteEtManifestation.typesManifestation[0]
                    .libelleFr
                : ""
              : item.informationsActivite.activitesCulturelles
              ? item.informationsActivite.activitesCulturelles[0].libelleFr
              : "",
          image: item?.illustrations
            ? item?.illustrations[0]?.traductionFichiers[0]?.url
            : "",
          title: getLangValue(
            item?.nom,
            `libelle${upperCaseFirstLetter(locale)}`
          ),
          location: item?.localisation.adresse.commune.nom,
          startDate: closestPeriod.dateDebut,
          endDate: closestPeriod.dateFin,
          horaireOuverture: closestPeriod.horaireOuverture,
        };
      });

      setFilteredItems([...filteredItems, ...items]);
    } catch (error) {
      console.log("errorOnSeeMore", error);
    }
  };

  return (
    <div className="container">
      <Text as="body2" className="block lg:hidden text-center mb-4">
        {t("calendar:week-interval", {
          start: formatDate(currentDate, router.locale),
          end: formatDate(addDays(currentDate, 7), router.locale),
        })}
      </Text>
      <div
        className={`${styles.navContainer} flex w-full justify-between pb-16`}
      >
        <div
          onClick={
            currentDate >= addWeek(getNearestSaturday(new Date()))
              ? displayLastWeek
              : null
          }
          className={`${styles.navButton} flex items-center gap-2  ${
            currentDate >= addWeek(getNearestSaturday(new Date()))
              ? `${styles.active} opacity-100 cursor-pointer`
              : "opacity-50 cursor-not-allowed"
          }`}
        >
          <Icon icon="icon-arrow-left-16" size={16} />
          <Text className={`${styles.textStyle}`} as="body2">
            {t("calendar:interval", {
              start: formatDate(addDays(currentDate, -7), router.locale),
              end: formatDate(currentDate, router.locale),
            })}
          </Text>
        </div>

        <Text as="body2" className="hidden lg:block">
          {t("calendar:week-interval", {
            start: formatDate(currentDate, router.locale),
            end: formatDate(addDays(currentDate, 7), router.locale),
          })}
        </Text>
        <div
          onClick={displayNextWeek}
          className={`${styles.navButton} ${styles.active} flex items-center gap-2 cursor-pointer`}
        >
          <Text className={`${styles.textStyle}`} as="body2">
            {t("calendar:interval", {
              start: formatDate(addDays(currentDate, 7), router.locale),
              end: formatDate(addDays(currentDate, 14), router.locale),
            })}
          </Text>
          <Icon icon="icon-arrow-right-16" size={16} />
        </div>
      </div>

      <CalendarNav
        handleClick={() => updateEventByDay}
        first={t("calendar:filter-all-week")}
        items={weekDays}
        isDate={true}
        currentDate={currentDate}
      />
      <div className=" lg:grid grid-cols-12 pb-16">
        {filteredItems &&
          filteredItems.map((item, index) => (
            <CardCalendar display="wide" key={index} className="" data={item} />
          ))}
        {!filteredItems && (
          <div className="col-span-12">{t("calendar:no-events")}</div>
        )}
      </div>
      {/* {numFound > 21 && ( */}
      {blok.numFound && blok.numFound > PER_PAGE && (
        <div className="w-full text-center mb-14">
          <Button
            // variant={isLoading ? "disabled" : "phantom"}
            variant={"phantom"}
            onClick={onSeeMore}
          >
            {t("common:actions.see-more")}
          </Button>
        </div>
      )}
      {/* )} */}
    </div>
  );
};

export default CalendarWeekBlock;
