import React, { useEffect, useState } from "react";
import { storyblokEditable } from "@storyblok/react";
import * as stylesStory from "../Story.module.scss";
import * as styles from "./MapBlock.module.scss";
import Text from "../../Core/Text";
import dynamic from "next/dynamic";
import { upperCaseFirstLetter } from "@/utils/format";
import { useRouter } from "next/router";
import { getLangValue } from "@/utils/global";
import Checkbox from "../../Core/Checkbox";
import Icon from "@/utils/Icon";

const DynamicGMap = dynamic(() => import("@/common/components/GMapsMap"), {
  loading: () => <p>A google maps map is loading...</p>,
  ssr: false,
});

export default function MapBlock(props) {
  const { blok } = props;
  const { locale } = useRouter();

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [apidaeContentMarkers, setApidaeContentMarkers] = useState([]);
  const [filteredApidaeContentMarkers, setFilteredApidaeContentMarkers] = useState([]);

  const blokApidaeContent =
    blok?.apidae?.selections && blok?.apidae?.selections[0];

  const langKeyTrad = `libelle${upperCaseFirstLetter(locale)}`;

  // console.log('MapBlock', blok, blokApidaeContent)

  useEffect(() => {
    const buildEquipmentMarkers = () => {
      if (blokApidaeContent) {
        const apidaeContent = blokApidaeContent?.content;
        const markers = [];

        for (let i = 0; i < apidaeContent?.length; i++) {
          const item = apidaeContent[i];
          const itemGoeolocation =
            item?.localisation?.geolocalisation?.geoJson?.coordinates;

          if (itemGoeolocation && itemGoeolocation[0] && itemGoeolocation[1]) {
            const adresseArray = [
              item?.localisation?.adresse?.adresse1,
              item?.localisation?.adresse?.adresse2,
              item?.localisation?.adresse?.adresse3,
            ];

            const communeArray = [
              item?.localisation?.adresse?.commune?.codePostal,
              item?.localisation?.adresse?.commune?.nom,
            ];

            const adresseString = adresseArray.filter(Boolean).join(", ");
            const communeString = communeArray.filter(Boolean).join(" ");

            const adresseComplete = `${adresseString}, ${communeString}`;

            let equipments = item?.prestations?.equipements || [];

            if (item.type === "COMMERCE_ET_SERVICE" && item.informationsCommerceEtService?.typesDetailles) {
              let typesDetailles = item?.informationsCommerceEtService?.typesDetailles;

              // Push the typesDetailles to the equipments array
              equipments = [...equipments, ...typesDetailles];
            }
            if (item.type === "EQUIPEMENT" && item.informationsEquipement?.activites) {
              let activites = item?.informationsEquipement?.activites;

              // Push the activites to the equipments array
              equipments = [...equipments, ...activites];
            }

            markers.push({
              _uid: item.id,
              title: getLangValue(item.nom, langKeyTrad),
              address: adresseComplete,
              latitude: itemGoeolocation && itemGoeolocation[1],
              longitude: itemGoeolocation && itemGoeolocation[0],
              equipments: equipments,
            });
          }
        }

        setApidaeContentMarkers(markers);
        setFilteredApidaeContentMarkers(markers);
      }
    };

    buildEquipmentMarkers();
  }, [blokApidaeContent, langKeyTrad]);

  const markers = blok.markers?.map((marker) => {
    return Object.assign({}, marker, {
      latitude: parseFloat(marker.latitude),
      longitude: parseFloat(marker.longitude),
    });
  });

  const handleChangeFilter = (type, value, checked) => {
    console.log('change filter', value, checked)

    if (checked) {
      setSelectedFilters([...selectedFilters, value]);
      onFilterEquipments([...selectedFilters, value]);
    } else {
      const newSelectedFilters = selectedFilters.filter(
        (item) => item.id !== value.id
      );
      setSelectedFilters(newSelectedFilters);

      if (newSelectedFilters.length === 0) {
        setFilteredApidaeContentMarkers(apidaeContentMarkers);
      } else {
        onFilterEquipments(newSelectedFilters);
      }
    }
  };

  const handleRemoveFilter = (value) => {
    const newSelectedFilters = selectedFilters.filter(
      (item) => item.id !== value.id
    );
    setSelectedFilters(newSelectedFilters);

    if (newSelectedFilters.length === 0) {
      setFilteredApidaeContentMarkers(apidaeContentMarkers);
    } else {
      onFilterEquipments(newSelectedFilters);
    }
  };

  const onFilterEquipments = (filters) => {
    const filteredMarkers = apidaeContentMarkers.filter((marker) => {
      for (let i = 0; i < marker.equipments?.length; i++) {
        const equipment = marker.equipments[i];

        const isEquipmentInFilters = filters.find((filter => filter.value === `${equipment.id}`))
        if (isEquipmentInFilters) {
          return true
        }
      }

      return false
      // return marker.equipments?.some((equipment) => {
      //   return filters.some((filter) => filter.value === `${equipment.id}`);
      // });
    });

    setFilteredApidaeContentMarkers(filteredMarkers);
  }

  // console.log(apidaeContentMarkers)

  return (
    <div
      className={`${stylesStory.column} ${styles.sMapBlock} column MapBlock`}
      {...storyblokEditable(blok)}
    >
      {blok?.title && (
        <div className="container relative z-2 mt-10 mb-4 flex items-end justify-between lg:mb-6">
          <Text as="h3" tag="h2">
            {blok.title}
          </Text>
        </div>
      )}

      <div className="container">
        {blok?.equipment_types?.length > 0 && (
          <div className="grid grid-cols-2 gap-x-4 md:grid-cols-4 mb-4">
            {blok?.equipment_types?.map((equipmentType, equipmentTypeIndex) => (
              <Checkbox
                key={`cbmp-${equipmentType._uid}${equipmentTypeIndex.id}`}
                item={{
                  id: equipmentType._uid,
                  label: equipmentType.title,
                  value: equipmentType.equipment_id,
                  elementReferenceType: equipmentType.elementReferenceType,
                }}
                index={equipmentTypeIndex}
                selectedItems={selectedFilters}
                handleChange={handleChangeFilter}
                type={equipmentType.elementReferenceType}
                className="mt-2 w-auto"
              />
            ))}
          </div>
        )}

        {selectedFilters?.length > 0 && (
          <div className="flex flex-wrap gap-x-6 gap-y-2 mb-4">
            <div className="flex gap-x-3">
              {selectedFilters.map((filter, filterIndex) => (
                <button
                  key={`${filterIndex}-filter${filter.id}`}
                  className={`${styles.filterReset} nowrap border border-track-blue rounded-full flex items-center bg-button-h3/[.4] transition hover:opacity-70 py-1 px-2`}
                  onClick={() => handleRemoveFilter(filter)}
                >
                  <Icon
                    icon="icon-cross-16"
                    className="transition-transform duration-300 ease-in-out mr-1"
                    size={17}
                  />
                  <Text as="body3" className="whitespace-nowrap">
                    {filter?.label}
                  </Text>
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="container">
        {markers?.length > 0 && (
          <div
            id="single-map-gmaps"
            className={`column relative ${blok?.title ? "mt-14" : ""
              } mb-16 ${blok?.title ? "lg:mt-20" : ""}`}
          >
            <DynamicGMap
              width={1360}
              height={640}
              center={[markers[0]?.longitude, markers[0]?.latitude]}
              markers={selectedFilters?.length > 0 ? filteredApidaeContentMarkers : [...markers, ...filteredApidaeContentMarkers]}
              zoomLevel={blok.zoom_level}
              interactive={blok.is_interactive}
            />
          </div>
        )}
      </div>
    </div>
  );
}
