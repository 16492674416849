import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Text from "../../Core/Text";
import ArrowLink from "../../Core/ArrowLink";
import * as stylesStory from "../Story.module.scss";
import * as styles from "./Introduction2.module.scss";

const Introduction2 = ({ blok }) => {
  return (
    <div
      className={`${stylesStory.column} ${styles.sIntroduction2} s-introduction w-full`}
      data-id={blok._uid}
      {...storyblokEditable(blok)}
    >
      {blok.title && (
        <div className="container mb-8 lg:mb-12">
          <Text as="h3" className="d--h2 lg:max-w-xl">
            {blok.title}
          </Text>
        </div>
      )}
      <div className="container lg:grid grid-cols-12 lg:gap-x-3">
        <div
          className={`w-full lg:sticky top-0 ${
            blok.variant === "variant-2" ? "col-span-4" : "col-span-6"
          }`}
        >
          <Text as="h5" className="lg:max-w-lg">
            {blok.subtitle}
          </Text>
          {blok.cta &&
            blok.cta[0] &&
            blok.cta[0].link &&
            (blok.variant === "variant-1" || blok.variant === "") && (
              <ArrowLink
                href={blok.cta[0].link.cached_url}
                className="text-buttonColor-primary mt-5 lg:mt-8"
              >
                {blok.cta[0].label}
              </ArrowLink>
            )}
        </div>
        <div
          className={`w-full mt-10 lg:mt-0 ${
            blok.variant === "variant-2" ? "col-span-5" : "col-span-6"
          } ${blok.variant === "variant-2" ? "col-start-6" : ""}`}
        >
          {blok.body &&
            blok.body.map((bodyItem) => (
              <StoryblokComponent
                blok={bodyItem}
                key={bodyItem._uid}
                className="lg:w-5/6"
              />
            ))}
          {blok.cta &&
            blok.cta[0] &&
            blok.cta[0].link &&
            blok.variant === "variant-2" && (
              <ArrowLink
                href={blok.cta[0].link.cached_url}
                className="text-buttonColor-primary mt-5 lg:mt-8"
              >
                {blok.cta[0].label}
              </ArrowLink>
            )}
        </div>
      </div>
    </div>
  );
};

export default Introduction2;
