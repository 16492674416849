import { useEffect, useState } from "react";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";
import * as styles from "./DrawerPage.module.scss";
import * as stylesDrawer from "@/common/components/Modals/Drawer.module.scss";
import Text from "@/common/components/Core/Text";
import Icon from "@/utils/Icon";
import Button from "@/common/components/Core/Button";
import { useRouter } from "next/router";
import { DEFAULT_LOCALE, SEASON_WINTER } from "@/utils/global";
import { getAvalancheRisk, getSkyStatus } from "@/utils/weather";
import { upperCaseFirstLetter } from "@/utils/format";

const WeatherModule = (props) => {
  const { blok, drawerData } = props;
  const router = useRouter();
  const { locale } = useRouter();
  const [selectedDay, setSelectedDay] = useState(null);
  const [daysArray, setDaysArray] = useState([]);
  const [weatherForecast, setWeatherForecast] = useState(new Map());
  const [snowForecast, setSnowForecast] = useState(new Map());
  const [amPm, setAmPm] = useState("am");
  const [stationOrSummit, setStationOrSummit] = useState("station");
  const [seasonMode, setSeasonMode] = useState(SEASON_WINTER);

  const { t } = useTranslation(["common", "calendar"]);

  useEffect(() => {
    const fetchWeatherForecast = async () => {
      // const response = await fetch("/api/lumiplan/get-weather-forecast");
      // if (!response.ok) {
      //   console.error("Error fetchWeatherForecast", response);
      //   return;
      // }

      // const responseSnow = await fetch("/api/lumiplan/get-snow");
      // if (!responseSnow.ok) {
      //   console.error("Error fetchSnow", responseSnow);
      //   return;
      // }

      // const data = await response.json();
      // const dataSnow = await responseSnow.json();
      const { data, dataSnow } = drawerData;

      if (data?.content?.resorts?.length > 0) {
        const resortData = data?.content?.resorts[0];

        const weatherZonesMap = new Map();
        let highestAltitude = 0;
        let highestAltitudeWeatherZone = null;
        for (let i = 0; i < resortData?.weatherZones?.length; i++) {
          const weatherZone = resortData?.weatherZones[i];

          // Find the element with the highest altitude
          // Then, after the loop, push the element to the weatherZones array
          if (weatherZone?.altitude?.value > highestAltitude) {
            highestAltitude = weatherZone?.altitude?.value;
            highestAltitudeWeatherZone = weatherZone;
          }

          if (weatherZone.name?.match(/COURCHEVEL/i)) {
            weatherZonesMap.set("station", weatherZone);
          }
        }

        if (highestAltitudeWeatherZone) {
          weatherZonesMap.set("summit", highestAltitudeWeatherZone);
        }

        // Build a dynamic list of dates from weatherZonesMap first item
        const dates = [];
        // const today = new Date()
        // const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        for (
          let i = 0;
          i < weatherZonesMap.get("station")?.weatherInfos?.length;
          i++
        ) {
          const weatherInfo = weatherZonesMap.get("station")?.weatherInfos[i];
          const weatherInfoDate = new Date(weatherInfo.date);

          // Format weatherInfoDate to YYYY-MM-DD where MM and DD are 2 digits without using toLocaleString
          const weatherInfoDateFormatted = `${weatherInfoDate.getFullYear()}-${(weatherInfoDate.getMonth() < 9 ? "0" : "") +
            (weatherInfoDate.getMonth() + 1)
            }-${(weatherInfoDate.getDate() < 10 ? "0" : "") +
            weatherInfoDate.getDate()
            }`;

          if (i === 0) {
            setSelectedDay(weatherInfoDateFormatted);
          }
          const weekDay = weatherInfoDate
            .toLocaleDateString("en-US", { weekday: "long" })
            .toLowerCase();

          dates.push({
            day: weatherInfoDateFormatted,
            displayDay: t(`calendar:day-${weekDay}`),
            raw: weatherInfoDate,
          });
        }

        setDaysArray(dates);

        setWeatherForecast(weatherZonesMap);
      }
      if (dataSnow?.content?.resorts?.length > 0) {
        const resortDataSnow = dataSnow?.content?.resorts[0];

        const snowZonesMap = new Map();
        let highestAltitude = 0;
        let highestAltitudeSnowZone = null;
        for (let i = 0; i < resortDataSnow?.snowZones?.length; i++) {
          const snowZone = resortDataSnow?.snowZones[i];

          if (snowZone?.altitude?.value > highestAltitude) {
            highestAltitude = snowZone?.altitude?.value;
            highestAltitudeSnowZone = snowZone;
          }

          if (snowZone.name?.match(/COURCHEVEL/i)) {
            snowZonesMap.set("station", snowZone);
          }
        }
        if (highestAltitudeSnowZone) {
          snowZonesMap.set("summit", highestAltitudeSnowZone);
        }

        setSnowForecast(snowZonesMap);
      }
    };

    let seasonMode = SEASON_WINTER;
    if (typeof window !== "undefined") {
      seasonMode = localStorage.getItem("seasonMode");
    }
    setSeasonMode(seasonMode);

    // Call API getWeatherForecast here and set data to state
    fetchWeatherForecast();
  }, [t, drawerData]);

  const onSetAm = () => {
    setAmPm("am");
  };
  const onSetPm = () => {
    setAmPm("pm");
  };

  const onSetStation = () => {
    setStationOrSummit("station");
  };
  const onSetSummit = () => {
    setStationOrSummit("summit");
  };

  const onChangeSelectedDay = (day) => {
    setSelectedDay(day);
  };

  const stationData = weatherForecast.get("station");
  const stationDataDay = stationData?.weatherInfos?.find(
    (weatherInfo) => weatherInfo.date === selectedDay
  );
  const stationSnowData = snowForecast.get("station");

  const summitData = weatherForecast.get("summit");
  const summitDataDay = summitData?.weatherInfos?.find(
    (weatherInfo) => weatherInfo.date === selectedDay
  );
  const summitSnowData = snowForecast.get("summit");
  const periodFromCurrentTime = new Date().getHours() < 12 ? "am" : "pm";

  let stationDataDayNow =
    stationDataDay && periodFromCurrentTime
      ? stationDataDay[periodFromCurrentTime]
      : null;
  let lastModified = stationDataDayNow?.lastModified || null;
  if (lastModified) {
    const lastModifiedDate = new Date(lastModified);

    const day = lastModifiedDate.toLocaleString(router.locale, {
      day: "numeric",
    });
    const month = t(
      `calendar:month-${lastModifiedDate
        .toLocaleString("en-US", { month: "long" })
        .toLowerCase()}`
    );
    const hourMinute = lastModifiedDate.toLocaleString(router.locale, {
      hour: "numeric",
      minute: "numeric",
    });
    const hour = lastModifiedDate.toLocaleString(router.locale, {
      hour: "numeric",
      hour12: locale === DEFAULT_LOCALE ? false : true,
    });
    let minute = lastModifiedDate.toLocaleString(router.locale, {
      minute: "numeric",
    });

    minute = minute.padStart(2, "0");

    lastModified = `${day} ${month} ${t("calendar:at")} ${locale === DEFAULT_LOCALE ? hour + " " + minute : hourMinute
      }`;
  }

  const images = blok?.fond?.length > 0 ? blok?.fond : [];
  const image =
    seasonMode === SEASON_WINTER
      ? images[0]
      : images.length > 1
        ? images[1]
        : images[0];

  return (
    <div className={`${styles.lumiplanModule} ${styles.weatherModule}`}>
      <div className={`${styles.drawerBg} absolute top-0 inset-0`}>
        {blok && (
          <Image
            src={image?.filename || "/meteo-desktop-bg.png"}
            alt={image?.alt || "missing alt"}
            fill
            sizes="(max-width: 768px) 80vw, (max-width: 1024px) 70vw, 50vw"
            className="object-cover"
          />
        )}
      </div>

      <div className="container relative">
        <Text as="h1" tag="h1" className="">
          {blok?.title}
        </Text>

        <div className="mt-4 lg:mt-16 lg:grid grid-cols-12 gap-x-3">
          <div className="lg:col-span-4 lg:flex flex-col justify-between">
            {blok?.subtitle && (
              <Text as="ui2" className="uppercase mb-6 lg:mb-10">
                {blok?.subtitle}
              </Text>
            )}
            <div className="flex whitespace-nowrap overflow-auto gap-x-3 mb-4 lg:flex-col lg:gap-y-2 items-center lg:items-start">
              {daysArray.map((day, index) => (
                <button
                  key={`dmd${index}`}
                  onClick={() => onChangeSelectedDay(day.day)}
                  className={`${selectedDay === day.day ? "" : "opacity-50"
                    } hover:opacity-100`}
                >
                  <Text as="h6" asDesktop="d--h3" className="capitalize">
                    {day.displayDay}
                  </Text>
                </button>
              ))}
            </div>

            <Button
              link={{
                linktype: "url",
                target: "_self",
                url: "/" + router.locale + "/agenda-courchevel",
              }}
              className="hidden w-full mt-6 lg:mb-6 md:w-auto lg:block self-start"
            >
              {t("common:lumiplan.cta-weather-today")}
            </Button>
          </div>
          <div className="lg:col-span-8">
            <Text as="h5" className="hidden lg:inline-block mb-6">
              {`${t(
                `calendar:day-${new Date(selectedDay)
                  .toLocaleDateString("en-US", { weekday: "long" })
                  .toLowerCase()}`
              )}, ${new Date(selectedDay).getDate()} ${t(
                `calendar:month-${new Date(selectedDay)
                  .toLocaleDateString("en-US", { month: "long" })
                  .toLowerCase()}`
              )} ${new Date(selectedDay).getFullYear()}`}
            </Text>
            <div className="gap-2 grid lg:grid-cols-8 lg:gap-3">
              <div className="bg-blur rounded-md flex flex-col items-center col-span-2 lg:col-span-4 lg:row-span-2 py-6 px-8 lg:py-8">
                <Text as="ui2" className="uppercase">
                  {t("common:lumiplan.station")}
                </Text>
                {stationDataDayNow?.skyStatus && (
                  <Icon
                    icon={getSkyStatus(stationDataDayNow?.skyStatus)?.icon}
                    color="#fff"
                    className={`${stylesDrawer.mainWeatherIcon} mt-5 mb-4`}
                  />
                )}
                {stationDataDayNow?.temperature && (
                  <p className={stylesDrawer.mainTemperature}>
                    {stationDataDayNow?.temperature?.value}&deg;
                  </p>
                )}
                <Text as="body2" className="opacity-80 mt-2 text-center">
                  {lastModified}
                </Text>
              </div>
              <div className="bg-blur rounded-md flex flex-col items-center justify-center col-span-1 lg:col-span-2 lg:row-span-1 pt-6 pb-4 px-4 lg:pt-8 lg:pb-6">
                <Text as="ui2" className="uppercase mb-2">
                  {t("common:lumiplan.station")}
                </Text>
                <Text as="ui3" className="mb-1">
                  {t("common:lumiplan.elevation")}{" "}
                  {stationData?.altitude?.value || "-"}m
                </Text>
                <div className="flex items-center gap-x-4">
                  <button onClick={onSetAm}>
                    <Text
                      as="ui1"
                      className={`${amPm !== "am" ? "opacity-50" : ""}`}
                    >
                      {t("common:lumiplan.morning")}
                    </Text>
                  </button>
                  <button onClick={onSetPm}>
                    <Text
                      as="ui1"
                      className={`${amPm !== "pm" ? "opacity-50" : ""}`}
                    >
                      {t("common:lumiplan.afternoon")}
                    </Text>
                  </button>
                </div>
                {amPm === "am" ? (
                  <div key={`stationDataDay-am`}>
                    {stationDataDay?.am?.skyStatus && (
                      <Icon
                        icon={getSkyStatus(stationDataDay?.am?.skyStatus)?.icon}
                        color="#fff"
                        className={`${stylesDrawer.smallWeatherIcon} mt-3 mb-1`}
                      />
                    )}
                    {stationDataDay?.am?.temperature && (
                      <p className={stylesDrawer.secondaryTemperature}>
                        {stationDataDay.am?.temperature?.value}&deg;
                      </p>
                    )}
                  </div>
                ) : (
                  <div key={`stationDataDay-pm`}>
                    {stationDataDay?.pm?.skyStatus && (
                      <Icon
                        icon={getSkyStatus(stationDataDay?.pm?.skyStatus)?.icon}
                        color="#fff"
                        className={`${stylesDrawer.smallWeatherIcon} mt-3 mb-1`}
                      />
                    )}
                    {stationDataDay?.pm?.temperature && (
                      <p className={stylesDrawer.secondaryTemperature}>
                        {stationDataDay.pm?.temperature?.value}&deg;
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div className="bg-blur rounded-md flex flex-col items-center justify-center col-span-1 lg:col-span-2 lg:row-span-1 pt-6 pb-4 px-4 lg:pt-8 lg:pb-6">
                <Text as="ui2" className="uppercase mb-2">
                  {t("common:lumiplan.summit")}
                </Text>
                <Text as="ui3" className="mb-1">
                  {t("common:lumiplan.elevation")}{" "}
                  {summitData?.altitude?.value || "-"}m
                </Text>
                <div className="flex items-center gap-x-4">
                  <button onClick={onSetAm}>
                    <Text
                      as="ui1"
                      className={`${amPm !== "am" ? "opacity-50" : ""}`}
                    >
                      {t("common:lumiplan.morning")}
                    </Text>
                  </button>
                  <button onClick={onSetPm}>
                    <Text
                      as="ui1"
                      className={`${amPm !== "pm" ? "opacity-50" : ""}`}
                    >
                      {t("common:lumiplan.afternoon")}
                    </Text>
                  </button>
                </div>
                {amPm === "am" ? (
                  <div key={`summitDataDay-am`}>
                    {summitDataDay?.am?.skyStatus && (
                      <Icon
                        icon={getSkyStatus(summitDataDay?.am?.skyStatus)?.icon}
                        color="#fff"
                        className={`${stylesDrawer.smallWeatherIcon} mt-3 mb-1`}
                      />
                    )}
                    {summitDataDay?.am?.temperature && (
                      <p className={stylesDrawer.secondaryTemperature}>
                        {summitDataDay.am?.temperature?.value}&deg;
                      </p>
                    )}
                  </div>
                ) : (
                  <div key={`summitDataDay-pm`}>
                    {summitDataDay?.pm?.skyStatus && (
                      <Icon
                        icon={getSkyStatus(summitDataDay?.pm?.skyStatus)?.icon}
                        color="#fff"
                        className={`${stylesDrawer.smallWeatherIcon} mt-3 mb-1`}
                      />
                    )}
                    {summitDataDay?.pm?.temperature && (
                      <p className={stylesDrawer.secondaryTemperature}>
                        {summitDataDay.pm?.temperature?.value}&deg;
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div className="bg-blur rounded-md flex flex-col items-center justify-center col-span-2 lg:col-span-4 lg:row-span-1 py-6 px-5 lg:pt-8 lg:pb-10 lg:px-10">
                <div className="flex items-center mb-5 gap-x-4 lg:mb-6">
                  <button onClick={onSetStation}>
                    <Text
                      as="ui2"
                      className={`uppercase hover:opacity-100 ${stationOrSummit == "station" ? "" : "opacity-50"
                        }`}
                    >
                      {t("common:lumiplan.weather-station")}
                    </Text>
                  </button>
                  <button onClick={onSetSummit}>
                    <Text
                      as="ui2"
                      className={`uppercase hover:opacity-100 ${stationOrSummit == "summit" ? "" : "opacity-50"
                        }`}
                    >
                      {t("common:lumiplan.weather-summit")}
                    </Text>
                  </button>
                </div>
                {stationOrSummit === "station" ? (
                  <ul className={stylesDrawer.conditionsTable}>
                    <li>
                      <Text as="body3" className="">
                        {t("common:lumiplan.weather-wind")}
                      </Text>
                      {stationDataDay && stationDataDayNow?.wind && (
                        <Text as="body3" className="opacity-50 lg:opacity-100">
                          {stationDataDayNow?.wind?.value?.value}km/h
                        </Text>
                      )}
                    </li>
                    <li>
                      <Text as="body3" className="">
                        {t("common:lumiplan.weather-limit_rain")}
                      </Text>
                      {stationDataDay &&
                        stationDataDayNow?.limitRainSnowFall && (
                          <Text
                            as="body3"
                            className="opacity-50 lg:opacity-100"
                          >
                            {stationDataDayNow?.limitRainSnowFall?.value}m
                          </Text>
                        )}
                    </li>
                    <li>
                      <Text as="body3" className="">
                        {t("common:lumiplan.weather-snow-depth")}
                      </Text>
                      {stationSnowData?.snowQuality && (
                        <Text as="body3" className="opacity-50 lg:opacity-100">
                          {stationSnowData.snowTotalDepth?.value}cm (
                          {stationSnowData.snowQuality})
                        </Text>
                      )}
                    </li>
                    <li>
                      <Text as="body3" className="">
                        {t("common:lumiplan.weather-snowslide-risk")}
                      </Text>
                      {/* <Text as="body3" className="opacity-50 lg:opacity-100">3/5</Text> */}
                      {stationSnowData?.avalancheRisk && (
                        <Text as="body3" className="opacity-50 lg:opacity-100">
                          {upperCaseFirstLetter(stationSnowData?.avalancheRisk)}{" "}
                          (
                          {
                            getAvalancheRisk(stationSnowData?.avalancheRisk)
                              ?.level
                          }
                          /5)
                        </Text>
                      )}
                    </li>
                    <li>
                      <Text as="body3" className="">
                        {t("common:lumiplan.weather-fresh-snow-depth")}
                      </Text>
                      {/* <Text as="body3" className="opacity-50 lg:opacity-100">10cm</Text> */}
                      <Text as="body3" className="opacity-50 lg:opacity-100">
                        {stationSnowData?.freshSnowFallDepth24H?.value}cm
                      </Text>
                    </li>
                  </ul>
                ) : (
                  <ul className={stylesDrawer.conditionsTable}>
                    <li>
                      <Text as="body3" className="">
                        {t("common:lumiplan.weather-wind")}
                      </Text>
                      {summitDataDay &&
                        summitDataDay[periodFromCurrentTime]?.wind && (
                          <Text
                            as="body3"
                            className="opacity-50 lg:opacity-100"
                          >
                            {
                              summitDataDay[periodFromCurrentTime]?.wind?.value
                                ?.value
                            }
                            km/h
                          </Text>
                        )}
                    </li>
                    <li>
                      <Text as="body3" className="">
                        {t("common:lumiplan.weather-limit_rain")}
                      </Text>
                      {summitDataDay &&
                        summitDataDay[periodFromCurrentTime]
                          ?.limitRainSnowFall && (
                          <Text
                            as="body3"
                            className="opacity-50 lg:opacity-100"
                          >
                            {
                              summitDataDay[periodFromCurrentTime]
                                ?.limitRainSnowFall?.value
                            }
                            m
                          </Text>
                        )}
                    </li>
                    <li>
                      <Text as="body3" className="">
                        {t("common:lumiplan.weather-snow-depth")}
                      </Text>
                      {summitSnowData?.snowQuality && (
                        <Text as="body3" className="opacity-50 lg:opacity-100">
                          {summitSnowData.snowTotalDepth?.value}cm (
                          {summitSnowData.snowQuality})
                        </Text>
                      )}
                    </li>
                    <li>
                      <Text as="body3" className="">
                        {t("common:lumiplan.weather-snowslide-risk")}
                      </Text>
                      {/* <Text as="body3" className="opacity-50 lg:opacity-100">3/5</Text> */}
                      {summitSnowData?.avalancheRisk && (
                        <Text as="body3" className="opacity-50 lg:opacity-100">
                          {upperCaseFirstLetter(summitSnowData?.avalancheRisk)}{" "}
                          (
                          {
                            getAvalancheRisk(summitSnowData?.avalancheRisk)
                              ?.level
                          }
                          /5)
                        </Text>
                      )}
                    </li>
                    <li>
                      <Text as="body3" className="">
                        {t("common:lumiplan.weather-fresh-snow-depth")}
                      </Text>
                      {/* <Text as="body3" className="opacity-50 lg:opacity-100">10cm</Text> */}
                      <Text as="body3" className="opacity-50 lg:opacity-100">
                        {summitSnowData?.freshSnowFallDepth24H?.value}cm
                      </Text>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <Button
          link={{
            linktype: "url",
            target: "_self",
            url: "/" + router.locale + "/agenda-courchevel",
          }}
          className={`${styles.weatherModuleCta} w-full mt-8 lg:mt-6 md:w-auto`}
        >
          {t("common:lumiplan.cta-weather-today")}
        </Button> */}
      </div>
    </div>
  );
};

export default WeatherModule;
