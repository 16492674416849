import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Text from "../../Core/Text";
import ArrowLink from "../../Core/ArrowLink";
import * as stylesStory from '../Story.module.scss';
import * as styles from './BlogContent.module.scss';

const BlogContent = ({ blok }) => {
  // console.log('blok', blok)
  return (
    <div
      className={`${stylesStory.column} ${styles.sBlogContent} s-BlogContent w-full`}
      data-id={blok._uid}
      {...storyblokEditable(blok)}
    >
      <div className="container lg:grid grid-cols-12 lg:gap-x-3">
        <div className={`w-full lg:col-start-4 lg:col-end-10`}>
          {blok.content?.map((contentItem) => !contentItem.is_hidden && <StoryblokComponent blok={contentItem} key={contentItem._uid} />)}
        </div>
      </div>
    </div>
  );
};

export default BlogContent;
