import { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import * as styles from "./CardsSlider.module.scss";
import CardActivity from "../Card/Activity";
import CardAgenda from "../Card/Agenda";
import ArrowButton from "../Core/ArrowButton";

const CardsSlider = ({ slides, slideType, mobileActive }) => {
  // console.log(slides);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    active: false,
    breakpoints: { '(min-width: 1024px)': { active: true, slidesToShow: 2.5 } },
    align: 'start',
    // containScroll: 'trimSnaps',
  })
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi],
  )
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi],
  )

  const scrollTo = useCallback(
    (index) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi],
  )

  const onInit = useCallback((emblaApi) => {
    // setScrollSnaps(emblaApi.scrollSnapList())
  }, [])

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap())
    setPrevBtnEnabled(emblaApi.canScrollPrev())
    setNextBtnEnabled(emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onInit(emblaApi)
    onSelect(emblaApi)
    emblaApi.on('reInit', onInit)
    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onInit, onSelect])

  const SlideComponent = slideType === 'activity' ? CardActivity : CardAgenda;

  return (
    <div className={`${styles.cardsSlider} mobile-overflow-container`}>
      <div className={`embla ${styles.cardsSliderSlider}`} ref={emblaRef}>
        <div className={`embla__container ${mobileActive ? '' : 'flex-col lg:flex-row'}`}>
          {slides && slides.map((slide, slideIndex) => <SlideComponent data={slide} key={`${slide._uid}-${slideIndex}`} large={slide.large} className={`embla__slide ${styles.emblaSlide} ${slideType === 'activity' ? styles.emblaSlideActivity : ''} ${mobileActive ? '' : 'mb-10'}`} />)}
        </div>
      </div>

      <div className={`${styles.emblaNavigation} left-0`} onClick={scrollPrev}>
        <ArrowButton disabled={!prevBtnEnabled} direction="left" className={`left-0 ml-10 ${styles.emblaNavigationButton} ${styles.emblaNavigationButtonPrev}`} />
      </div>
      <div className={`${styles.emblaNavigation} right-0`} onClick={scrollNext}>
        <ArrowButton disabled={!nextBtnEnabled} className={`right-0 mr-10 ${styles.emblaNavigationButton} ${styles.emblaNavigationButtonNext}`} />
      </div>
    </div>
  );
};

export default CardsSlider;