import Image from "next/image";
import { useCallback, useState } from "react";

const PLACEHOLDER_SRC = `data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D`;

export const SingleMediaFull = (props) => {
  const { img, inView } = props;
  const [hasLoaded, setHasLoaded] = useState(false);

  const setLoaded = useCallback((e) => {
    if (inView) setHasLoaded(true);
  }, [inView]);

  return (
    <div className="embla__slide relative">
      <Image
        src={inView || hasLoaded ? img : PLACEHOLDER_SRC}
        alt="img alt"
        // width={index === 0 ? 600 : 320}
        // height={index === 0 ? 270 : 150}
        fill
        onLoad={setLoaded}
        className="w-full h-full object-cover"
      />
    </div>
  )
}

export default SingleMediaFull;