import { storyblokEditable } from "@storyblok/react";
import Text from "../../Core/Text";
import * as styles from "./BlogImage.module.scss";
import { storyblokContentRenderOptions } from "../Paragraph";

import ImageHeader from "@/public/webcam-bg.png";
import Image from "next/image";
const BlogImage = ({ blok, className }) => {
  //console.log("blogimage", blok);
  return (
    <div
      className={`${styles.blogImage} mb-14 lg:mb-20 mt-14 ${className}`}
      {...storyblokEditable(blok)}
    >
      <div className="relative">
        <Image
          className={`${styles.image} max-w-full rounded-xl`}
          src={blok.image.filename}
          fill
          alt={"missing alt"}
        />
      </div>
      <Text as="body3" className="text-text-h4 mt-4">
        {blok.legende}
      </Text>
    </div>
  );
};

export default BlogImage;
