import {
  StoryblokComponent,
  storyblokEditable,
  useStoryblokState,
} from "@storyblok/react";
import Head from "next/head";
import dynamic from "next/dynamic";
import * as styles from "./ActivityPage.module.scss";
import * as stylesStory from "@/common/components/Story/Story.module.scss";
import * as stylesHero from "@/common/components/Hero/Hero.module.scss";
import Hero from "@/common/components/Hero";
import CardActivity from "@/common/components/Card/Activity";
import Text from "@/common/components/Core/Text";
import Button from "@/common/components/Core/Button";
import { use, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PER_PAGE } from "@/hooks/useEvents";
import PushBlock from "../../PushBlock";
import Icon from "@/utils/Icon";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import ActivityFilterDropdown from "@/common/components/ActivityFilterDropdown";
import InfoBar from "../../InfoBar";
import { getLangValue } from "@/utils/global";
import { slugify, upperCaseFirstLetter } from "@/utils/format";

const DynamicFollowBlock = dynamic(
  () => import("@/common/components/FollowBlock"),
  {
    loading: () => (
      <div>
        <p>Loading...</p>
      </div>
    ),
  }
);

export default function ActivityPage({
  blok,
  settings,
  nestedData,
  apidaeData,
  dataFilters,
  dataConfig,
  randomSeed,
}) {
  // console.log("ActivityPage blok", blok, dataConfig);
  const { t } = useTranslation("common");

  let title =
    blok.hero && blok.hero[1]
      ? blok.hero[1]?.title + ` - ${t("courchevel")}`
      : blok.hero && blok.hero[0]
      ? blok.hero[0].title + ` - ${t("courchevel")}`
      : t("courchevel");

  let description =
    blok.hero && blok.hero[1]
      ? blok.hero[1]?.title + ` - ${t("courchevel")}`
      : blok.hero && blok.hero[0]
      ? blok.hero[0].title + ` - ${t("courchevel")}`
      : t("courchevel");
  let robots = "";
  if (blok?.meta_title) {
    title = blok.meta_title;
  }
  if (blok?.meta_description) {
    description = blok.meta_description;
  }
  if (blok?.hide_from_search) {
    robots = blok.hide_from_search ? "noindex, nofollow" : "index, follow";
  }
  // console.log("ActivityPage blok", blok, apidaeData);
  const introduction =
    blok.content &&
    blok.content.find((item) => item.component === "introduction");
  const { objetsTouristiques, query, numFound } = apidaeData;
  const { locale, query: routeQuery } = useRouter();

  const langKeyTrad = `libelle${upperCaseFirstLetter(locale)}`;

  const [allObjects, setAllObjects] = useState(objetsTouristiques || []);

  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const selectedFiltersFromUrl = [];
  if (dataFilters?.length > 0) {
    for (const filter of dataFilters) {
      if (routeQuery?.filter?.includes(filter.slug)) {
        for (const critere of filter?.elementsReference) {
          selectedFiltersFromUrl.push(critere);
        }
      }
    }
  }

  const [selectedFilters, setSelectedFilters] = useState(
    selectedFiltersFromUrl || []
  );
  const [previousFilters, setPreviousFilters] = useState([]);

  const [stateNumFound, setStateNumFound] = useState(numFound || 0);

  const contentClone = blok.content && [...blok.content];
  const contentWithoutIntroduction =
    contentClone &&
    contentClone.filter((item) => item.component !== "introduction");

  const faq = blok.faq && blok.faq[0];

  const onSeeMore = async () => {
    setIsLoading(true);

    try {
      const newPage = page + 1;
      let newObjectsUrl = `/api/apidae/get-activity?selectionIds=["${
        query.selectionIds[0]
      }"]&first=${newPage * PER_PAGE}&locales=${locale},en&territoireIds=82490`;
      if (randomSeed) {
        newObjectsUrl += `&order=RANDOM&randomSeed=${randomSeed}`;
      }

      const newObjects = await fetch(newObjectsUrl);

      if (!newObjects.ok) {
        throw new Error("onSeeMore", newObjects);
      }

      const newObjectsJson = await newObjects.json();
      const newObjectsTouristiques = newObjectsJson.objetsTouristiques;

      setAllObjects([...allObjects, ...newObjectsTouristiques]);
      setPage(newPage);
    } catch (error) {
      console.error("onSeeMore", error);
    }
    setIsLoading(false);
  };

  const onFilterActivities = useCallback(
    async (filtersData) => {
      const filtersToUse = filtersData || selectedFilters;

      // Check that the filters are not the same as the previous ones
      if (
        (filtersToUse.length > 0 || previousFilters.length > 0) &&
        JSON.stringify(filtersToUse) === JSON.stringify(previousFilters)
      ) {
        return;
      }

      setIsLoading(true);

      const critereArray = [];
      const critereParamsArray = [];
      for (const filter of filtersToUse) {
        // for (const critere of filter?.criteres) {
        //   critereArray.push(
        //     `${critere.type}:${critere.type_key}_${critere.id}`
        //   );
        // }
        critereArray.push(
          `critere:${filter.elementReferenceType}_${filter.id}`
        );
        critereParamsArray.push(`${slugify(filter.label)}-${filter.id}`);
      }
      const critereString = critereArray?.join("+");
      const randomSeed = Math.random().toString(36).substring(2, 7);

      try {
        const newObjects = await fetch(
          `/api/apidae/get-activity?selectionIds=["${
            query.selectionIds[0]
          }"]&first=${page * PER_PAGE}&locales=${locale},en&criteresQuery=${
            critereString ? critereString : ""
          }`
        );

        if (!newObjects.ok) {
          throw new Error("onFilterActivities", newObjects);
        }

        const newObjectsJson = await newObjects.json();
        const newObjectsTouristiques = newObjectsJson?.objetsTouristiques || [];
        setStateNumFound(newObjectsJson?.numFound || 0);

        // Update the url
        // If the filters are empty, we remove the filter param
        if (critereParamsArray.length === 0) {
          window.history.pushState("", "", window.location.pathname);
        } else {
          window.history.pushState(
            "",
            "",
            window.location.pathname +
              "?filter=" +
              critereParamsArray?.join(",")
          );
        }

        setAllObjects([...newObjectsTouristiques]);
        setPage(0);
      } catch (error) {
        console.error("onFilterActivities", error);
      }

      setPreviousFilters(filtersToUse);

      setIsLoading(false);
    },
    [selectedFilters, previousFilters, page, query.selectionIds, locale]
  );

  const handleRemoveFilter = (value) => {
    const newSelectedFilters = selectedFilters.filter(
      (item) => item.id !== value.id
    );
    setSelectedFilters(newSelectedFilters);

    onFilterActivities(newSelectedFilters);
  };

  const onSelectFilter = (value) => {
    setSelectedFilters(value);
  };

  useEffect(() => {
    const filterQuery = routeQuery?.filter;
    if (filterQuery) {
      const filterQueryArray = filterQuery.split(",");
      const filterQueryArrayObject = filterQueryArray.map((item) => {
        let filter = null;
        // Filters will have this format: "slugn-ame-id" (a slug followed by an id separated by -) get only the id part
        let id = item.split("-").pop();
        console.log("ID from url", id);

        for (const dataFilter of dataFilters) {
          const foundElInsideReference = dataFilter?.elementReferences?.find(
            (elref) => `${elref.id}` === id
          );
          if (foundElInsideReference) {
            filter = {
              id: foundElInsideReference.id,
              label: getLangValue(foundElInsideReference, langKeyTrad),
              elementReferenceType: foundElInsideReference.elementReferenceType,
            };

            break;
          }
        }

        // const filter = dataFilters.find((filter) => filter.elementsReference.find((elref) => elref.id === item && {
        //   id: element.id,
        //   label: getLangValue(element, langKeyTrad),
        //   elementReferenceType: element.elementReferenceType,
        // }));
        return filter;
      });
      console.log(
        "filterQueryArrayObject",
        dataFilters,
        filterQueryArrayObject
      );
      setSelectedFilters(filterQueryArrayObject);
    }
  }, [routeQuery, dataFilters]);

  useEffect(() => {
    console.log("Objects touristiques changed", objetsTouristiques?.length);
    if (objetsTouristiques?.length > 0) {
      setAllObjects(objetsTouristiques);
    }
  }, [objetsTouristiques]);

  console.log(
    "ActivityPage blok (id - obj touristiques - allObjects)",
    blok?._uid,
    objetsTouristiques?.length,
    allObjects.length
  );

  return (
    <div className={styles.activityPage} {...storyblokEditable(blok)}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        {robots && <meta name="robots" content={robots} />}
      </Head>
      <Hero
        content={blok.hero}
        {...storyblokEditable(blok.hero)}
        settings={settings}
      />
      <div className={stylesHero.heroPlaceholder}></div>
      {settings?.content?.infobar && settings?.content?.infobar[0] && (
        <InfoBar blok={settings.content.infobar[0]} />
      )}
      <div className="content-blocks relative bg-white z-10">
        <div className={`${stylesStory.column} column`}>
          <div className="container flex flex-col lg:flex-row lg:gap-x-3">
            {introduction && !introduction.is_hidden && (
              <StoryblokComponent
                blok={introduction}
                settings={settings}
                key={introduction._uid}
              />
            )}
          </div>
        </div>

        <div className={`${stylesStory.column} column`}>
          <div className="container">
            <div className="lg:flex justify-between">
              <Text as="h3" tag="h2" className="mb-6">
                {blok.title_listing_apidae
                  ? blok.title_listing_apidae
                  : settings?.content?.default_apidae_listing_title
                  ? settings?.content?.default_apidae_listing_title
                  : t("activity-selection-title")}
              </Text>
              {/* {dataFilters?.length > 0 && (
                <div className="flex items-center gap-x-6">
                  <div className="flex gap-x-3">
                    {selectedFilters.map((filter, filterIndex) => (
                      <button
                        key={`${filterIndex}-filter${filter.id}`}
                        className={`${styles.filterReset} nowrap border border-track-blue rounded-full flex items-center bg-button-h3/[.4] transition hover:opacity-70 py-1 px-2`}
                        onClick={() => handleRemoveFilter(filter)}
                      >
                        <Icon
                          icon="icon-cross-16"
                          className="transition-transform duration-300 ease-in-out mr-1"
                          size={17}
                        />
                        <Text as="body3" className="whitespace-nowrap">
                          {filter?.label}
                        </Text>
                      </button>
                    ))}
                  </div>
                  <div ref={refFilterDropdown} className="relative">
                    <button
                      onClick={handleClickFilter}
                      onKeyDown={handleKeyDown}
                      type="button"
                      id="filterMenuButton"
                      className={`${styles.dropdownTrigger} flex items-center cursor-pointer gap-3`}
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded={filterOpen}
                    >
                      {t("actions.filter")}

                      <Icon icon="icon-filter-24" size={24} />
                    </button>
                    <div
                      className={`${styles.dropdownMenu} hidden absolute right-0 p-4 lg:p-6 rounded-md drop-shadow-md z-10`}
                      aria-labelledby="filterMenuButton"
                    >
                      {dataFilters?.map((filter, filterIndex) => (
                        <Checkbox
                          key={`${filterIndex}-f`}
                          item={filter}
                          index={filterIndex}
                          selectedItems={selectedFilters}
                          handleChange={handleToggleFilter}
                          type={"f"}
                          className="mt-2"
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )} */}
              {dataFilters?.length > 0 && (
                <div className="flex flex-wrap items-center gap-x-6 gap-y-2 mb-6 lg:mb-0">
                  {selectedFilters?.length > 0 && (
                    <div className="flex gap-x-3">
                      {selectedFilters.map((filter, filterIndex) => (
                        <button
                          key={`${filterIndex}-filter${filter?.id || filter}`}
                          className={`${styles.filterReset} nowrap border border-track-blue rounded-full flex items-center bg-button-h3/[.4] transition hover:opacity-70 py-1 px-2`}
                          onClick={() => handleRemoveFilter(filter)}
                        >
                          <Icon
                            icon="icon-cross-16"
                            className="transition-transform duration-300 ease-in-out mr-1"
                            size={17}
                          />
                          <Text as="body3" className="whitespace-nowrap">
                            {filter?.label}
                          </Text>
                        </button>
                      ))}
                    </div>
                  )}
                  {dataFilters?.map((filter, filterIndex) => (
                    <ActivityFilterDropdown
                      key={`${filterIndex}-fg-${filter.id}`}
                      filter={filter}
                      selectedFilters={selectedFilters}
                      onSelectFilter={onSelectFilter}
                      onFilterActivities={onFilterActivities}
                    />
                  ))}
                </div>
              )}
            </div>

            <div
              key={blok._uid}
              className="flex flex-col gap-y-10 md:grid md:grid-cols-12 md:gap-3 lg:gap-y-14"
            >
              {allObjects?.map((item, itemIndex) => (
                <CardActivity
                  key={`${item.id}-${itemIndex}`}
                  data={item}
                  className="md:col-span-6 lg:col-span-4"
                />
              ))}
              {selectedFilters.length > 0 && allObjects.length === 0 && (
                <div className="flex-1 col-span-6">{t("no-results")}</div>
              )}
            </div>
            {stateNumFound > 21 && (
              <div className="w-full text-center mt-14">
                <Button
                  variant={isLoading ? "disabled" : "phantom"}
                  onClick={onSeeMore}
                >
                  {t("actions.see-more")}
                </Button>
              </div>
            )}
          </div>
        </div>

        {contentWithoutIntroduction &&
          contentWithoutIntroduction.map((nestedBlok) => (
            <StoryblokComponent
              blok={nestedBlok}
              key={nestedBlok._uid}
              nestedData={nestedData}
              settings={settings}
            />
          ))}

        {blok.cross_content &&
          blok.cross_content.map((crossContentBlock) => (
            <StoryblokComponent
              blok={{
                component: "cross_content_block",
                source: [crossContentBlock],
                template: "thumbnails",
              }}
              settings={settings}
              key={`ccbi-${crossContentBlock._uid}`}
              nestedData={nestedData}
            />
          ))}

        {blok.second_cross_content &&
          blok.second_cross_content.map((crossContentBlock) => (
            <StoryblokComponent
              settings={settings}
              blok={crossContentBlock}
              key={`ccsbi-${crossContentBlock._uid}`}
              nestedData={nestedData}
            />
          ))}
      </div>

      {/* <DynamicFollowBlock /> */}
      {faq && <StoryblokComponent blok={faq} />}
    </div>
  );
}
