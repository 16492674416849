import React from "react";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { getNearestSaturday, formatDateToUrl } from "@/utils/format";
import * as styles from "./CalendarNav.module.scss";
import Icon from "@/utils/Icon";
import { addDays } from "@/utils/format";
import Text from "../Core/Text";
import useTranslation from "next-translate/useTranslation";

export default function CalendarNav({
  items,
  active,
  first,
  handleClick,
  isDate,
  categ,
  currentDate,
}) {
  const router = useRouter();
  const { locale } = useRouter();
  const path = router.asPath.split("?")[0];

  const { t } = useTranslation();

  const [activeItem, setActiveItem] = useState(null);
  const [category, setCategory] = useState(null);
  const segments = path.split("/");

  useEffect(() => {
    if (segments.length > 2) {
      setCategory(segments[2]);
    }
  }, [path]);
  const filterElement = (date, index) => {
    const updatedQuery = { ...router.query };
    // updatedQuery.tag = slug;

    if (isDate) {
      router.push(
        {
          pathname: `${router.locale}${path}`,
          query: {
            start: date,
            // date: formatDateToUrl(currentDate),
          },
        },
        undefined,
        { shallow: true }
      );
    } else {
      router.push(
        {
          pathname: `${router.locale}${path}`,
        },
        undefined,
        { shallow: true }
      );
    }

    setActiveItem(index);
    setDropDownToggle(false);
  };

  useEffect(() => {
    if (!router.query.date) {
      setActiveItem(-1);
    }
  }, [router.query.tag]);

  const showAllEvents = () => {
    const updatedQuery = { ...router.query };

    delete updatedQuery.tag;

    if (currentDate) {
      router.push(
        {
          pathname: `${router.locale}${path}`,
          query: {
            // start: formatDateToUrl(currentDate),
            // end: formatDateToUrl(addDays(currentDate, 7)),
          },
        },
        undefined,
        { shallow: true }
      );
    } else {
      router.push(
        {
          pathname: `${router.locale}/${segments[1]}`,
        },
        undefined,
        { shallow: true }
      );
    }
    setActiveItem(-1);
  };

  const [dropDownToggle, setDropDownToggle] = useState(false);
  const toggleDropDown = () => {
    setDropDownToggle(!dropDownToggle);
  };

  const checkIfDisabledDate = (dateValue) => {
    return (
      new Date(dateValue) < new Date() &&
      new Date(dateValue).toDateString() != new Date().toDateString()
    );
  };

  return (
    <div className="lg:flex justify-between lg:border-b border-filet">
      <div className="hidden navContainer lg:flex gap-6 ">
        <a
          className={`${styles.navLink} pb-5 ${
            (segments.length < 3 && activeItem === -1) ||
            (segments.length < 3 && router.query.end)
              ? styles.active
              : ""
          } cursor-pointer`}
          onClick={() => showAllEvents()}
          href={!isDate ? "/" + locale + "/" + segments[1] : undefined}
          // onClick={handleClick()}
        >
          {first}
        </a>
        {items.map((item, index) => (
          <a
            className={`pr-1 pb-5 ${
              (router.query.start === item.date &&
                !router.query.end &&
                activeItem != -1 &&
                currentDate) ||
              item.slug === categ
                ? `${styles.active}`
                : ""
            } ${
              checkIfDisabledDate(item.date)
                ? "pointer-event-none cursor-not-allowed text-gray-400"
                : "cursor-pointer " + styles.navLink
            }`}
            onClick={() =>
              checkIfDisabledDate(item.date)
                ? {}
                : filterElement(item.date, index)
            }
            key={index}
            href={
              item.slug && !isDate
                ? "/" + locale + "/" + segments[1] + "/" + item.slug
                : undefined
            }
          >
            {!isDate ? item.label : t(item.tradKey)}
          </a>
        ))}
      </div>
      <div className="relative mb-3">
        <div
          onClick={() => toggleDropDown()}
          className="flex gap-2 items-center cursor-pointer lg:hidden"
        >
          <Text className="text-button-h4" as="body2">
            {first}
          </Text>
          <Icon
            className={` transition-all duration-300 ${
              dropDownToggle ? "rotate-180" : ""
            }`}
            icon="icon-arrow-down-16"
            size={16}
          />
        </div>
        <div
          className={`absolute z-1 bg-white  flex-col gap-2 pb-2 ${
            dropDownToggle ? "flex" : "hidden"
          }`}
        >
          {segments.length > 2 ? (
            <a className="pr-5 cursor-pointer" onClick={() => showAllEvents()}>
              {t("calendar:all-events")}
            </a>
          ) : null}

          {items.map((item, index) => (
            <a
              className={`pr-5 
            
               ${
                 checkIfDisabledDate(item.date)
                   ? "pointer-event-none cursor-not-allowed text-gray-400"
                   : "cursor-pointer "
               }`}
              onClick={() =>
                checkIfDisabledDate(item.date)
                  ? {}
                  : filterElement(item.date, index)
              }
              key={index}
              href={
                item.slug && !isDate
                  ? "/" + locale + "/" + segments[1] + "/" + item.slug
                  : undefined
              }
            >
              {!isDate ? item.label : t(item.tradKey)}
            </a>
          ))}
        </div>
      </div>
      {/* <div className="flex items-center cursor-pointer gap-3">
        <Text as="body2" className="text-text-h1">
          Filtrer
        </Text>

        <Icon icon="icon-filter-24" size={24} />
      </div> */}
    </div>
  );
}
