import React, { useEffect, useState } from "react";
import Image from "next/image";
import * as styles from "./CardActivity.module.scss";
import * as stylesCard from "../Card.module.scss";
import Text from "../../Core/Text";
import ArrowLink from "../../Core/ArrowLink";
import { useRouter } from "next/router";
import Link from "next/link";
import { slugify, upperCaseFirstLetter } from "@/utils/format";
import {
  DEFAULT_LOCALE,
  SEASON_WINTER,
  getLangValue,
  getLangValueWithoutFallback,
} from "@/utils/global";
import useTranslation from "next-translate/useTranslation";
import CustomLink from "../../CustomLink";
export default function CardActivity(props) {
  const { t } = useTranslation("common");
  const { locale } = useRouter();
  const { data, className, variant } = props;
  const content = data.content || null;
  const dataFromApidae = !!data.identifier;

  const [imageBySeason, setImageBySeason] = useState(null);

  useEffect(() => {
    if (!dataFromApidae && content) {
      let seasonMode = SEASON_WINTER;

      if (typeof window !== "undefined") {
        seasonMode = localStorage.getItem("seasonMode");
      }

      let heroImages = [];

      if (content.hero?.length > 0) {
        content.hero?.map((item) => {
          if (item.Image) {
            heroImages.push(item.Image);
          }
        });
      }

      const images =
        content.card_thumbnail?.length > 0 ? content.card_thumbnail : [];

      if (images?.length === 0) {
        const image =
          seasonMode === SEASON_WINTER
            ? heroImages[0]
            : heroImages.length > 1
              ? heroImages[1]
              : heroImages[0];
        setImageBySeason(image);
      } else {
        const image =
          seasonMode === SEASON_WINTER
            ? images[0]
            : images.length > 1
              ? images[1]
              : images[0];

        setImageBySeason(image);
      }
    }
  }, [content, dataFromApidae]);

  let cardThumbnail = null;
  let cardName = null;
  let cardDescription = null;
  let cardLink = null;
  let cardIsPalace = false;
  if (dataFromApidae) {
    const langKeyTrad = `libelle${upperCaseFirstLetter(locale)}`;
    const defaultLangKeyTrad = `libelle${upperCaseFirstLetter(DEFAULT_LOCALE)}`;
    if (data.illustrations?.length) {
      let langTradIllustration = data.illustrations[0].traductionFichiers.find(
        (item) => item.locale === locale
      );
      if (!langTradIllustration) {
        langTradIllustration = data.illustrations[0].traductionFichiers.find(
          (item) => item.locale === "en"
        );
      }
      if (!langTradIllustration) {
        langTradIllustration = data.illustrations[0].traductionFichiers.find(
          (item) => {
            return item.locale === DEFAULT_LOCALE;
          }
        );
      }

      if (langTradIllustration) {
        cardThumbnail = {
          filename: langTradIllustration.url,
          alt: data.illustrations[0]?.legende
            ? getLangValueWithoutFallback(
              data.illustrations[0].legende,
              langKeyTrad
            )
            : "",
        };
      }
    }

    cardName = getLangValue(data.nom, langKeyTrad);
    cardDescription =
      data.presentation && data.presentation.descriptifCourt
        ? getLangValueWithoutFallback(
          data.presentation.descriptifCourt,
          langKeyTrad
        )
        : null;
    // cardLink = (locale !== DEFAULT_LOCALE ? locale + '/' : '') + slugify(data.type) + '/' + slugify(cardName) + '-' + data.id;
    cardLink =
      "/" +
      (locale !== DEFAULT_LOCALE ? locale + "/" : "") +
      "a/" +
      slugify(cardName) +
      "-" +
      data.id;
    if (data.informationsHotellerie && data.informationsHotellerie.labels) {
      data.informationsHotellerie.labels.forEach((item) => {
        if (getLangValue(item, langKeyTrad).includes('Palace')) {
          cardIsPalace = true;
          return;
        }
      });

    }
  } else if (content) {
    cardThumbnail = imageBySeason;
    cardName =
      data.content?.hero?.length > 0 ? data.content?.hero[0]?.title : data.name;
    cardDescription = content.short_description;
    cardLink = data.full_slug;
  }

  return (
    <CustomLink
      link={cardLink}
      className={`${className} ${stylesCard.card} ${styles.cardActivity} ${data.large ? stylesCard.cardLarge : ""
        } ${styles[variant]} relative`}
    >
      <div
        className={`${stylesCard.cardImage} ${styles.cardImage} ${data.large ? styles.cardImageLarge : ""
          } relative overflow-hidden rounded-xl mb-5 bg-gray-100`}
      >
        {cardThumbnail && cardThumbnail.filename && (
          <Image
            src={cardThumbnail.filename}
            alt={cardThumbnail.alt || "missing alt"}
            fill
            sizes="(max-width: 768px) 80vw, (max-width: 1024px) 60vw, 60vw"
            className="object-cover"
          />
        )}
      </div>
      <Text as="h6" tag="h3" className="mb-2">
        {cardName || ""}
        {cardIsPalace && (
          <span className="text-palaceColor-primary"> - {t("Palace")}</span>
        )}
      </Text>
      <Text
        as="body2"
        className={`${styles.cardDescription} line-clamp-2 lg:line-clamp-3`}
      >
        {cardDescription || ""}
      </Text>
      {cardLink && (cardName || cardDescription) && (
        <ArrowLink className="mt-4 text-buttonColor-primary">
          {t("actions.explore")}
        </ArrowLink>
      )}
    </CustomLink>
  );
}
