export const SEASON_SUMMER = "ete";
export const SEASON_WINTER = "hiver";
export const DEFAULT_LOCALE = "fr";

const secondLangKey = `libelleEn`;
const defaultLangKeyTrad = `libelleFr`;
// Get value with a given key into and object
export const getLangValue = (obj, lang) => {
  if (!obj) return null;
  if (obj[lang]) {
    return obj[lang];
  } else if (obj[secondLangKey]) {
    return obj[secondLangKey];
  } else if (obj[defaultLangKeyTrad]) {
    return obj[defaultLangKeyTrad];
  } else {
    return null;
  }
};

const secondLangKeySimple = "en";
const defaultLangKeyTradSimple = "fr";
export const getLangValueSimple = (obj, lang) => {
  if (!obj) return null;
  if (obj[lang]) {
    return obj[lang];
  } else if (obj[secondLangKeySimple]) {
    return obj[secondLangKeySimple];
  } else if (obj[defaultLangKeyTradSimple]) {
    return obj[defaultLangKeyTradSimple];
  } else {
    return null;
  }
};

export const getLangValueWithoutFallback = (obj, lang) => {
  if (!obj) return null;
  if (obj[lang]) {
    return obj[lang];
  } else if (obj[secondLangKey]) {
    return obj[secondLangKey];
  } else {
    return null;
  }
};

export const getDifficultyLevel = (levelText) => {
  if (!levelText) return null;
  if (levelText.match(/Facile/i)) {
    return 1;
  } else if (levelText.match(/modéré/i)) {
    return 2;
  } else if (levelText.match(/assez difficile/i)) {
    return 3;
  } else if (levelText.match(/très difficile/i)) {
    return 5;
  } else if (levelText.match(/difficile/i)) {
    return 4;
  } else {
    return null;
  }
};

export const getDefaultLocale = (defaultLocale) => {
  return defaultLocale === 'default' ? 'fr' : defaultLocale;
}
