import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Text from "../../Core/Text";
import * as styles from "./IconLinkList.module.scss";

const IconLinkList = ({ blok, className }) => {
  return (
    <div
      className={`${styles.sIconLinkList} s-iconLinkList ${className}`}
      data-id={blok._uid}
      {...storyblokEditable(blok)}
    >
      {blok.title && (
        <Text as="ui2" className="uppercase mb-4">
          {blok.title}
        </Text>
      )}
      <div className="flex gap-x-4">
        {blok &&
          blok.list &&
          blok.list.map((item) => (
            <StoryblokComponent
              blok={item}
              key={item._uid}
              className="hover:text-gray-500"
            />
          ))}
      </div>
    </div>
  );
};

export default IconLinkList;
