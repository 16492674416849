import React from "react";
import Text from "../Text";
import Icon from "@/utils/Icon";

const Checkbox = (props) => {
  const {
    item = "",
    index = 0,
    selectedItems = [],
    handleChange = "",
    type = "",
    className,
    filterSlug,
    ...rest
  } = props;

  const elementIsSelected = !!selectedItems.find(
    (el) => el.id === item.id || el._uid === item.id
  );

  const handleChangeInput = (e) => {
    handleChange(type, item, e.target.checked);
  };

  return (
    <div
      key={`${item}-${type}-${filterSlug}`}
      className={`${elementIsSelected ? "active" : ""} w-full ${className}`}
    >
      <label
        htmlFor={`${filterSlug}${type}${index}`}
        className="flex cursor-pointer items-center select-none hover:text-buttonColor-primary transition-colors"
      >
        <div className="relative">
          <input
            type="checkbox"
            id={`${filterSlug}${type}${index}`}
            className="sr-only"
            // checked={elementIsSelected}
            defaultValue={elementIsSelected}
            onChange={handleChangeInput}
          />
          <div
            className={`box border bg-white block h-5 w-5 transition rounded-sm`}
          >
            {elementIsSelected && (
              <Icon
                icon="checkmark"
                className="absolute left-0 top-0 right-0 bottom-0 m-auto"
                size={10}
              />
            )}
          </div>
        </div>
        <Text as="body2" className="ml-2">
          {item?.libelle || item?.label || item}
        </Text>
      </label>
    </div>
  );
};

export default Checkbox;
