import React, { useState, useEffect } from 'react';
import * as styles from "./Modal.module.scss";

const Modal = ({ isOpen, onClose, children, large }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={`${styles.modal} fixed top-0 left-0 w-full h-full flex items-end lg:items-center justify-center z-50`}>
      <div className={`${styles.modal__overlay} fixed top-0 left-0 w-full h-full bg-black/[.5] z-0`} onClick={onClose} />
      <div className={`${styles.modal__content} ${large ? 'max-w-[900px]' : 'max-w-[675px]'} bg-white rounded-2xl z-1  w-full max-h-[92vh] overflow-auto`}>
        {children}
      </div>
    </div>
  );
};

export default Modal;