import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import React, { useState } from "react";
import Head from "next/head";
import Text from "../../../Core/Text";
import Image from "next/image";
import ImageHeader from "@/public/webcam-bg.png";
import ImageBGBottom from "@/public/relief.png";
import ImageBGTop from "@/public/relief-beige.png";
import CardImg from "@/public/card-img.png";
import Author from "@/public/author.jpg";
import Icon from "@/utils/Icon";
import ArrowLink from "@/common/components/Core/ArrowLink";
import CardActivity from "../../../Card/Activity";
import * as styles from "./SingleBlog.module.scss";
import Paragraph from "@/common/components/Story/Paragraph";
import { useRouter } from "next/router";
import getConfig from "next/config";
import useTranslation from "next-translate/useTranslation";

export default function SingleBlogPage({
  blok,
  settings,
  nestedData,
  dataConfig,
}) {
  const { t } = useTranslation(["blog", "common"]);

  let title =
    blok.hero && blok.hero[1]
      ? blok.hero[1]?.title + ` - ${t("common:courchevel")}`
      : blok.hero && blok.hero[0]
      ? blok.hero[0].title + ` - ${t("common:courchevel")}`
      : t("common:courchevel");
  let description =
    blok.hero && blok.hero[1]
      ? blok.hero[1]?.title + ` - ${t("common:courchevel")}`
      : blok.hero && blok.hero[0]
      ? blok.hero[0].title + ` - ${t("common:courchevel")}`
      : t("common:courchevel");
  let robots = "";
  if (blok?.meta_title) {
    title = blok.meta_title;
  }
  if (blok?.meta_description) {
    description = blok.meta_description;
  }
  if (blok?.hide_from_search) {
    robots = blok.hide_from_search ? "noindex, nofollow" : "index, follow";
  }
  let blokTypes = [];
  let currentBlokGroup = [];

  blok.post_content.forEach((item) => {
    if (item.component === "push_section") {
      if (currentBlokGroup.length > 0) {
        blokTypes.push(currentBlokGroup);
        currentBlokGroup = [];
      }
      blokTypes.push(item);
    } else {
      currentBlokGroup.push(item);
    }
  });

  if (currentBlokGroup.length > 0) {
    blokTypes.push(currentBlokGroup);
  }

  // console.log("------------------");
  // console.log(blokTypes, currentBlokGroup);

  // console.log(blok);

  const { publicRuntimeConfig } = getConfig();
  const siteUrl = publicRuntimeConfig.SITE_URL;
  const router = useRouter();
  const currentUrl = `${siteUrl}${router.asPath}`;

  return (
    <div className={""} {...storyblokEditable(blok)}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        {robots && <meta name="robots" content={robots} />}
      </Head>
      <div className={`${styles.heroContainer} relative`}>
        <div
          className="absolute max-h-1 top-[30%] md:top-[38%] lg-bottom- bottom-[32%] left-0 right-0 z-0 bg-no-repeat bg-background-beigeDark bg-contain"
          style={{
            backgroundImage: `url(${ImageBGBottom.src})`,
            backgroundPosition: "bottom center",
          }}
        >
          <Image
            className={`absolute top-0 left-0 object-contain w-full h-auto -translate-y-[100%] `}
            src={ImageBGTop}
            alt={"missing alt"}
          />
        </div>
        <div className={`${styles.heroSingle}`}>
          <div
            className={`${styles.imageContainer} relative left-0 right-0 h-0 overflow-hidden lg:rounded-xl`}
          >
            <Image
              className={`absolute top-0 left-0 object-cover w-full h-full`}
              src={`http://${blok.Hero[0].Image.substring(2)}`}
              alt={"missing alt"}
              fill
              priority
            />
          </div>
        </div>
        <div className="container relative z-10">
          <Text
            as="h3"
            tag="h1"
            className="lg:text-[48px] lg:leading-[52px] my-6 lg:mt-12 lg:mb-6 max-w-[660px]"
          >
            {blok.Hero[0].title}
          </Text>
          <Text as="body3" className="text-text-h4 mb-1">
            {t("blog:published-date")} 22, Jan. 2023
          </Text>
          <div className="flex flex-col-reverse lg:flex-row mt-8 lg:mt-14 relative">
            <div className={"xl:absolute top-0 left-0 bottom-0 mt-4 lg:mt-0"}>
              <div className={"sticky top-[80px] min-w-[200px]"}>
                <div className={"flex items-center"}>
                  <Image
                    src={blok.auteur[0].image.filename}
                    className={`mr-4 w-12 h-12 rounded-full object-cover`}
                    width={48}
                    height={48}
                    alt="missing alt"
                  />
                  <div>
                    <Text as="body3" className="text-text-h4">
                      {t("blog:author")}
                    </Text>
                    <Text as="body2">{blok.auteur[0].nom}</Text>
                  </div>
                </div>
                <div className={"mt-8 flex gap-6"}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`http://www.facebook.com/sharer.php?u=${currentUrl}`}
                  >
                    <Icon icon="icon-facebook" size="20px" />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`http://twitter.com/share?text=${encodeURIComponent(
                      blok.Hero[0].title
                    )}&url=${currentUrl}`}
                  >
                    <Icon icon="icon-twitter" size="20px" />
                  </a>
                </div>
              </div>
            </div>
            <div className={`${styles.wysiwyg} w-full`}>
              <div className={"lg:max-w-[675px] m-auto"}>
                <div className="flex gap-2">
                  {blok.categories &&
                    blok.categories.map((category, index) => {
                      return (
                        <Text
                          key={`blok-${index}`}
                          as="ui2"
                          className="mb-3 md:mb-5 uppercase text-text-h4 text-track-red"
                        >
                          {category.name}
                        </Text>
                      );
                    })}
                </div>
              </div>
              <div>
                {blokTypes.map((blok, index) => {
                  if (Array.isArray(blok)) {
                    return (
                      <div
                        className={"lg:max-w-[675px] m-auto"}
                        key={`blok-${index}`}
                      >
                        {blok.map((blok, index) => {
                          return (
                            <StoryblokComponent
                              nestedData={nestedData}
                              key={blok._uid}
                              blok={blok}
                            />
                          );
                        })}
                      </div>
                    );
                  } else
                    return (
                      <StoryblokComponent
                        nestedData={nestedData}
                        key={blok._uid}
                        blok={blok}
                      />
                    );
                })}
              </div>
            </div>
          </div>
          <div className={"lg:max-w-[675px] m-auto"}>
            <div
              className={
                "flex justify-between py-5 border-y border-filet mt-14"
              }
            >
              <button>
                <Icon icon="icon-arrow-left-16" size="16px" />
                <Text as="button2" tag="span" className={"pl-1"}>
                  {t("blog:previous-post")}
                </Text>
              </button>
              <button>
                <Text as="button2" tag="span" className={"pr-1"}>
                  {t("blog:next-post")}
                </Text>
                <Icon icon="icon-arrow-right-16" size="16px" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
