import React from "react";
import Image from "next/image";
import * as styles from "./Champion.module.scss";
import * as stylesCard from "../Card.module.scss";
import Text from "../../Core/Text";
import ArrowLink from "../../Core/ArrowLink";
import useTranslation from "next-translate/useTranslation";
import { StoryblokComponent } from "@storyblok/react";

export default function CardChampion(props) {
  const { blok, className } = props;
  const { t } = useTranslation("common");

  return (
    <div
      className={`${className} ${stylesCard.card} ${styles.cardChampion} cardChampion relative group`}
    >
      <div
        className={`${stylesCard.cardImage} ${styles.cardImage} cardImage relative overflow-hidden rounded-xl mb-4 bg-gray-100`}
      >
        {blok.photo?.filename && (
          <Image
            src={blok.photo.filename}
            alt={blok.photo.alt || "missing alt"}
            fill
            sizes="(max-width: 768px) 80vw, (max-width: 1024px) 50vw, 33vw"
            className="object-cover"
          />
        )}

        <div className="absolute bottom-4 left-4 flex gap-x-5 z-10 opacity-0 translate-y-2 group-hover:opacity-100 group-hover:translate-y-0 transition">
          {blok.socials?.map((item) => (
            <StoryblokComponent
              blok={item}
              key={item._uid}
              className="text-white hover:scale-110 transform transition"
            />
          ))}
        </div>
      </div>
      <Text as="body2" className={"text-text-h4 mb-2"}>
        {blok.discipline}
      </Text>
      <Text as="h5" tag="h3" className="mb-2">
        {blok.nom_athlete}
      </Text>
      {blok.cta && blok.cta[0]?.link && (
        <ArrowLink
          tag="link"
          link={blok.link}
          target={blok.cta[0]?.link?.target}
          href={blok.cta[0]?.link?.cached_url || ""}
          className="mt-4 text-buttonColor-primary"
        >
          {t("actions.explore")}
        </ArrowLink>
      )}
    </div>
  );
}
