
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React, { useRef, useEffect, useState } from "react";
import "@/styles/globals.css";
import "@/styles/main.scss";
import BannerImage from "@/common/components/Story/BannerImage";
import Page from "@/common/components/Page";
import localFont from "next/font/local";
import { storyblokInit, apiPlugin } from "@storyblok/react";
import LivecamsBlock from "@/common/components/Story/LivecamsBlock";
import MultListBlock from "@/common/components/Story/MultListBlock";
import CrossContentBlock from "@/common/components/Story/CrossContentBlock";
import AgendaBlock from "@/common/components/Story/AgendaBlock";
import ServicesBlock from "@/common/components/Story/ServicesBlock";
import Navigation from "@/common/components/Navigation";
import HeaderMenu from "@/common/components/Story/HeaderMenu";
import Cta from "@/common/components/Story/Cta";
import ListingPage from "@/common/components/Story/Pages/ListingPage";
import CalendarPage from "@/common/components/Story/CalendarPage";
import CalendarWeekPage from "@/common/components/Story/CalendarWeekPage";
import Introduction from "@/common/components/Story/Introduction";
import Paragraph from "@/common/components/Story/Paragraph";
import InternalActivities from "@/common/components/Story/CrossContentBlock/InternalActivities";
import InternalListing from "@/common/components/Story/CrossContentBlock/InternalListing";
import LinkList from "@/common/components/Story/LinkList";
import FooterNewsletter from "@/common/components/Story/FooterNewsletter";
import IconLinkList from "@/common/components/Story/IconLinkList";
import ActivityPage from "@/common/components/Story/Pages/ActivityPage";
import TableBlock from "@/common/components/Story/TableBlock";
import List from "@/common/components/Story/List";
import Feature from "@/common/components/Story/List/Feature";
import TableHead from "@/common/components/Story/List/TableHead";
import TableRow from "@/common/components/Story/List/TableRow";
import TableCol from "@/common/components/Story/List/TableCol";
import TextCta from "@/common/components/Story/TextCta";
import InfoBar from "@/common/components/Story/InfoBar";
import InfoBarItem from "@/common/components/Story/InfoBar/InfobarItem";
import HomeBlogPage from "@/common/components/Story/Pages/HomeBlogPage";
import SingleBlogPage from "@/common/components/Story/Pages/SingleBlogPage";
import HomeHikingPage from "@/common/components/Story/Pages/HomeHikingPage";
import FaqBlock from "@/common/components/Story/FaqBlock";
import FaqItem from "@/common/components/Story/FaqBlock/FaqItem";
import ModalStack from "@/common/components/DrawerStack";
import Backdrop from "@/common/components/DrawerStack/Backdrop";
import CmsPage from "@/common/components/Story/Pages/CmsPage";
import Custom404 from "@/common/components/Story/Pages/Custom404";
import { AppWrapper } from "@/context/AppContext";
import BlogContent from "@/common/components/Story/BlogContent";
import SectionMediaFull from "@/common/components/SectionMediaFull";
import IframeBlock from "@/common/components/Story/IframeBlock";
import PlansGuidesBlock from "@/common/components/Story/PlansGuidesBlock";
import ChampionsBlock from "@/common/components/Story/ChampionsBlock";
import MapBlock from "@/common/components/Story/MapBlock";
import ForfaitsSkiBlock from "@/common/components/Story/ForfaitsSkiBlock";
import GuideLink from "@/common/components/Card/GuideLink";
import Introduction2 from "@/common/components/Story/Introduction2";
import ColorContrastBlock from "@/common/components/Story/ColorContrastBlock";
import CardChampion from "@/common/components/Card/Champion";
import DrawerPage from "@/common/components/Story/Pages/DrawerPage";
import { Roboto_Condensed } from "next/font/google";
import BlogTips from "@/common/components/Story/BlogTips";
import BlogImage from "@/common/components/Story/BlogImage";
import PushSection from "@/common/components/Story/PushSection";
import CrossContentInnerBlog from "@/common/components/Story/CrossContentInnerBlog";
import InternalBlogArticles from "@/common/components/Story/CrossContentBlock/InternalBlogArticles";
import PodcastsPage from "@/common/components/Story/Pages/PodcastsPage";
import ArrowLink from "@/common/components/Story/ArrowLink";
// import { ReactLenis, useLenis } from "@studio-freight/react-lenis";
const robotoCondensed = Roboto_Condensed({
    variable: "--font-roboto-condensed",
    subsets: ["latin", "cyrillic"],
    weight: ["700"],
    display: "swap"
});
const helveticaNeue = localFont({
    src: [
        {
            path: "../public/fonts/HelveticaNeueLTPro-MdCn.woff2",
            weight: "500",
            style: "normal"
        },
        {
            path: "../public/fonts/HelveticaNeueLTPro-Roman.woff2",
            weight: "400",
            style: "normal"
        },
    ],
    variable: "--font-helvetica"
});
const components = {
    page: Page,
    listingpage: ListingPage,
    calendarpage: CalendarPage,
    calendar_category: CalendarPage,
    weeklycalendarpage: CalendarWeekPage,
    activitypage: ActivityPage,
    homeblog: HomeBlogPage,
    blogpost: SingleBlogPage,
    hiking_homepage: HomeHikingPage,
    cmspage: CmsPage,
    404: Custom404,
    blogcateg: HomeBlogPage,
    drawer_lumiplanpage: DrawerPage,
    page_podcast: PodcastsPage,
    banner_image: BannerImage,
    livecams_block: LivecamsBlock,
    multlist_block: MultListBlock,
    cross_content_block: CrossContentBlock,
    internal_activities: InternalActivities,
    internal_listing: InternalListing,
    agenda_block: AgendaBlock,
    services_block: ServicesBlock,
    header: Navigation,
    header_menu: HeaderMenu,
    CTA: Cta,
    icon_link: Cta,
    introduction: Introduction,
    introduction2_strate: Introduction2,
    paragraph: Paragraph,
    arrow_cta: ArrowLink,
    link_list: LinkList,
    footer_newsletter: FooterNewsletter,
    icon_link_list: IconLinkList,
    table: TableBlock,
    list: List,
    feature: Feature,
    _table_head: TableHead,
    _table_row: TableRow,
    _table_col: TableCol,
    text_cta: TextCta,
    blog_image: BlogImage,
    push_section: PushSection,
    cross_content_inner_blog: CrossContentInnerBlog,
    internal_blog_articles: InternalBlogArticles,
    infobar: InfoBar,
    infobar_item: InfoBarItem,
    faq: FaqBlock,
    faq_item: FaqItem,
    blog_content: BlogContent,
    large_video: SectionMediaFull,
    iframe: IframeBlock,
    plans_guides: PlansGuidesBlock,
    champions: ChampionsBlock,
    map: MapBlock,
    forfaits_ski: ForfaitsSkiBlock,
    guide_link: GuideLink,
    color_contrast_section: ColorContrastBlock,
    champion_card: CardChampion,
    blog_tips: BlogTips
};
storyblokInit({
    accessToken: process.env.storyblokApiToken,
    use: [apiPlugin],
    components
});
function App(props) {
    const { Component, pageProps } = props;
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // Définis ici la largeur de l'écran pour le mode mobile
        };
        window.addEventListener("resize", handleResize);
        handleResize(); // Vérifie l'état initial lors du premier rendu
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    // let lenisOptions = {
    //   easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    //   // duration: 1.2,
    //   lerp: 0.14,
    // };
    return (<>
      <style jsx global>{`
        :root {
          /* ... */
          --font-helvetica: ${helveticaNeue.style.fontFamily};
          --font-roboto-condensed: ${robotoCondensed.style.fontFamily};
        }
      `}</style>
      {isMobile ? (<ModalStack renderBackdrop={Backdrop}>
          <AppWrapper>
            <Component {...pageProps}/>
          </AppWrapper>
        </ModalStack>) : (<ModalStack renderBackdrop={Backdrop}>
          <AppWrapper>
            <Component {...pageProps}/>
          </AppWrapper>
        </ModalStack>)}
    </>);
}

    export default __appWithI18n(App, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  