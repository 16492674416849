import React from "react";
import * as styles from "./Button.module.scss";
import * as textStyles from "../Text/Text.module.scss";
import Link from "next/link";

const Button = (props) => {
  const {
    children,
    tag = "button",
    variant = "default",
    size,
    transform,
    className = "",
    rounded,
    loading,
    linktype,
    target,
    href,
    link,
    ...rest
  } = props;
  let buttonHref = href || "null";

  let ButtonTag = tag;

  let linkTarget = target;

  const linkRedirect = link;

  ButtonTag =
    ButtonTag === "link"
      ? link?.linktype === "url"
        ? "a"
        : Link
      : link?.linktype === "url" || link?.linktype === "asset"
      ? "a"
      : tag;

  if (linkRedirect?.story?.full_slug) {
    ButtonTag = Link;
  }

  if (linktype === "url") {
    linkTarget = "_blank";
  }

  let urlHref = null;

  if (linkRedirect?.url) {
    urlHref = linkRedirect.url;
  }

  if (linkRedirect?.cached_url) {
    urlHref = linkRedirect.cached_url;
  }

  if (linkRedirect === undefined || linkRedirect === null) {
    urlHref = "";
  }

  if (linkRedirect?.story?.full_slug) {
    urlHref = linkRedirect.story.full_slug;
  }

  if (urlHref?.match(/(\/home)$/gm)) {
    urlHref = "/";
  }

  // if (link?.story?.full_slug) {
  //   buttonHref = link.story.full_slug;
  //   buttonTag = "link";
  // } else if (link?.linktype === "url") {
  //   buttonHref = link?.cached_url;
  //   buttonTag = "a";
  //   linkTarget = "_blank";
  // }

  let buttonVariantStyle = ``;
  // rewrite the switch statement to if and else if statements
  if (variant === "outline") {
    buttonVariantStyle =
      "text-text-h1 border border-text-h1 bg-white hover:bg-text-h1 hover:text-white";
  } else if (variant.includes("outline inverted")) {
    buttonVariantStyle =
      "text-white bg-transparent border border-white hover:text-text-h1 hover:bg-white";
  } else if (variant === "phantom") {
    buttonVariantStyle =
      "text-text-h1 bg-greyBg border-white hover:text-white hover:bg-button-h1";
  } else if (variant === "disabled") {
    buttonVariantStyle = "text-text-h1 bg-greyBg opacity-70 cursor-disabled";
  } else {
    buttonVariantStyle =
      "text-white bg-buttonColor-primary border-buttonColor-primary hover:text-text-h1 hover:bg-background-beige hover:border-white";
  }

  if (rounded) {
    buttonVariantStyle += " rounded-full";
  } else {
    buttonVariantStyle += " rounded-md";
  }

  if (buttonHref.match(/(\/home)$/gm)) {
    buttonHref = "/";
  }

  return (
    <ButtonTag
      className={`button border ${styles.button} ${buttonVariantStyle} ${
        variant.includes("squared") ? styles.buttonSquared : ""
      } ${size ? styles[`button${size}`] : ""} ${
        size === "Small" ? textStyles.button3 : ""
      } ${loading ? styles.loading : ""} ${className}`}
      target={
        linkRedirect?.target
          ? linkRedirect?.target
          : linkRedirect?.linktype === "url" && !urlHref?.includes("mailto")
          ? "_blank"
          : linkTarget
          ? linkTarget
          : "_self"
      }
      href={urlHref || "/"}
      {...rest}
    >
      {children}
    </ButtonTag>
  );
};

export default Button;
