import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import * as stylesStory from "../Story.module.scss";
import * as stylesCard from "../../Card/Card.module.scss";
import * as styles from "./PlansGuidesBlock.module.scss";
import Text from "../../Core/Text";
import Link from "next/link";
import { SEASON_WINTER } from "@/utils/global";
import { useEffect, useState } from "react";

const PlansGuidesBlock = ({ blok, nestedData }) => {
  const [imageBySeason, setImageBySeason] = useState(null);

  // console.log('PlansGuides', blok)

  useEffect(() => {
    let seasonMode = SEASON_WINTER

    if (typeof window !== 'undefined') {
      seasonMode = localStorage.getItem('seasonMode')
    }

    const images = blok.cover_image?.length > 0 ? blok.cover_image : [blok.cover_image]
    const image = seasonMode === SEASON_WINTER ? images[0] : images.length > 1 ? images[1] : images[0]

    setImageBySeason(image);
  }, [blok]);

  return (
    <div className={`${stylesStory.column} column PlansGuidesBlock ${styles[blok.template]} relative ${blok.background === 'dark' ? `${stylesStory.columnDarkBg} column--dark` : ''}`} {...storyblokEditable(blok)}>
      {blok.title && <div className="container mb-4">
        <Text as="h3" tag="h2" className="">{blok.title}</Text>
      </div>}

      <div className={`container lg:flex gap-x-3 mb-10 z-2`}>
        <div
          className={`${stylesCard.cardImage} ${styles.titleImage} hidden relative lg:block overflow-hidden rounded-xl mb-5 lg:w-1/2 bg-gray-100 text-white lg:sticky top-0 self-start`}
        >
          {imageBySeason?.filename && (
            <Image
              src={imageBySeason.filename}
              alt={imageBySeason.alt}
              fill
              sizes="(max-width: 768px) 90vw, (max-width: 1024px) 50vw, 33vw"
              className="object-cover"
            />
          )}
        </div>

        <div className="flex flex-col gap-y-6 lg:w-1/2 lg:grid-cols-6 lg:gap-y-0 self-start">
          {blok?.guide_link_list?.map((linkItem, linkItemIndex) => <StoryblokComponent key={linkItem._uid} blok={linkItem} className={linkItemIndex === 0 ? 'lg:border-t' : ''} />)}
        </div>
      </div>
    </div>
  );
};

export default PlansGuidesBlock;
