import { storyblokEditable } from "@storyblok/react";
import Text from "../../Core/Text";
import * as styles from './BlogTips.module.scss';
import { storyblokContentRenderOptions } from "../Paragraph";
import { render } from "storyblok-rich-text-react-renderer";

const BlogTips = ({ blok, className }) => {
  return (
    <div className={`${styles.sBlogTips} s-blog-tips bg-blueLight p-6 pr-10 lg:py-9 lg:px-12 rounded-md mt-12 ${className}`} {...storyblokEditable(blok)}>
      <Text as="h5" tag="p" className="mb-2">
        {blok.title}
      </Text>
      {blok.description && <div>{render(blok.description, storyblokContentRenderOptions)}</div>}
    </div>
  );
};

export default BlogTips;
