import { useEffect, useRef, useState } from "react";
import * as styles from "./PodcastsPage.module.scss";
import Icon from "@/utils/Icon";
import { useRouter } from "next/router";
import { PodcastProvider, ThemeContext, usePodcast } from "./PodcastModule";
import Text from "@/common/components/Core/Text";

var playerStatus = false;

const PodcastItem = (props) => {
  const { locale } = useRouter();
  const { activeItem, setCurrentActiveItem } = usePodcast();
  const { index, blok, dynamicClass, data, onPlay } = props;
  const thumbnail = data.thumbnail.filename;
  const audioRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [onPlaying, setOnPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    if (!activeItem) {
      setPlaying(false);
      return;
    } else if (activeItem._uid !== data._uid) {
      setPlaying(false);
    }
  }, [activeItem]);

  const handlePlayPause = async () => {
    if (audioRef.current) {
      if (playing) {
        setPlaying(false);
        setCurrentActiveItem(false);
      } else {
        setPlaying(true);
        // on audio process set current time
        audioRef.current.addEventListener("timeupdate", () => {
          setCurrentTime(audioRef?.current?.currentTime);
        });
        setCurrentActiveItem(data);
      }
    }
    if (typeof window !== "undefined") {
      localStorage.setItem("currentPlayItem", JSON.stringify(data));
      onPlay();
    }
  };

  useEffect(() => {
    setOnPlaying(playing);
    if (playing) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [playing]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const formatDate = (inputDate) => {
    const publishAt = new Date(inputDate);
    const publishAtValue = publishAt.toLocaleString(locale ?? "en", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return publishAtValue;
  };

  return (
    <div
      className={`${styles.podcastItemContainer} ${dynamicClass} max-w-[327px] h-[392px] sm:max-w-[446px] sm:h-[512px] md:max-w-[446px] md:h-[512px] col-span-1 rounded-xl  bg-cover bg-center bg-no-repeat `}
      style={{ backgroundImage: `url(${thumbnail})` }}
    >
      <audio
        src={data.file.filename}
        controls
        id={data._uid}
        ref={audioRef}
        className={"hidden"}
      ></audio>
      <div
        className={`${
          Object.keys(activeItem).length !== 0 && data._uid !== activeItem._uid
            ? "bg-gradient-to-b from-[#314255c7] to-[#213b64ba] aa"
            : "bg-gradient-to-t from-[#0d2440] z-10 bb"
        } w-full h-full rounded-xl`}
      >
        <div className={`${styles.infos} " flex items-end w-full h-full "`}>
          <div className="w-full">
            <div className="w-full flex justify-center px-6">
              <div
                className={`${styles.controls} flex mb-14 px-8 py-4 items-center justify-center transform translate-x-50 cursor-pointer`}
                onClick={() => {
                  handlePlayPause(data);
                }}
              >
                <div className="flex ">
                  {/* #ec4060 */}
                  {onPlaying ? (
                    <Icon
                      icon="icon-pause"
                      className="mx-3 text-[#ec4060]"
                      size={16}
                    />
                  ) : (
                    <Icon
                      icon="icon-play"
                      size={16}
                      className="text-red-500"
                      disableFill
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={` ${playing ? "" : "mb-9"} flex flex-col px-6 `}>
              <Text
                as="ui2"
                className={`${playing ? "hidden" : ""} mb-5 uppercase `}
              >
                {data.episode}
              </Text>
              {/* <Text as="h5">
                          { data.hote }
                        </Text> */}
              <Text as="h5" className={`${playing ? "mb-5" : "mb-2"} `}>
                {data.title}
              </Text>

              {/* <div className={`h-[45px] ${playing ? " mb-5 " : " mb-4 "} `}>
                          <Text as="body3" className="line-clamp-2">{data.description}</Text>
                        </div> */}
              <div className={`flex flex-row align-middle items-center`}>
                <Text as="body3">{formatDate(data.published_at)}</Text>
                <Icon icon="icon-dots" className="mx-3" size={5} />
                <Text as="body3">{data.length} min</Text>
              </div>
            </div>
            {activeItem._uid == data._uid && playing && (
              <Text as="body3" className="text-center mb-4">
                {" "}
                {formatTime(currentTime)}{" "}
              </Text>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
PodcastItem.contextType = ThemeContext;
export default PodcastItem;
