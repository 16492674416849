import React from "react";
import * as styles from "./ArrowLink.module.scss";
import ArrowRightFull from "@/public/icons/arrow-right-full.svg";
import ArrowRightEmpty from "@/public/icons/arrow-right-empty.svg";
import Text from "../Text";
import Link from "next/link";
import Icon from "@/utils/Icon";
const ArrowLink = (props) => {
  const {
    children,
    tag = "button",
    variant = "default",
    transform,
    className = "",
    target,
    link,
    href,
    linktype,
    noColorChange,
    sizeText = "button1",
    active = false,
    ...rest
  } = props;

  const linkHref = href;
  const linkRedirect = link;

  let ArrowLinkTag = tag;
  if ((linkRedirect && linkRedirect !== "") || linkRedirect?.story) {
    ArrowLinkTag = "link";
  }

  ArrowLinkTag =
    ArrowLinkTag === "link"
      ? target === "_blank" || linkRedirect?.linktype === "url"
        ? "a"
        : Link
      : linkRedirect?.linktype === "url" || linkRedirect?.linktype === "asset"
      ? "a"
      : ArrowLinkTag;

  let linkTarget = target;
  if (linktype === "url") {
    linkTarget = "_blank";
  }

  let urlHref = null;

  if (linkRedirect?.url) {
    urlHref = linkRedirect.url;
  }

  if (linkRedirect?.cached_url) {
    urlHref = linkRedirect.cached_url;
  }

  if (linkRedirect?.story?.full_slug) {
    urlHref = `/${linkRedirect.story.full_slug}`;
  }

  if (linkRedirect === undefined) {
    urlHref = "";
  }

  if (urlHref && urlHref?.match(/(\/home)$/gm)) {
    urlHref = "/";
  }

  return (
    <ArrowLinkTag
      className={`
        arrowLink flex items-center 
        ${noColorChange ? "" : "hover:text-buttonColor-primary"} 
        ${styles.arrowLink} 
        ${styles[`arrowLink${variant}`]} 
        ${active ? styles.arrowLinkActive : ''} 
        ${className}
      `}
      href={urlHref}
      target={
        linkRedirect?.target
          ? linkRedirect?.target
          : linkRedirect?.linktype === "url" && !urlHref?.includes("mailto")
          ? "_blank"
          : "_self"
      }
      rel={target === "_blank" ? "noopener noreferrer" : ""}
      {...rest}
    >
      {variant === "outline" ? (
        <ArrowRightEmpty className={styles.arrowLinkIcon} />
      ) : (
        variant === 'reverse' ? (
          <Icon
            className={`${styles.arrowLinkIconHover} text-button-h4`}
            icon="icon-arrow-up-right-filled-16"
            size={17}
          />
        ) : (
          <Icon
            className={styles.arrowLinkIcon}
            icon="icon-arrow-right-filled-16"
            size={16}
          />
        )
      )}
      <Text tag="p" as={sizeText}>
        {children}
      </Text>

      {variant === "outline" ? (
        <ArrowRightEmpty className={styles.arrowLinkIconHover} />
      ) : (
        variant === 'reverse' ? (
          <Icon
            className={styles.arrowLinkIcon}
            icon="icon-arrow-up-right-16"
            size={17}
          />
        ) : (
          <Icon
            className={styles.arrowLinkIconHover}
            icon="icon-arrow-right-filled-16"
            size={16}
          />
        )
      )}
    </ArrowLinkTag>
  );
};

export default ArrowLink;
