import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import * as styles from './LinkList.module.scss';
import Text from "../../Core/Text";
import { useState } from "react";
import Icon from "@/utils/Icon";

const LinkList = ({ blok, className, index }) => {
  const [openedMenuItem, setOpenedMenuItem] = useState(null);

  const openMenuItem = (itemIndex) => {
    if (openedMenuItem === itemIndex) {
      setOpenedMenuItem(null);
    } else {
      setOpenedMenuItem(itemIndex);
    }
  };

  return (
    <div
      className={`${styles.sLinkList} s-LinkList ${className}`}
      data-id={blok._uid}
      {...storyblokEditable(blok)}
    >
      <button
        onClick={() => openMenuItem(index)}
        aria-haspopup={
          blok.list && blok.list.length > 0
            ? "true"
            : "false"
        }
        aria-expanded={openedMenuItem == index}
        className={`${styles.buttonTrigger} flex items-center justify-between w-full border-t border-white/10 py-4 lg:border-none`}
        key={blok._uid}
        dataindex={index}
      >
        <Text
          as="ui2"
          className="uppercase"
        >
          {blok.title}
        </Text>
        {blok.list &&
          blok.list.length > 0 && (
            <Icon icon="icon-arrow-down-16" size={16} className={`${styles.sLinkListChevron} lg:hidden`} />
          )}
      </button>
      {blok && blok.list &&
        <ul
          key={`fnb${blok._uid}`}
          role="menu"
          aria-label={
            blok.label || blok.title
          }
          className="text-lg overflow-hidden"
        >{blok.list.map((item, itemIndex) => (
          <li role="menuitem"
            className={`${itemIndex > 0 ? "" : ""
              } mb-1`} key={`fnbi${item._uid}`}>
            <StoryblokComponent blok={item} key={item._uid} className="lg:hover:underline" />
          </li>
        ))}</ul>}

      {/* <button
        onClick={() => openMenuItem(index)}
        role="menuitem"
        aria-haspopup={
          nestedBlok.col && nestedBlok.col.length > 0
            ? "true"
            : "false"
        }
        aria-expanded={openedMenuItem == index}
        className="flex items-center justify-between w-full border-b border-white/10 py-5"
        key={nestedBlok._uid}
        dataindex={index}
      >
        <Text as="nav" className="">
          {nestedBlok.label || nestedBlok.title}
        </Text>
        {nestedBlok.col &&
          nestedBlok.col.length > 0 && (
            <IconCaretDown />
          )}
      </button>
      {nestedBlok.col && nestedBlok.col.length > 0 && (
        <ul
          key={`nb${nestedBlok._uid}`}
          role="menu"
          aria-label={
            nestedBlok.label || nestedBlok.title
          }
          className="text-lg overflow-hidden"
        >
          {nestedBlok.col &&
            nestedBlok.col.length > 0 &&
            nestedBlok.col.map(
              (colBlok, colBlokIndex) => {
                if (
                  colBlok &&
                  colBlok.component === "link_list"
                ) {
                  return (
                    <>
                      <li
                        role="none"
                        className={`${colBlokIndex > 0 ? "mt-8" : ""
                          } mb-3`}
                      >
                        <Text
                          as="ui2"
                          className="uppercase text-button-h3"
                        >
                          {colBlok.title}
                        </Text>
                      </li>
                      {colBlok &&
                        colBlok.list &&
                        colBlok.list.map(
                          (
                            colBlokListItem,
                            colBlokListItemIndex
                          ) => (
                            <li
                              key={colBlokListItem._uid}
                              role="none"
                              className={`${colBlokIndex <
                                  nestedBlok.col
                                    .length -
                                  1 &&
                                  colBlokListItemIndex <
                                  colBlok.list.length -
                                  1
                                  ? "mb-1"
                                  : "mb-8"
                                }`}
                            >
                              <Link
                                href={
                                  colBlokListItem.link
                                    ? colBlokListItem
                                      .link.cached_url
                                    : "/"
                                }
                              >
                                {colBlokListItem.label}
                              </Link>
                            </li>
                          )
                        )}
                    </>
                  );
                }
              }
            )}
        </ul> */}
    </div>
  );
};

export default LinkList;
