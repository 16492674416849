import React from "react";
import Image from "next/image";
import * as styles from "./CardAgenda.module.scss";
import * as stylesCard from "../Card.module.scss";
import Text from "../../Core/Text";
import ArrowLink from "../../Core/ArrowLink";
import { slugify, upperCaseFirstLetter } from "@/utils/format";
import { useRouter } from "next/router";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import CustomLink from "../../CustomLink";
import { DEFAULT_LOCALE, getLangValue } from "@/utils/global";
export default function CardAgenda(props) {
  const { t } = useTranslation("common");
  const { locale } = useRouter();
  const { data, className, large } = props;

  const parsedDate = data.eventDate ? new Date(data.eventDate) : null;
  const dayOfMonth = parsedDate ? parsedDate.getDate() : null;

  const isApidae = data.id && data.type;

  let cardThumbnail = data.image && data.image.filename;
  let cardName = data.title;
  let cardDescription = data.short_description;
  let cardLink = data.full_slug;

  if (isApidae) {
    const dataContent = data.content;
    const langKeyTrad = `libelle${upperCaseFirstLetter(locale)}`;
    const defaultLangKeyTrad = `libelle${upperCaseFirstLetter(DEFAULT_LOCALE)}`;
    if (dataContent?.illustrations && dataContent?.illustrations.length) {
      let langTradIllustration = dataContent?.illustrations[0].traductionFichiers.find(
        (item) => item.locale === locale
      );
      if (!langTradIllustration) {
        langTradIllustration = dataContent?.illustrations[0].traductionFichiers.find(
          (item) => item.locale === DEFAULT_LOCALE
        );
      }

      if (langTradIllustration) {
        cardThumbnail = {
          filename: langTradIllustration.url,
          alt: dataContent?.illustrations[0].legende
            ? dataContent?.illustrations[0].legende[langKeyTrad] ||
            dataContent?.illustrations[0].legende[defaultLangKeyTrad]
            : "",
        };
      }
    }
    cardName = dataContent?.nom ? getLangValue(dataContent?.nom, langKeyTrad) : cardName;
    cardDescription =
      getLangValue(dataContent?.presentation.descriptifCourt, langKeyTrad)
      || null;
    // cardLink = (locale !== DEFAULT_LOCALE ? locale + '/' : '') + slugify(data.type) + '/' + slugify(cardName) + '-' + data.id;
    cardLink =
      "/" +
      (locale !== DEFAULT_LOCALE ? locale + "/" : "") +
      "a/" +
      slugify(cardName) +
      "-" +
      data.id;
  }

  return (
    <CustomLink
      link={`${cardLink}`}
      className={`${className} ${stylesCard.card} ${styles.cardAgenda} ${large ? stylesCard.cardLarge : ""
        } relative last:mb-0`}
    >
      <div
        className={`${stylesCard.cardImage} ${styles.cardImage} ${large ? styles.cardImageLarge : ""
          } relative overflow-hidden rounded-xl mb-4 bg-gray-100`}
      >
        {cardThumbnail && cardThumbnail.filename && (
          <Image
            src={cardThumbnail.filename}
            alt={cardThumbnail.alt || "missing alt"}
            fill
            sizes="(max-width: 768px) 80vw, (max-width: 1024px) 50vw, 33vw"
            className="object-cover"
          />
        )}
        {parsedDate && (
          <div
            className={`${styles.eventDate} absolute z-1 top-2 left-2 flex py-3 px-4 rounded-lg bg-white/80`}
          >
            <Text as="h4" className="mr-2">
              {dayOfMonth > 9 ? dayOfMonth : `0${dayOfMonth}`}
            </Text>
            <Text as="body3" className="text-text-h4">
              {parsedDate.toLocaleString("default", {
                month: "short",
                year: "numeric",
              })}
            </Text>
          </div>
        )}
      </div>
      <Text as="h6" tag="h3" className="mb-1">
        {cardName}
      </Text>
      <Text as="body2" className={stylesCard.cardDescription}>
        {cardDescription}
      </Text>
      {cardLink && (
        <ArrowLink className="mt-4 text-buttonColor-primary">
          {t("actions.explore")}
        </ArrowLink>
      )}
    </CustomLink>
  );
}
