import { storyblokEditable } from "@storyblok/react";
import * as stylesStory from "../Story.module.scss";
import * as styles from "./ChampionsBlock.module.scss";
import Text from "../../Core/Text";
import CardChampion from "../../Card/Champion";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";
import ArrowButton from "../../Core/ArrowButton";

const ChampionsBlock = ({ blok }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    active: false,
    breakpoints: { '(min-width: 1024px)': { active: true, slidesToShow: 2.5 } },
    align: 'start',
    // containScroll: 'trimSnaps',
  })
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi],
  )
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi],
  )

  const onSelect = useCallback((emblaApi) => {
    // setSelectedIndex(emblaApi.selectedScrollSnap())
    setPrevBtnEnabled(emblaApi.canScrollPrev())
    setNextBtnEnabled(emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onSelect])

  const championsItems = blok.champion_card;
  if (championsItems?.length > 0) {
    championsItems[0].large = true;
  }

  return (
    <div
      className={`${stylesStory.column} ${styles.sChampionsBlock} column sChampionsBlock`}
      {...storyblokEditable(blok)}
    >
      <div className="container relative z-2 mt-10 mb-4 flex items-end justify-between lg:mb-6">
        <Text as="h3" tag="h2">
          {blok.title}
        </Text>
      </div>

      <div className="relative container">
        <div className="relative">
          <div className={`embla relative mb-12 lg:mb-0 z-2`} ref={emblaRef}>
            <div className={`${styles.championsBlockList} embla__container flex flex-col gap-y-10`}>
              {championsItems?.map((champion, index) => (
                <CardChampion key={champion._uid} blok={champion} className="embla__slide" />
              ))}
            </div>
          </div>

          <div className={`${styles.emblaNavigation} left-0`} onClick={scrollPrev}>
            <ArrowButton disabled={!prevBtnEnabled} direction="left" className={`left-0 ml-10 ${styles.emblaNavigationButton} ${styles.emblaNavigationButtonPrev}`} />
          </div>
          <div className={`${styles.emblaNavigation} right-0`} onClick={scrollNext}>
            <ArrowButton disabled={!nextBtnEnabled} className={`right-0 mr-10 ${styles.emblaNavigationButton} ${styles.emblaNavigationButtonNext}`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChampionsBlock;
