import { useState } from "react";
import { storyblokEditable } from "@storyblok/react";
import * as styles from './FaqItem.module.scss';
import Text from "@/common/components/Core/Text";
import Icon from "@/utils/Icon";

const FaqItem = ({ blok, className }) => {
  const [openedMenuItem, setOpenedMenuItem] = useState(false);

  const openMenuItem = () => {
    if (openedMenuItem) {
      setOpenedMenuItem(false);
    } else {
      setOpenedMenuItem(true);
    }
  };

  return (
    <div {...storyblokEditable(blok)} className={`${styles.faqItem} ${className} border-b border-filet`}>
      <button onClick={openMenuItem} className="flex text-left items-center justify-between w-full py-4 cursor-pointer lg:py-5"
        aria-expanded={openedMenuItem}><Text as="body2" className="pr-4">{blok.question}</Text><div className={styles.faqItemIcon}>
          <Icon icon="icon-arrow-down-16" size={16} />
        </div></button>
      <div className={`${styles.faqItemContent} overflow-hidden`}>
        <Text as="body2">{blok.answer}</Text>
      </div>
    </div>
  );
};

export default FaqItem;
