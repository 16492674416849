export const disableBodyScroll = (document, scrollY = false) => {
  const body = document.querySelector('body')
  const overlay = document.querySelector('.js-overlay');
  const wrapper = document.querySelector('.js-wrapper');

  let scrollTop = scrollY !== false  ? scrollY : window.scrollY

  /* console.log(window.scrollY, 'window.scrollY')
  console.log(scrollY, 'scrollY')
  console.log(scrollTop, 'scrollTop') */

  body.setAttribute('scrollTop', `${scrollTop}`)
  wrapper?.classList.add('is-fixed');
  overlay?.classList.add('overlay--is-open');
  // Scroll the wrapper, rather than setting an offset
  // via `top` or `transform`.
  wrapper?.scroll(0, scrollTop);
}

export const enableBodyScroll = (document) => {
  const body = document.querySelector('body')
  const overlay = document.querySelector('.js-overlay');
  const wrapper = document.querySelector('.js-wrapper');

  let scrollTop = body.getAttribute('scrollTop') ?? 'O'
  wrapper?.classList.remove('is-fixed');
  overlay?.classList.remove('overlay--is-open');
  window.scrollTo(0, Number(scrollTop));
}