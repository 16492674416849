import React, { useState } from "react";
import * as styles from "./BlogNav.module.scss";
import Text from "../Core/Text";
import Link from "next/link";
export default function BlogNav({
  first = "Tout voir",
  categories,
  onCategoryChange,
}) {
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    onCategoryChange(category);
  };

  return (
    <div className="lg:border-b border-filet mb-2 md:mb-8 sticky top-[72px] z-10 bg-white">
      <div className="hidden navContainer overflow-auto lg:flex lg:whitespace-nowrap">
        <span
          className={`${styles.navLink} py-5 pr-3 ${
            selectedCategory === -1 || selectedCategory === ""
              ? styles.active
              : ""
          } cursor-pointer`}
          onClick={() => handleCategoryChange("")}
          key="all"
        >
          {first}
        </span>
        {categories.map((category) => (
          <Link
            href={`/blog/${category.slug}`}
            key={category.id}
            onClick={() => handleCategoryChange(category.slug)}
            className={`px-3 py-5 cursor-pointer ${styles.navLink} ${
              selectedCategory === category.slug ? `${styles.active}` : ""
            }`}
          >
            {category.label}
          </Link>
        ))}
      </div>
      <select
        className={"lg:hidden py-4 outline-0 bg-white"}
        onChange={(event) => handleCategoryChange(event.target.value)}
      >
        <option key="all-select" value="">
          {first}
        </option>
        {categories.map((category) => (
          <option key={`${category.id}-select`} value={category.slug}>
            {category.label}
          </option>
        ))}
      </select>
    </div>
  );
}
