import { storyblokEditable } from "@storyblok/react";
import Text from "../../Core/Text";
import * as styles from "./PushSection.module.scss";
import ArrowLink from "../../Core/ArrowLink";
import ImageHeader from "@/public/webcam-bg.png";
import Image from "next/image";
const PushSection = ({ blok, className }) => {
  const images = blok?.image?.length > 0 ? blok.image[0] : blok.image[0];
  return (
    <div
      className={`${styles.pushSection} lg:aspect-[16/6] px-6 pt-14 pb-8 lg:p-12 text-white rounded-xl overflow-hidden flex items-end relative bg-black mt-14 mb-10 lg:my-20 ${className}`}
      {...storyblokEditable(blok)}
    >
      {images?.filename ? (
        <Image
          src={images.filename}
          alt={images.alt || "missing alt"}
          fill
          sizes="(max-width: 768px) 80vw, (max-width: 1024px) 50vw, 33vw"
          className={`absolute top-0 left-0 w-full h-full object-cover z-0 opacity-70`}
        />
      ) : (
        <Image
          className={`absolute top-0 left-0 w-full h-full object-cover z-0 opacity-70`}
          src={ImageHeader}
        />
      )}
      <div className={"relative"}>
        <Text as="h3" className="mb-2 max-w-[600px]">
          {blok.title}
        </Text>
        <Text as="body1" className="max-w-[600px]">
          {blok.description}
        </Text>
        {blok.cta &&
          blok.cta.map((item, index) => {
            return (
              <ArrowLink
                key={`blok-${index}`}
                className="mt-10"
                link={item.link}
                href={item.link.cache_url}
              >
                {item.label}
              </ArrowLink>
            );
          })}
      </div>
    </div>
  );
};

export default PushSection;
