import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import Image from "next/image";
import * as styles from "./DoubleImageSlider.module.scss";
import Text from "../Core/Text";
import ArrowButton from "../Core/ArrowButton";

const DoubleImageSlider = ({ items }) => {
  const [isSliding, setIsSliding] = useState(false);
  const [direction, setDirection] = useState('forward');
  const [selectedImage, setSelectedImage] = useState(0);
  const [secondImage, setSecondImage] = useState(1);
  const [renderedImages, setRenderedImages] = useState([]);

  const handlers = useSwipeable({
    onSwipedLeft: () => onChangeNextImage(),
    onSwipedRight: () => onChangePrevImage(),
    trackMouse: true,
    swipeDuration: 500,
    preventScrollOnSwipe: true,
  });

  const onChangeNextImage = () => {
    setIsSliding(true)

    const nextIndex = parseInt(selectedImage) + 1
    if (nextIndex < items.length) {
      setSelectedImage(nextIndex)

      if (nextIndex + 1 < items.length) {
        setSecondImage(nextIndex + 1)
      } else {
        setSecondImage(0)
      }

    } else {
      setSelectedImage(0)
      setSecondImage(1)
    }

    setDirection('forward')

    setTimeout(() => {
      setIsSliding(false)
    }, 500)
  }

  const onChangePrevImage = () => {
    setIsSliding(true)

    const prevIndex = parseInt(selectedImage) - 1
    if (prevIndex >= 0) {
      setSelectedImage(prevIndex)

      if (parseInt(selectedImage) < items.length) {
        setSecondImage(parseInt(selectedImage))
      } else {
        setSecondImage(0)
      }
    } else {
      setSelectedImage(items.length - 1)
      setSecondImage(0)
    }

    setDirection('backward')

    setTimeout(() => {
      setIsSliding(false)
    }, 500)
  }

  return (
    <div className={`${styles.doubleImageSlider} w-full mt-4 lg:mt-0`} {...handlers}>
      <div className={`flex lg:grid lg:grid-cols-8 gap-x-3`}>
        <div className={`${styles.firstImage} flex flex-col lg:col-span-5`}>
          <div
            className={`${styles.cardImage} ${styles.cardImage} relative overflow-hidden rounded-xl bg-gray-100`}
          >
            {items.map((item, index) => {
              const isRendered = renderedImages.includes(index)
              if (!isRendered && index === selectedImage) {
                setRenderedImages([...renderedImages, index])
              }
              let isNextImage = false
              let isPrevImage = false
              if (items?.length > 1) {
                isNextImage = index === secondImage
                isPrevImage = selectedImage > 0 ? index === selectedImage - 1 : index === items.length - 1
              }

              return <div className={`${styles.sliderItem} ${direction === 'forward' ? styles.sliderItemForward : styles.sliderItemBackward} ${index === selectedImage ? `${styles.sliderItemActive} opacity-100` : ''} ${isNextImage ? styles.sliderItemNext : ''} ${isPrevImage ? styles.sliderItemPrev : ''} ${isSliding ? styles.isSliding : ''}`} key={`${index}-${item.filename}`}>
                <Image
                  src={item.filename}
                  alt={item.alt}
                  fill
                  sizes="(max-width: 768px) 80vw, (max-width: 1024px) 50vw, 33vw"
                  className={`object-cover ${isRendered ? 'block' : 'hidden'}`}
                />
              </div>
            })}
          </div>
          {items[selectedImage] && <div className="flex flex-col items-end self-end mt-3 lg:mt-4">
            {items[selectedImage].copyright  && <Text as="ui3" className="mb-1">{items[selectedImage].copyright}</Text>}
            {/* <Text as="ui2" className="uppercase text-button-h3">01 janvier 2023</Text> */}
          </div>}
        </div>
        {items?.length > 1 && <div className={`${styles.secondImage} flex flex-col lg:col-span-3`}>
          <div
            className={`${styles.cardImage} ${styles.cardImage} relative overflow-hidden rounded-xl bg-gray-100`}
          >
            {items.map((item, index) => {
              const isRendered = renderedImages.includes(index)
              if (!isRendered && index === secondImage) {
                setRenderedImages([...renderedImages, index])
              }

              const isNextImage = secondImage < items.length - 1 ? index === secondImage + 1 : index === 0
              const isPrevImage = index === selectedImage

              return <div className={`${styles.sliderItem} ${direction === 'forward' ? styles.sliderItemForward : styles.sliderItemBackward} ${index === secondImage ? `${styles.sliderItemActive} opacity-100` : ''} ${isNextImage ? styles.sliderItemNext : ''} ${isPrevImage ? styles.sliderItemPrev : ''} ${isSliding ? styles.isSliding : ''}`} key={`${index}-${item.filename}`}>
                <Image
                  src={item.filename}
                  alt={item.alt}
                  fill
                  sizes="(max-width: 768px) 80vw, (max-width: 1024px) 50vw, 33vw"
                  className={`object-cover ${isRendered ? 'block' : 'hidden'}`}
                />
              </div>
            })}
          </div>

          {items?.length > 2 && <div className="hidden lg:flex items-center self-end mt-8">
            <ArrowButton direction="left" variant="outline" action={onChangePrevImage} />
            <ArrowButton variant="outline" action={onChangeNextImage} />
          </div>}
        </div>}
      </div>

      <div className="lg:hidden flex items-center justify-end mt-12 mr-8">
        <ArrowButton direction="left" variant="outline" action={onChangePrevImage} />
        <ArrowButton variant="outline" action={onChangeNextImage} />
      </div>
    </div>);
};

export default DoubleImageSlider;
