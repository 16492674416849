import React, { useMemo, useState, useContext, createRef } from "react";
import { disableBodyScroll, enableBodyScroll } from "@/utils/scroll";

export interface DrawerStackValue {
  /**
   * Opens a Drawer using the provided component and props
   */
  openDrawer: <T extends StackedDrawerProps, P extends T>(
    component: React.ComponentType<T>,
    props?: Omit<P, keyof StackedDrawerProps>,
    options?: OpenDrawerOptions
  ) => any;

  /**
   * Closes the active Drawer
   */
  closeDrawer: () => void;

  /**
   * Closes the number of Drawers
   */
  closeDrawers: (amount?: number) => void;

  /**
   * Closes all Drawers
   */
  closeAllDrawers: () => void;

  stack: StackedDrawer[];
}

export type OpenDrawerOptions = {
  /**
   * Replaces the active Drawer in the stack
   */
  replace?: boolean;
  /**
   * A ref to the drawer node
   */
  nodeRef?: React.RefObject<any>;

  /**
   * A query selector to the element that should be locked
   * when the drawer is open
   * @default document.body
   */
  targetElement?: string;
};

export interface StackedDrawerProps {
  open?: boolean;
}

export type StackedDrawer = {
  component: React.ComponentType;
  nodeRef: React.RefObject<any>;
  props: any;
};

const DrawerStackContext = React.createContext<DrawerStackValue>({} as any);

export interface DrawerStackProps {
  renderBackdrop?: React.ComponentType<any>;
  renderDrawers?: React.ComponentType<DrawerStackValue>;
  children?: React.ReactNode;
}

export default function DrawerStack(props: DrawerStackProps) {
  const {
    children,
    renderDrawers: DrawersComponent = Drawers,
    renderBackdrop: BackdropComponent,
  } = props;
  const [stack, setStack] = useState<StackedDrawer[]>([]);

  const value = useMemo<DrawerStackValue>(() => {
    function pop(amount = 1) {
      return setStack((prev) => [...prev].slice(0, prev.length - amount));
    }

    function dismissAll() {
      setStack([]);
    }

    function dismiss(amount?: number) {
      if (stack.length === 1) {
        dismissAll();
        enableBodyScroll(document);
      } else {
        pop(amount);
      }
    }

    function disableScroll(targetElementSelector: string) {
      const targetElement = targetElementSelector
        ? document.querySelector(targetElementSelector)
        : document.body;

      //console.log("targetElement", targetElement);
      if (targetElement) {
        disableBodyScroll(document);
      }
    }

    return {
      stack,
      openDrawer: (component, props, options) => {
        setStack((prev) => {
          let newStack = [...prev];

          if (options?.replace) {
            newStack = stack.slice(0, stack.length - 1);
          }

          setTimeout(() => {
            disableScroll(options.targetElement);
          }, 500);

          return [
            ...newStack,
            { component, props, nodeRef: options.nodeRef } as StackedDrawer,
          ];
        });
      },
      closeDrawer: () => dismiss(1),
      closeDrawers: dismissAll,
      closeAllDrawers: dismissAll,
    };
  }, [stack]);

  return (
    <DrawerStackContext.Provider value={value}>
      {children}
      {BackdropComponent && value.stack.length > 0 && <BackdropComponent />}
      <DrawersComponent {...value} />
    </DrawerStackContext.Provider>
  );
}

function Drawers({ stack }: DrawerStackValue) {
  return (
    <div className="parent-stack">
      {stack.map((drawer, index) => {
        return (
          <drawer.component
            key={`drawer${
              drawer.props.drawerKey ? drawer.props.drawerKey + index : index
            }`}
            // open={drawer === stack[stack.length - 1]}
            open={true}
            {...drawer.props}
          />
        );
      })}
    </div>
  );
}

export function useDrawers() {
  return useContext(DrawerStackContext);
}
