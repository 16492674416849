import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
import Head from "next/head";
import dynamic from "next/dynamic";
import Hero from "@/common/components/Hero";
import * as styles from "./FlexiblePage.module.scss";
import * as stylesHero from "@/common/components/Hero/Hero.module.scss";
import useTranslation from "next-translate/useTranslation";
import InfoBar from "../Story/InfoBar";
const DynamicFollowBlock = dynamic(
  () => import("@/common/components/FollowBlock"),
  {
    loading: () => <p>Loading...</p>,
  }
);

const Page = ({ blok, settings, nestedData, dataConfig }) => {
  const sbEditableProps = blok ? storyblokEditable(blok) : {};

  const { t } = useTranslation("common");

  let title =
    blok.hero && blok.hero[1]
      ? blok.hero[1]?.title + ` - ${t("courchevel")}`
      : blok.hero && blok.hero[0]
        ? blok.hero[0].title + ` - ${t("courchevel")}`
        : t("courchevel");
  let description =
    blok.hero && blok.hero[1]
      ? blok.hero[1]?.title + ` - ${t("courchevel")}`
      : blok.hero && blok.hero[0]
        ? blok.hero[0].title + ` - ${t("courchevel")}`
        : t("courchevel");
  let robots = "";
  if (blok?.meta_title) {
    title = blok.meta_title;
  }
  if (blok?.meta_description) {
    description = blok.meta_description;
  }
  if (blok?.hide_from_search) {
    robots = blok.hide_from_search ? "noindex, nofollow" : "index, follow";
  }
  // console.log('Page single', blok, nestedData)

  const faq = blok?.faq && blok.faq[0];

  return (
    <div className={styles.flexiblePage} {...sbEditableProps}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        {robots && <meta name="robots" content={robots} />}
      </Head>
      <Hero content={blok.hero} settings={settings}/>
      <div className={stylesHero.heroPlaceholder}></div>
      {settings?.content?.infobar && settings?.content?.infobar[0] && (
        <InfoBar blok={settings?.content?.infobar[0]} />
      )}

      <div className="content-blocks relative bg-white z-10 pb-8 lg:pb-20">
        {blok?.body?.map((nestedBlok) => {
          if (nestedBlok?.is_hidden) return null;
          if (nestedBlok?.component === "push_section") {
            return <div className="container" key={nestedBlok._uid}>
              <StoryblokComponent
                blok={nestedBlok}
                nestedData={nestedData}
                settings={settings}
              />
            </div>
          }
          return (
            <StoryblokComponent
              blok={nestedBlok}
              key={nestedBlok._uid}
              nestedData={nestedData}
              settings={settings}
            />
          )
        })}
      </div>

      {/* <DynamicFollowBlock /> */}

      {faq && <StoryblokComponent blok={faq} />}
    </div>
  );
};

export default Page;
