import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import Image from "next/image"
import useTranslation from "next-translate/useTranslation";
import * as styles from "./PodcastsPage.module.scss";
import Text from "@/common/components/Core/Text";
import { useRouter } from "next/router";
import { SEASON_WINTER } from "@/utils/global";
import PodcastItem from "./PodcastItem";
import { useSessionStorage } from "@/hooks/useSessionStorage";
let title = null
let hote = null
let description = null

const PodcastContext = createContext();

export const usePodcast = () => {
  const context = useContext(PodcastContext);
  if (!context) {
    throw new Error('usePodcast must be used within a PodcastContext');
  }
  return context;
}
export const PodcastProvider = ({ children }) => {

  const [activeItem, setActiveItem] = useState({});

  const setCurrentActiveItem = (item) => {
    setActiveItem(item);
    window.currentItem = item
  };

  return (
    <PodcastContext.Provider value={{ activeItem, setCurrentActiveItem }}>
      {children}
    </PodcastContext.Provider>
  );
};

const PodcastModule = (props) => {
  const titleRef = useRef(null);
  const hoteRef = useRef(null);
  const descriptionRef = useRef(null);
  const refPodcastScroller = useRef(null);
  const refPodcastScrollerShadowBefore = useRef(null);
  const refPodcastScrollerShadowAfter = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const [scrolledEnd, setScrolledEnd] = useState(false);
  const { blok } = props;
  const [seasonMode, setSeasonMode] = useState(SEASON_WINTER);
  const currentItem = useRef(null)
  const { activeItem } = usePodcast();
  const images =
    blok?.fond?.length > 0 ? blok?.fond : [];
  const image =
    seasonMode === SEASON_WINTER
      ? images[0]
      : images.length > 1
        ? images[1]
        : images[0];


  const sortedData = blok.podcasts.sort((a, b) => new Date(b.published_at) - new Date(a.published_at));
  // console.log("blok.podcasts ", sortedData)  
  const PodcastGrid = ({ podcastItems }) => {
    const chunkedPodcasts = podcastItems.reduce((result, item, index) => {
      const chunkIndex = Math.floor(index / 2);

      if (!result[chunkIndex]) {
        result[chunkIndex] = [];
      }

      result[chunkIndex].push(item);

      return result;
    }, []);

    const handlePlay = () => {
      if (typeof window !== 'undefined') {
        let value = localStorage.getItem('currentPlayItem');
        let parsed = JSON.parse(value)

        title = parsed.title
        description = parsed.description
        hote = parsed.hote
        if (hoteRef.current) {
          // titleRef.current.innerText = title
          hoteRef.current.innerText = hote
          descriptionRef.current.innerText = description
        }
      }
    }

    return (
      <>
        <PodcastProvider>
          {chunkedPodcasts.map((chunk, rowIndex) => (
            <div key={rowIndex} className={`grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1 md:gap-3 ${rowIndex !== 0 ? 'lg:-mt-16 md:mt-6 sm:-mt-16 mt-6 ' : 'sm:mt-0 mt-6 '}`}>
              {chunk.map((item, colIndex) => (
                <PodcastItem onPlay={() => handlePlay()}
                  dynamicClass={`${colIndex % 2 !== 0 ? ' lg:mt-24 md:mt-6 sm:mt-24 mt-6 ' : ' mt-0 '}`}
                  key={colIndex} data={item}
                  index={rowIndex * 2 + colIndex + 1} />
              ))}
            </div>
          ))}
        </PodcastProvider>
      </>
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      if (refPodcastScroller.current) {
        if (refPodcastScroller.current.scrollTop > 0) {
          if (refPodcastScrollerShadowBefore.current) {
            refPodcastScrollerShadowBefore.current.style.transform = `translateY(${refPodcastScroller.current.scrollTop}px)`;

            refPodcastScrollerShadowAfter.current.style.transform = `translateY(${refPodcastScroller.current.scrollTop}px)`;
          }

          setScrolled(true);
        } else {
          setScrolled(false);
        }

        if (refPodcastScroller.current.scrollHeight - refPodcastScroller.current.scrollTop === refPodcastScroller.current.clientHeight) {
          setScrolledEnd(true);
        } else {
          setScrolledEnd(false);
        }
      }
    }
    const podcastScroller = refPodcastScroller.current;
    podcastScroller.addEventListener('scroll', handleScroll);

    return () => {
      const cleanupPodcastScroller = podcastScroller;
      cleanupPodcastScroller.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return <div className={`${styles.lumiplanModule} ${styles.weatherModule} `}>
    <div className={`${styles.drawerBg} absolute top-0 inset-0`}>
      {blok && (
        <Image
          src={image?.filename || "/meteo-desktop-bg.png"}
          alt={image?.alt || "missing alt"}
          fill
          sizes="(max-width: 768px) 80vw, (max-width: 1024px) 70vw, 50vw"
          className="object-cover"
        />
      )}
    </div>
    <div className="container relative">
      <div className="lg:grid grid-cols-12 gap-x-3 mb-20">
        <div className={`${styles.details} pt-8 lg:col-span-4 lg:flex flex-col justify-between`}>
          <div>

            <Text as="ui2" className="uppercase mb-10">
              Podcast
            </Text>
            <Text as="h3" className="mb-6" >
              {blok?.title}
            </Text>
            {blok?.subtitle && <Text as="body1" className="mb-3 lg:mb-3 ">
              {blok?.subtitle}
            </Text>}
            {blok?.subtitle && <Text as="body1" className="lg:mb-10 ">
              {blok?.short_description}
            </Text>}
          </div>
          <div className="mt-5">
            <p as="body3" ref={hoteRef}>{hote && JSON.stringify(hote).replaceAll('"', ' ')}</p>
            <p as="body3" className="sm:opacity-70 opacity-100" ref={descriptionRef}>{description && JSON.stringify(description).replaceAll('"', ' ')}</p>

          </div>
        </div>
        <div ref={refPodcastScroller} className={`${styles.scrollbar} ${scrolled ? styles.scrollbarScrolled : ''} ${scrolledEnd ? styles.scrollbarScrolledEnd : ''} relative lg:col-span-8 h-screen sm:mt-0 mt-8`}>
          <div ref={refPodcastScrollerShadowBefore} className={`${styles.scrollbarShadow} ${styles.scrollbarShadowBefore} scrollbar-shadow--before`}></div>
          <div className="flex flex-col sm:flex-wrap"> {/*overflow-scroll */}
            <PodcastGrid podcastItems={sortedData} />
          </div>
          <div ref={refPodcastScrollerShadowAfter} className={`${styles.scrollbarShadow} ${styles.scrollbarShadowAfter} scrollbar-shadow--after`}></div>
        </div>
      </div>
    </div>
  </div>
}

export default PodcastModule