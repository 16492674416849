import React from "react";
import * as styles from "./IframeBlock.module.scss";
import { storyblokEditable } from "@storyblok/react";

export default function IframeBlock(props) {
  const { blok } = props;

  return (
    <div className={`${styles.iframeBlock} overflow-hidden bg-gray-100`} {...storyblokEditable(blok)}>
      <div className={styles.sectionIframe} dangerouslySetInnerHTML={{ __html: blok.code }}></div>
    </div>
  );
}
