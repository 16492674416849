import { storyblokEditable } from "@storyblok/react";
import * as styles from "./BannerImage.module.scss";
import Text from "../../Core/Text";
import CoreImage from "../../Core/Image";
import Image from "next/image";
import ArrowLink from "../../Core/ArrowLink";

// Définissez la fonction optimizeImage ici
function optimizeImage(image) {
  if (!image.filename) return null;
  let imageSource = image.filename + `/m/1920x0/filters:focal(${image.focus})`;

  if (image.focus) imageSource += `/filters:focal(${image.focus})`;

  return imageSource;
}

const BannerImage = ({ blok, withToggle, title, setActiveIsPresent }) => {
  const optimizedImageSrc = optimizeImage(blok.Image);
  setActiveIsPresent(blok.active_here)
  return (
    <div
      className={`${styles.bannerImage} ${
        withToggle ? styles.bannerImageWithToggle : ""
      } banner-image w-full flex flex-col justify-end text-white`}
      data-id={blok._uid}
      {...storyblokEditable(blok)}
    >
      <div className={`${styles.bannerImageBackground}`}>
        {!blok.isVideo && blok.Image && blok.Image.filename ? (
          <Image
            src={`${blok.Image.filename}/m/2600x1500/${
              blok.Image.focus ? `filters:focal(${blok.Image.focus})` : ""
            }`}
            // src={`${blok.Image.filename}/m/`}
            alt={blok.Image.alt}
            fill
            priority
            quality={80}
          />
        ) : null}
        {blok.isVideo && blok.video && blok.video.filename ? (
          <video
            autoPlay
            loop
            muted
            playsInline
            className="w-full h-full object-cover"
          >
            <source src={blok.video.filename} type="video/mp4" />
          </video>
        ) : null}
      </div>

      <div className={`${styles.bannerImageContent} container mb-6 lg:mb-12`}>
        <Text as="h1" tag="h1" className="w-5/6 lg:w-6/12">
          {title ? title : blok.title}
        </Text>
        {blok.cta && blok.cta[0]?.link && (
          <ArrowLink
            variant="outline"
            tag="link"
            link={blok.link}
            className="mt-6"
          >
            {blok.cta[0]?.label}
          </ArrowLink>
        )}
      </div>
    </div>
  );
};

export default BannerImage;
