import Image from "next/image";
import React, { useState } from "react";
import * as style from "./Image.module.scss";

const storyBlokImageLoader = ({ src, quality, width }) => {
  // Image width and height
  const widthAndHeight = `/${width ?? 0}x0`;

  // Adding quality to the image
  const imageModifications = `filters:quality(${quality ?? 80})`;

  return src + "/m/" + `${widthAndHeight}/${imageModifications}`;
};

// const imageLoader = ({ src, width, quality }) => {
//   return `${src}/m/${width ?? 0}x0/filters:quality(${
//     quality ?? 80
//   })`;
// };

const CoreImage = (props) => {
  const [loaded, setLoaded] = useState(false);
  const {
    className = "",
    src,
    alt,
    fill,
    is_external_url,
    ...rest
  } = props;

  if (!src) {
    return null;
  }
  return (
    <Image
      className={`${style.coreImage} ${
        loaded ? style.loaded : ""
      } ${className}`}
      onLoadingComplete={(img) => setLoaded(true)}
      src={`${src}/m/`}
      alt={alt}
      sizes="(max-width: 340px) 128px, (max-width: 430px) 320px, (max-width: 630px) 630px, (max-width: 768px) 828px, (max-width: 1024px) 1024px, 1024px"
      {...rest}
    />
  );
};

export default CoreImage;
