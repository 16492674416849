import { storyblokEditable } from "@storyblok/react";
import * as stylesStory from "../Story.module.scss";
import Text from "../../Core/Text";
import Button from "../../Core/Button";
import * as styles from "./AgendaBlock.module.scss";
import CardsSlider from "../../CardsSlider";
import { useMemo } from "react";

const AgendaBlock = ({ blok }) => {
  const agendaItems = blok.agenda_items && blok.agenda_items.selections;
  if (agendaItems && agendaItems.length > 0) {
    agendaItems[0].large = true;
  }

  const currentDate = useMemo(() => new Date(), []);

  const filteredItems = useMemo(() => {
    let newItems = agendaItems?.length > 0 ? [...agendaItems] : [];

    if (newItems.length === 0) {
      for (let i = 0; i < agendaItems?.length; i++) {
        const item = agendaItems[i];

        const sortedPeriods = item.ouverture?.periodesOuvertures?.sort(
          (a, b) =>
            Math.abs(new Date(a.dateDebut) - currentDate) -
            Math.abs(new Date(b.dateDebut) - currentDate)
        );

        if (sortedPeriods?.length > 0) {
          const closestPeriod = sortedPeriods[0];

          // const startDate = closestPeriod?.dateDebut
          const endDate = closestPeriod?.dateFin;

          if (endDate) {
            // const startDateObj = new Date(startDate);
            const endDateObj = new Date(endDate);

            if (endDateObj < currentDate) {
              newItems = newItems.splice(i, 1);
            }
          }
        }
      }
    }

    return newItems;
  }, [agendaItems, currentDate]);

  return (
    <div
      className={`${stylesStory.column} column agendaBlock`}
      {...storyblokEditable(blok)}
    >
      <div className="container relative z-2 mt-10 mb-4 flex items-end justify-between lg:mb-6">
        <Text as="h3" tag="h2" className="flex-1">
          {blok.title}
        </Text>

        {blok.cta && blok.cta[0] && blok.cta[0].link && (
          <Button
            link={blok.cta[0].link}
            variant="phantom"
            size="Small"
            className="hidden lg:block mb-1"
            rounded
          >
            {blok.cta[0].label}
          </Button>
        )}
      </div>

      <div className={`container relative mb-0 lg:mb-0 z-2`}>
        <CardsSlider
          slides={
            filteredItems && filteredItems.length > 0 ? filteredItems : []
          }
          slideType="agenda"
        />
      </div>
    </div>
  );
};

export default AgendaBlock;
