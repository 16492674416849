import React, { useEffect, useState } from "react";
import Image from "next/image";
import * as styles from "./CardNav.module.scss";
import * as stylesCard from "../Card.module.scss";
import Text from "../../Core/Text";
import ArrowLink from "../../Core/ArrowLink";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

export default function CardNav(props) {
  const { t } = useTranslation("common");
  const { content, className, loadImage } = props;
  const [imageVisible, setImageVisible] = useState(false);
  const router = useRouter();

  const handleClick = (e) => {
    e.preventDefault();
    // If content.link.linktype === url, open in new tab
    if (content.link?.linktype === "url") {
      window.open(content.link.url, "_blank");
    } else if (content.link?.cached_url) {
      router.push(content.link.cached_url);
    }
  };

  useEffect(() => {
    if (loadImage && !imageVisible) {
      setImageVisible(true);
    }
  }, [loadImage, imageVisible]);

  return (
    <div
      className={`${className} ${stylesCard.card} ${styles.cardNav} relative`}
      onClick={handleClick}
    >
      <div
        className={`${stylesCard.cardImage} ${styles.cardImage} relative overflow-hidden rounded-xl mb-5 bg-gray-100`}
      >
        {content.image && (content.image.filename || content.image[0].filename) && imageVisible && (
          <Image
            src={content.image.filename || content.image[0].filename}
            alt={content.image.alt || content.image[0].alt}
            fill
            sizes="(max-width: 768px) 50vw, 25vw"
            className="object-cover"
          />
        )}
      </div>
      <Text as="ui2" className="uppercase">
        {content.title}
      </Text>
      <Text
        as="body2"
        className={`${styles.cardDescription} text-text-h4 mt-3`}
      >
        {content.description}
      </Text>
      {content.link && content.link.cached_url && (
        <ArrowLink
          className="mt-4 text-buttonColor-primary"
          href={content.link.cached_url}
          link={content.link}
        >
          {t("actions.explore")}
        </ArrowLink>
      )}
    </div>
  );
}
