import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const TableRow = ({ blok }) => {
  return (
    <tr
      data-id={blok._uid}
      {...storyblokEditable(blok)}
      className="bg-white border-b border-filet"
    >
      {blok.body && blok.body.map((blokBodyItem) =>
        <StoryblokComponent blok={blokBodyItem} key={blokBodyItem._uid} />
      )}
    </tr>
  );
};

export default TableRow;
