import React, { useCallback, useRef, useState, useEffect } from "react";
import * as styles from "./SectionMediaFull.module.scss";
import { storyblokEditable } from "@storyblok/react";
import Icon from "@/utils/Icon";
import { useRouter } from "next/router";
import useEmblaCarousel from "embla-carousel-react";
import Image from "next/image";
import Text from "../Core/Text";
import ArrowButton from "../Core/ArrowButton";
import SingleMediaFull from "./SingleMediaFull";
import { DEFAULT_LOCALE } from "@/utils/global";

export default function SectionMediaFull(props) {
  const { blok, images } = props;
  //console.log("media full blok", images);
  const { locale } = useRouter();
  const videoRef = useRef(null);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    active: images?.length > 1,
    breakpoints: { "(min-width: 1024px)": { active: true } },
  });

  const onInit = useCallback((emblaApi) => {
    if (emblaApi) {
      setScrollSnaps(emblaApi.scrollSnapList());
    }
  }, []);

  const onSelect = useCallback((emblaApi) => {
    if (emblaApi) {
      setSelectedIndex(emblaApi.selectedScrollSnap());
    }
  }, []);

  useEffect(() => {
    if (!emblaApi || images.length <= 1) return;
    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);

    emblaApi.on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  const handlePlayVideo = () => {
    if (videoIsPlaying) {
      videoRef.current.pause();
      setVideoIsPlaying(false);
    } else {
      videoRef.current.play();
      setVideoIsPlaying(true);
    }
  };

  const onChangeNextSlide = () => {
    const nextIndex = parseInt(selectedIndex) + 1;
    if (nextIndex < images?.length) {
      setSelectedIndex(nextIndex.toString());
      if (emblaApi) {
        emblaApi.scrollTo(nextIndex);
      }
    }
  };

  const onChangePrevSlide = () => {
    const prevIndex = parseInt(selectedIndex) - 1;
    if (prevIndex >= 0) {
      setSelectedIndex(prevIndex.toString());
      if (emblaApi) {
        emblaApi.scrollTo(prevIndex);
      }
    }
  };

  return (
    <div
      className={`${styles.sectionMediaFull} group ${images?.length > 0 ? "mb-10" : ""
        } ${videoIsPlaying ? styles.sectionMediaFullPlaying : ""
        } relative overflow-hidden bg-gray-100`}
      {...storyblokEditable(blok)}
    >
      {blok?.video?.filename && (
        <div className="w-full h-full cursor-pointer" onClick={handlePlayVideo}>
          <video
            ref={videoRef}
            className="w-full h-full object-cover"
            loop
            muted
            playsInline
          >
            <source src={blok?.video.filename} type="video/mp4" />
          </video>
        </div>
      )}

      {blok?.video?.filename && (
        <button
          type="button"
          className={`${videoIsPlaying ? "opacity-0" : ""
            } absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 py-1 px-8 h-14 flex items-center justify-center rounded-full bg-white text-buttonColor-primary group-hover:scale-110 transition duration-300 ease-in-out`}
          aria-expanded="false"
          onClick={handlePlayVideo}
        >
          <Icon icon="icon-play" size={16} />
        </button>
      )}

      {images?.length > 0 && (
        <div className={`relative h-full ${styles.relativeContainer}`}>
          {images.length > 1 && (
            <div className="absolute bottom-10 inline-flex rounded text-white px-2 left-10 text-lg z-1">
              <Text as="h3">
                {selectedIndex + 1}/{images.length}
              </Text>
            </div>
          )}
          {images.length > 1 && (
            <div className="z-1 absolute right-10 bottom-10 flex items-center self-end">
              <ArrowButton direction="left" action={onChangePrevSlide} />
              <ArrowButton direction="right" action={onChangeNextSlide} />
            </div>
          )}
          {images.length > 1 ? (
            <div className="embla__viewport h-full" ref={emblaRef}>
              <div className="embla__container">
                {images.map((image, index) => {
                  let slideImage = null;
                  if (
                    image.traductionFichiers &&
                    image.traductionFichiers.length > 0
                  ) {
                    slideImage = image.traductionFichiers.find(
                      (traductionFichier) => traductionFichier.locale === locale
                    );

                    if (slideImage === undefined) {
                      slideImage = image.traductionFichiers.find(
                        (traductionFichier) =>
                          traductionFichier.locale === DEFAULT_LOCALE
                      );
                    }
                  }

                  return (
                    // <div className="embla__slide" key={index}>
                    //   <Image
                    //     src={slideImage.url}
                    //     alt="img alt"
                    //     // width={index === 0 ? 600 : 320}
                    //     // height={index === 0 ? 270 : 150}
                    //     fill
                    //     priority={index === 0}
                    //     className="w-full h-full object-cover"
                    //   />
                    // </div>
                    <SingleMediaFull img={slideImage.url} key={`smf-${index}`} inView={index === selectedIndex} />
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="single-image-container h-full">
              <Image
                src={
                  images[0].traductionFichiers[0]?.url ||
                  images[0].traductionFichiers.find(
                    (traductionFichier) =>
                      traductionFichier.locale === DEFAULT_LOCALE
                  ).url
                }
                alt="img alt"
                // width={600}
                // height={270}
                fill
                priority={true}
                className="w-full h-full object-cover"
              />
            </div>
          )}
          Z
        </div>
      )}
    </div>
  );
}
