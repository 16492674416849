import React from "react";
import Image from "next/image";
import * as styles from "./CardHiking.module.scss";

import ImageCalendarCard from "@/public/card-img.png";
import Icon from "@/utils/Icon";
import Text from "../../Core/Text";
import { useRouter } from "next/router";
import { slugify, upperCaseFirstLetter } from "@/utils/format";
import { DEFAULT_LOCALE, getDifficultyLevel, getLangValue } from "@/utils/global";
import Link from "next/link";
import CustomLink from "../../CustomLink";

export default function CardHiking(props) {
  const { data, className, onItemHover } = props;
  const { locale } = useRouter();
  const langKeyTrad = `libelle${upperCaseFirstLetter(locale)}`;

  let cardThumbnail = null;
  if (data.illustrations && data.illustrations.length) {
    let langTradIllustration = data.illustrations[0].traductionFichiers.find(
      (item) => item.locale === locale
    );
    if (!langTradIllustration) {
      langTradIllustration = data.illustrations[0].traductionFichiers.find(
        (item) => item.locale === "en"
      );
    }
    if (!langTradIllustration) {
      langTradIllustration = data.illustrations[0].traductionFichiers.find(
        (item) => item.locale === DEFAULT_LOCALE
      );
    }

    if (langTradIllustration) {
      cardThumbnail = {
        filename: langTradIllustration.url,
        alt: data.illustrations[0]?.nom
          ? getLangValue(data.illustrations[0].nom, langKeyTrad)
          : "",
      };
    }
  }

  const cardName = getLangValue(data.nom, langKeyTrad) || null;
  const cardLink =
    "/" +
    (locale !== DEFAULT_LOCALE ? locale + "/" : "") +
    "a/" +
    slugify(cardName) +
    "-" +
    data.id;
  const typeClientele = data.prestations?.typesClientele
    ? data.prestations?.typesClientele[0]
    : null;
  let difficulty = typeClientele
    ? getLangValue(typeClientele, langKeyTrad)
    : null;
  if (difficulty) {
    // Get the last part of the string after the - (ex: "Niveau orange - assez difficile" => "assez difficile")
    difficulty = difficulty.split("-").pop().trim();
    difficulty = upperCaseFirstLetter(difficulty);
  }

  // Compare the difficulty level (in french) and get a difficulty level from 1 to 5
  const difficultyLevel = getDifficultyLevel(
    getLangValue(typeClientele, "libelleFr")
  );

  const handleMouseEnter = (e) => {
    if (onItemHover) {
      onItemHover({
        lat: data.localisation?.geolocalisation?.geoJson?.coordinates[1],
        lng: data.localisation?.geolocalisation?.geoJson?.coordinates[0],
      });
    }
  };

  const handleMouseLeave = (e) => {
    if (onItemHover) {
      onItemHover(null);
    }
  };

  return (
    <CustomLink
      link={cardLink}
      className={`${className} ${styles.cardHiking} flex flex-col md:flex-row group cursor-pointer lg:hover:bg-greyBg md:border border-filet cursor-pointer rounded-lg overflow-hidden`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`${styles.cardHikingThumbnail} relative md:min-w-[40%] md:max-w-[40%] bg-background-beige rounded-lg overflow-hidden`}
      >
        <Image
          className={`${styles.ratioImage} object-cover w-full h-full`}
          src={
            cardThumbnail?.filename ? cardThumbnail.filename : ImageCalendarCard
          }
          alt={cardThumbnail?.alt || "missing alt"}
          width={260}
          height={168}
        />
      </div>
      <div
        className={"flex flex-col col-span-3 py-4 md:p-5 flex-grow relative"}
      >
        <div>
          {/* {data.informationsEquipement?.activites?.map((activity) => <Text as="ui2" key={`hact${activity.id}`} className="mb-1 md:mb-2 uppercase text-blueBanner">{getLangValue(activity, langKeyTrad)}</Text>)} */}
          {data?.informationsEquipement?.activites &&
            data?.informationsEquipement?.activites.length > 0 && (
              <Text as="ui2" className="mb-1 md:mb-2 uppercase text-blueBanner">
                {getLangValue(
                  data.informationsEquipement?.activites[0],
                  langKeyTrad
                )}
              </Text>
            )}
        </div>
        <Text as="h6" className="mb-1 md:mb-2">
          {cardName}
        </Text>
        <table className="w-full">
          <tbody>
            <tr className="">
              <td className="pr-4">
                <Icon icon="icon-pin-24" className="text-text-h4" size={16} />
                <Text
                  as="body3"
                  className="text-text-h4 truncate inline-block align-middle ml-2"
                >
                  {data?.localisation?.adresse?.commune?.nom}
                </Text>
              </td>
              <td className="pr-4">
                <Icon
                  icon="icon-distance-16"
                  className="text-text-h4"
                  size={16}
                />
                <Text
                  as="body3"
                  className="text-text-h4 truncate inline-block align-middle ml-2 "
                >
                  {data?.informationsEquipement?.itineraire?.distance}km
                </Text>
              </td>
              <td className="">
                <Icon
                  icon={`icon-difficulte-${difficultyLevel !== null
                      ? difficultyLevel > 4
                        ? 4
                        : difficultyLevel
                      : 1
                    }`}
                  className="text-text-h4"
                  size={16}
                />
                {difficultyLevel > 4 && <span>+</span>}
                <Text
                  as="body3"
                  className="text-text-h4 truncate inline-block align-middle ml-2 "
                >
                  {difficulty}
                </Text>
              </td>
            </tr>
            <tr className="">
              <td className="">
                <Icon
                  icon="icon-deni-pos-24"
                  className="text-text-h4"
                  size={16}
                />
                <Text
                  as="body3"
                  className="text-text-h4 truncate inline-block align-middle ml-2 pr-1"
                >
                  {data?.informationsEquipement?.itineraire
                    ?.denivellationPositive ?? "-"}
                  m
                </Text>
              </td>
              <td className="">
                <Icon
                  icon="icon-denivele-neg-24"
                  className="text-text-h4"
                  size={16}
                />
                <Text
                  as="body3"
                  className="text-text-h4 truncate inline-block align-middle ml-2 pr-1"
                >
                  {data?.informationsEquipement?.itineraire
                    ?.denivellationNegative ?? "-"}
                  m
                </Text>
              </td>
              <td className="">
                <Text
                  as="body3"
                  className="text-text-h4 truncate inline-block align-middle ml-2 pr-1"
                >
                  {data?.informationsEquipement?.itineraire?.dureeJournaliere ??
                    "-"}
                  min
                </Text>
              </td>
            </tr>
          </tbody>
        </table>
        {/* <div className="mt-auto flex gap-x-3 lg:w-11/12">
                    {data?.localisation?.adresse?.commune && (
                        <div className="flex flex-row items-center gap-1 col-span-1 mt-1">
                            <Icon icon="icon-pin-24" className="text-text-h4 mr-1" size={16} />
                            <Text as="body3" className="text-text-h4 whitespace-nowrap text-ellipsis overflow-hidden pr-1">{data?.localisation?.adresse?.commune?.nom}</Text>
                        </div>
                    )}
                    {data?.informationsEquipement?.itineraire?.distance && (
                        <div className="flex flex-row items-center gap-1 col-span-1 mt-1">
                            <Icon icon="icon-distance-16" className="text-text-h4 mr-1" size={16} />
                            <Text as="body3" className="text-text-h4 whitespace-nowrap text-ellipsis overflow-hidden pr-1">{data?.informationsEquipement?.itineraire?.distance}km</Text>
                        </div>
                    )}
                    {level && (
                        <div className="flex flex-row items-center gap-1 col-span-1 mt-1">
                            <Icon icon="icon-difficulte-16" className="text-text-h4 mr-1" size={16} />
                            <Text as="body3" className="text-text-h4 whitespace-nowrap text-ellipsis overflow-hidden pr-1">{level}</Text>
                        </div>
                    )}
                </div>
                <div className="flex gap-x-3 lg:w-11/12">
                    <div className="flex flex-row items-center gap-1 col-span-1 mt-1">
                        <Icon icon="icon-deni-pos-24" className="text-text-h4 mr-1" size={16} />
                        <Text as="body3" className="text-text-h4 whitespace-nowrap text-ellipsis overflow-hidden pr-1">{data?.informationsEquipement?.itineraire?.denivellationPositive ?? '-'}m</Text>
                    </div>
                    <div className="flex flex-row items-center gap-1 col-span-1 mt-1">
                        <Icon icon="icon-denivele-neg-24" className="text-text-h4 mr-1" size={16} />
                        <Text as="body3" className="text-text-h4 whitespace-nowrap text-ellipsis overflow-hidden pr-1">{data?.informationsEquipement?.itineraire?.denivellationNegative ?? '-'}m</Text>
                    </div>
                </div> */}
        <div
          className={`${styles.cardHover} transition duration-150 ease-out absolute right-5 top-5 leading-[0] opacity-0`}
        >
          <Icon
            icon="icon-arrow-up-right-filled-16"
            className="text-text-h4 mr-1"
            size={16}
          />
        </div>
      </div>
    </CustomLink>
  );
}
