import { useState } from "react";
import { storyblokEditable } from "@storyblok/react";
import Text from "../../Core/Text";
import Link from "next/link";
import * as styles from "./HeaderMenu.module.scss";
import CardNav from "../../Card/Nav";
import ArrowLink from "../../Core/ArrowLink";
import CustomLink from "../../CustomLink";
const HeaderMenu = ({
  blok,
  dataindex,
  onSetSubmenuOpened,
  currentSubmenuOpened,
  onMouseEnter,
}) => {
  // const [submenuOpened, setSubmenuOpened] = useState(false);

  // const onMouseEnter = (e) => {
  //   const target = e.target;
  //   console.log("target", target);
  //   if (target !== null) {
  //     const section = target.nextSibling;

  //     if (section && section.className.includes("nav-submenu")) {
  //       setSubmenuOpened(true);
  //       onSetSubmenuOpened(dataindex);
  //     } else {
  //       setSubmenuOpened(false);
  //       onSetSubmenuOpened(null);
  //     }
  //   } else if (submenuOpened !== false) {
  //     setSubmenuOpened(false);
  //     onSetSubmenuOpened(null);
  //   }
  // };

  const handleMouseEnter = (e) => {
    if (onMouseEnter) onMouseEnter(e);
  };

  // console.log(blok);

  // const onMouseLeave = (e) => {
  //   const target = e.target;
  //   if (target !== null) {
  //     const section = target.nextSibling;

  //     if (section && section.className.includes("nav-submenu")) {
  //       setSubmenuOpened(false);
  //       onSetSubmenuOpened(null);
  //     }
  //   }
  // };

  const linkListCount = blok.col?.filter(
    (col) => col.component === "link_list"
  )?.length;
  const cardCount = blok.col?.filter((col) => col.component === "card")?.length;

  const span3 = linkListCount + cardCount < 5;

  return (
    <div role="presentation">
      <button
        role="menuitem"
        aria-haspopup="true"
        aria-expanded={currentSubmenuOpened === dataindex}
        onMouseEnter={handleMouseEnter}
        data-index={dataindex}
        className={`${styles.navItemParent} menu-item h-full cursor-pointer px-3 md:hover:bg-buttonColor-primary md:hover:text-white duration-200 truncate`}
      >
        <Text as="nav">{blok.title}</Text>
      </button>
      <div
        className={`${styles.navSubmenu} nav-submenu text-text-h1 py-10`}
        role="menu"
        aria-label="Séjourner"
        // {...storyblokEditable(blok)}
      >
        <div className="container">
          <div className="grid grid-cols-12 gap-x-3">
            {blok.col &&
              blok.col.length > 0 &&
              blok.col.map((colBlok) => {
                if (colBlok) {
                  if (colBlok.component === "link_list") {
                    return (
                      <ul
                        key={colBlok._uid}
                        role="menu"
                        aria-label={colBlok.title}
                        className={`text-lg overflow-hidden ${
                          span3 ? "col-span-3" : "col-span-2"
                        }`}
                      >
                        <li role="none" className="mb-4">
                          <Text as="ui2" className="uppercase">
                            {colBlok.title}
                          </Text>
                        </li>
                        {colBlok &&
                          colBlok.list &&
                          colBlok.list.map((colBlokListItem) => (
                            <li
                              key={colBlokListItem._uid}
                              role="none"
                              className="mb-1"
                            >
                              {colBlokListItem.is_arrow_link ? (
                                <ArrowLink
                                  href={colBlokListItem.link?.cached_url || "/"}
                                  className=""
                                  linktype={colBlokListItem.link?.linktype}
                                  link={colBlokListItem.link}
                                >
                                  <Text tag="span" as="body2">
                                    {colBlokListItem.label}
                                  </Text>
                                </ArrowLink>
                              ) : (
                                <CustomLink
                                  className="md:hover:underline focus:underline"
                                  link={colBlokListItem.link}
                                >
                                  <Text as="body2">
                                    {colBlokListItem.label}
                                  </Text>
                                </CustomLink>
                              )}
                            </li>
                          ))}
                      </ul>
                    );
                  } else if (colBlok.component === "card") {
                    return (
                      <CardNav
                        content={colBlok}
                        key={colBlok._uid}
                        className="col-span-3"
                        loadImage={currentSubmenuOpened === dataindex}
                      />
                    );
                  }
                }
              })}
          </div>
        </div>
        {/* {blok.col.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))} */}
      </div>
    </div>
  );
};
export default HeaderMenu;
