import Button from "@/common/components/Core/Button";
import { useEvents, PER_PAGE } from "@/hooks/useEvents";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import CalendarNav from "../../CalendarNav";
import CardCalendar from "../../Card/Calendar";
import * as styles from "./CalendarBlock.module.scss";
import { DEFAULT_LOCALE, getLangValue } from "@/utils/global";
import { formatDateToUrl, upperCaseFirstLetter } from "@/utils/format";
import Text from "../../Core/Text";
import Icon from "@/utils/Icon";

// Dynamic import to avoid hydration error
const Calendar = dynamic(() => import("react-calendar"), {
  ssr: false,
});

import useTranslation from "next-translate/useTranslation";
import "react-calendar/dist/Calendar.css";

const CalendarBlock = ({ blok, categories, title, type }) => {
  const { t } = useTranslation("common");
  const { defaultLocale, locale } = useRouter();

  const langKeyTrad = `libelle${upperCaseFirstLetter(locale)}`;
  const [urlSlug, setUrlSlug] = useState(null);
  const [departureDate, setDepartureDate] = useState(null);
  const [arrivalDate, setArrivalDate] = useState(new Date());
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isCalendarDisplayed, setIsCalendarDisplayed] = useState(false);
  const [filteredItems, setFilteredItems] = useState(blok.content.items || []);
  const [isDefaultDate, setDefaultDate] = useState(false);
  const [selectRange, setSelectRange] = useState([new Date(), null]);
  const refCalendarBlockContainer = useRef(null);
  const dateNow = new Date();

  const router = useRouter();
  const path = router.asPath.split("?")[0];
  const segments = path.split("/");
  const lastSegment = segments[segments.length - 1];

  const categInfo = categories?.find((categ) => categ.slug === lastSegment);
  const critereArray = categInfo?.criteres.map(
    (critere) => `${critere.type}:${critere.type_key}_${critere.id}`
  );
  const critereString = critereArray?.join("+");

  const critereType = categInfo?.criteres[0]?.type;
  const { start, end } = router.query;

  // Add useEffect to update items when slug changes
  useEffect(() => {
    const updatedItems =
      urlSlug && blok.content.items
        ? blok.content.items.filter((item) => item.slug === urlSlug)
        : blok.content.items;
    setFilteredItems(updatedItems);

    if ("scrollPosition" in sessionStorage) {
      window.scrollTo(0, 800);
      sessionStorage.removeItem("scrollPosition");
    }
  }, [urlSlug, blok.content.items]);

  useEffect(() => {
    const formattedDepartureDate = departureDate
      ? `${departureDate.getFullYear()}-${(departureDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${departureDate
          .getDate()
          .toString()
          .padStart(2, "0")}`
      : null;

    const formattedArrivalDate = `${arrivalDate.getFullYear()}-${(
      arrivalDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${arrivalDate.getDate().toString().padStart(2, "0")}`;

    const url = {
      pathname: `${router.locale}${path}`,
      query: {
        start: formattedArrivalDate,
        // end: formattedDepartureDate,
      },
    };

    if (formattedDepartureDate) {
      url.query.end = formattedDepartureDate;
    }

    if (arrivalDate != new Date() && !isFirstRender) {
      router.push(url, undefined, { shallow: true });
    }
  }, [departureDate, arrivalDate]);

  useEffect(() => {
    setIsFirstRender(false);

    if (
      "scrollRestoration" in history &&
      history.scrollRestoration !== "manual"
    ) {
      history.scrollRestoration = "manual";
    }
  }, []);

  useEffect(() => {
    if (isDefaultDate) {
      setArrivalDate(new Date());
      setDepartureDate(null);
    }
  }, [isDefaultDate]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (refCalendarBlockContainer.current) {
        sessionStorage.setItem(
          "scrollPosition",
          refCalendarBlockContainer.current.getBoundingClientRect().top - 100
        );
      } else {
        sessionStorage.setItem("scrollPosition", window.scrollY.toString());
      }
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events]);

  // useEffect(() => {
  //   const updatedItems = urlSlug
  //     ? blok.content.items.filter((item) => item.slug === urlSlug)
  //     : blok.content.items;

  //   const filteredBySlugAndDate = updatedItems.filter((item) => {
  //     const eventDate = new Date(item.eventDate);
  //     return (
  //       (!departureDate || eventDate >= departureDate) &&
  //       (!arrivalDate || eventDate <= arrivalDate)
  //     );
  //   });

  //   setFilteredItems(filteredBySlugAndDate);
  // }, [urlSlug, blok.content.items, departureDate, arrivalDate]);
  const [page, setPage] = useState(0);

  const onSeeMore = async () => {
    setIsLoading(true);

    const newPage = page + 1;

    try {
      const newPage = page + 1;
      setPage(newPage);

      const newObjects = await fetch(
        `/api/apidae/get-events?start=${
          start ? start : formatDateToUrl(arrivalDate)
        }&end=${end ? end : start ? start : ""}&first=${
          newPage * PER_PAGE
        }&count=${PER_PAGE}&criteretype=${critereType}&critere=${
          critereString ? critereString : ""
        }&locales=${DEFAULT_LOCALE}${locale === "en" ? ",en" : `,en`}${
          locale !== "en" && locale !== DEFAULT_LOCALE ? `,${locale}` : ""
        }`
      );

      const newObjectsJson = await newObjects.json();
      const newObjectsTouristiques = newObjectsJson.objetsTouristiques;

      const items = newObjectsTouristiques?.map((item) => {
        const sortedPeriods = item.ouverture.periodesOuvertures.sort(
          (a, b) =>
            Math.abs(new Date(a.dateDebut) - arrivalDate) -
            Math.abs(new Date(b.dateDebut) - arrivalDate)
        );

        const closestPeriod = sortedPeriods[0];

        return {
          id: item.id,
          slug: "cultures-et-spectacles",
          // tag: item.informationsFeteEtManifestation?.categories[0]?.libelleFr,
          tag:
            item.type === "FETE_ET_MANIFESTATION"
              ? item.informationsFeteEtManifestation &&
                item.informationsFeteEtManifestation.typesManifestation
                ? item.informationsFeteEtManifestation.typesManifestation[0]
                    .libelleFr
                : ""
              : item.informationsActivite.activitesCulturelles
              ? item.informationsActivite.activitesCulturelles[0].libelleFr
              : "",
          image: item?.illustrations
            ? item?.illustrations[0]?.traductionFichiers[0]?.url
            : "",
          title: getLangValue(item?.nom, langKeyTrad),
          location: item?.localisation.adresse.commune.nom,
          startDate: closestPeriod.dateDebut,
          endDate: closestPeriod.dateFin,
          horaireOuverture: closestPeriod.horaireOuverture,
          ouverture: item?.ouverture,
        };
      });

      setFilteredItems([...filteredItems, ...items]);
    } catch (error) {
      console.log("errorOnSeeMore", error);
    }
  };

  const restartCalendarDate = async () => {
    setSelectRange([null, null]);
    setDefaultDate(!isDefaultDate);
    setPage(1);
    const url = {
      pathname: `${router.locale}${path}`,
      query: {},
    };
    router.push(url, undefined, { shallow: true });
    scrollToCalendar();
  };

  let options = { day: "numeric", month: "short", year: "numeric" };

  const formattedArrival = new Date(arrivalDate).toLocaleDateString(
    "en-US",
    options
  );

  const formattedDeparture = new Date(departureDate).toLocaleDateString(
    "en-US",
    options
  );

  const currentCategory = categories.find(
    (category) => category.slug === lastSegment
  );

  let filteredCategories = [];
  if (!currentCategory || segments.length < 3) {
    filteredCategories = categories;
  } else {
    filteredCategories = categories.filter(
      (category) => category.id !== currentCategory.id
    );
  }

  const scrollToCalendar = () => {
    const calendarBlock = document.getElementById("calendarBlockContainer");

    // window.scrollTo({
    //   top: calendarBlock.offsetTop,
    //   behavior: "smooth",
    // });
    // if (calendarBlock) {
    //   lenisContext?.scrollTo(1000);
    // }
  };
  return (
    <div
      ref={refCalendarBlockContainer}
      className="container"
      id="calendarBlockContainer"
    >
      <CalendarNav
        categ={lastSegment}
        isDate={false}
        first={segments.length > 2 ? title : t("calendar:all-events")}
        items={filteredCategories || []}
      />

      <div className="lg:grid grid-cols-12 pb-16 relative  border-t lg:border-none">
        <div
          onClick={() => setIsCalendarDisplayed(!isCalendarDisplayed)}
          className={`${styles.mobileDateDisplay} w-full p-4 bg-background-beigeDark flex gap-4 lg:hidden justify-center cursor-pointer mb-2 mt-2 `}
        >
          <Icon icon="icon-calendar" size={24} />
          <Text as="body2" className="text-center" variant="h3">
            {formattedArrival} {departureDate ? "- " + formattedDeparture : ""}
          </Text>
        </div>
        <div className="lg:grid grid-cols-9 col-span-9 ">
          {filteredItems &&
            filteredItems.map((item, index) => (
              <CardCalendar key={index} className="" data={item} />
            ))}
          {!filteredItems && (
            <div className="text-center">{t("calendar:no-events")}</div>
          )}
        </div>
        <div
          className={`lg:col-span-3  lg:border-l border-filet mb-9 absolute lg:static top-16 w-full bg-white ${
            isCalendarDisplayed
              ? "opacity-100 pointer-events-auto visible"
              : "opacity-0 pointer-events-none invisible"
          } lg:opacity-100 lg:pointer-events-auto lg:visible`}
        >
          <div
            className={`lg:sticky h-32 top-24 w-full lg:pt-28 ${styles.calendarContainer} flex flex-col lg:block justify-center w-full`}
          >
            <Calendar
              allowPartialRange={true}
              selectRange
              onChange={(date) => {
                setArrivalDate(date[0]);
                date.length > 1 ? setDepartureDate(date[1]) : null;
                setDefaultDate(false);
                date[1] != null ? setIsCalendarDisplayed(false) : null;
                scrollToCalendar();
              }}
              formatShortWeekday={(locale, date) =>
                [`D`, `L`, `M`, `M`, `J`, `V`, `S`][date.getDay()]
              }
              minDetail="month"
              next2Label={null}
              prev2Label={null}
              // Tile disabled all the day before day now
              tileDisabled={({ date }) => {
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                return date < yesterday;
              }}
              locale={locale}
              value={[arrivalDate, departureDate]}
              className={`${styles.calendarStyle}`}
            />
            {!!arrivalDate && !!departureDate && (
              <div className="w-full text-center mt-2 pb-4 lg:pb-0">
                <Button
                  className="flex justify-center"
                  variant={"phantom"}
                  onClick={() => restartCalendarDate()}
                >
                  {t("calendar:restart")}
                </Button>
              </div>
            )}
          </div>
        </div>
        {blok.numFound && blok.numFound > PER_PAGE && (
          <div className="w-full text-center mt-14 col-span-9">
            <Button
              // variant={isLoading ? "disabled" : "phantom"}
              variant={"phantom"}
              onClick={() => onSeeMore()}
            >
              {t("actions.see-more")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarBlock;
