import { storyblokEditable } from "@storyblok/react";
import Text from "../../Core/Text";
import * as styles from "./CrossContentInnerBlog.module.scss";
import ArrowLink from "../../Core/ArrowLink";
import ImageHeader from "@/public/webcam-bg.png";
import Image from "next/image";
import CardActivity from "../../Card/Activity";
const CrossContentInnerBlog = ({ blok, className, nestedData }) => {
  const { source } = blok;
  const listItems = source || [];

  let listingItems = listItems;
  if (nestedData) {
    for (let i = 0; i < listItems.length; i++) {
      const listItem = listItems[i];
      //console.log("baba", listItem);
      const nestedDataItem = nestedData.find(
        (nestedDataItem) => nestedDataItem.uuid === listItem
      );
      if (nestedDataItem) {
        listingItems[i] = nestedDataItem;
      }
    }
  }

  if (nestedData)
    return (
      <div
        className={`${styles.crossContentInnerBlog} ${className} mt-14 lg:mt-20 mb-14`}
        {...storyblokEditable(blok)}
      >
        <Text as="h5" tag="div" className="mb-4">
          {blok.title}
        </Text>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 lg:gap-4">
          {listingItems &&
            listingItems.map((item, index) => {
              return <CardActivity key={`${index}-card`} data={item} />;
            })}
        </div>
      </div>
    );
};

export default CrossContentInnerBlog;
