import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import Text from "../../Core/Text";
import ArrowLink from "../../Core/ArrowLink";
import { useEffect, useRef, useState } from "react";
import CardStay from "../../Card/Stay";
import CardActivity from "../../Card/Activity";
import * as styles from "./MultiListBlock.module.scss";
import DoubleImageSlider from "../../DoubleImageSlider";
import darkBgPattern from "@/public/pattern-smaller.png";
import reliefShape from "@/public/relief.png";
import reliefShapeBeige from "@/public/relief-beige.png";
import { SEASON_SUMMER, SEASON_WINTER } from "@/utils/global";

// listType = 'card_list' | 'image_list'

const MultListBlock = ({ blok }) => {
  const [listType, setListType] = useState("");
  const [selectedSection, setSelectedSection] = useState(0);
  const [selectedListItems, setSelectedListItems] = useState([]);
  const [selectedListItemsSummer, setSelectedListItemsSummer] = useState([]);
  const multiListBlockRef = useRef(null);

  useEffect(() => {
    let seasonMode = SEASON_WINTER;

    if (typeof window !== "undefined") {
      seasonMode = localStorage.getItem("seasonMode");
    }

    const cListType = blok.list[0].component;

    setListType(blok.list[0]?.component);

    if (cListType === "card_list" && blok.list[0]?.list) {
      setSelectedListItems(blok.list[0].list);
    } else if (cListType === "image_list" && blok.list[0].images) {
      let listItems = [];
      for (let i = 0; i < blok.list[0].images.length; i++) {
        const image = blok.list[0].images[i];
        if (seasonMode === SEASON_SUMMER && blok.list[0].imagesete) {
          const summerImage = blok.list[0].imagesete[i];
          if (summerImage) {
            listItems.push(summerImage);
          } else {
            listItems.push(image);
          }
        } else {
          listItems.push(image);
        }
      }
      setSelectedListItems(listItems);
    }
  }, [blok.list]);

  const changeListItems = (listIndex) => () => {
    let seasonMode = SEASON_WINTER;

    if (typeof window !== "undefined") {
      seasonMode = localStorage.getItem("seasonMode");
    }
    setSelectedSection(listIndex);
    // If the listType is card_list, then we need to set the list items to the list property of the list item
    if (listType === "card_list") {
      setSelectedListItems(blok.list[listIndex].list);
    } else if (listType === "image_list") {
      let listItems = [];
      if (seasonMode === SEASON_SUMMER && blok.list[listIndex].imagesete) {
        for (let i = 0; i < blok.list[listIndex].images.length; i++) {
          const summerImage = blok.list[listIndex].imagesete[i];
          listItems.push(summerImage);
        }
      } else {
        for (let i = 0; i < blok.list[listIndex].images.length; i++) {
          const image = blok.list[listIndex].images[i];
          listItems.push(image);
        }
      }
      setSelectedListItems(listItems);
    }

    let scrollTop = window.scrollY || document.documentElement.scrollTop,
      elementOffset = multiListBlockRef.current.getBoundingClientRect().top;
    window.scrollTo({
      top: scrollTop + elementOffset - 72,
      behavior: "smooth",
    });
  };

  const backgroundDark = blok.background === "dark";

  return (
    <div
      ref={multiListBlockRef}
      className={`${styles.multiListBlock} column multListBlock relative ${
        backgroundDark ? `${styles.backgroundDark} column--dark` : ""
      } ${listType === "image_list" ? styles.multiListBlockImages : ""}`}
      {...storyblokEditable(blok)}
    >
      <div
        className={`${
          styles.multiListBlockContainer
        } container relative pt-14 pb-12 ${
          backgroundDark ? "lg:pb-0" : "lg:pb-20"
        } lg:grid grid-cols-12 gap-3 z-2`}
      >
        <div
          className={`${styles.stickyAside} mb-3 lg:mb-0 lg:pr-0 ${
            listType === "card_list" ? "lg:col-span-5" : "lg:col-span-4"
          } lg:sticky self-start`}
        >
          <Text as="h3" tag="h2" className="w-4/6 lg:w-2/3 mb-2 lg:mb-6">
            {blok.title}
          </Text>

          <Text
            as="body1"
            className={`${
              backgroundDark ? "" : "text-text-h4"
            } pr-8 lg:pr-0 lg:w-2/3`}
          >
            {blok.subtitle}
          </Text>

          {blok.cta && blok.cta[0] && blok.cta[0].link && (
            <ArrowLink
              href={blok.cta[0].link.cached_url}
              link={blok.cta[0].link}
              className="text-buttonColor-primary mt-5"
            >
              {blok.cta[0].label}
            </ArrowLink>
          )}

          <ul
            className={`${styles.multiListBlockSwitcher} mb-5 mt-8 lg:mt-20 pb-5 `}
          >
            {blok.list?.length > 1 &&
              blok.list?.map((listItem, index) => (
                <li
                  key={`slm${listItem._uid}-${index}`}
                  className="mb-3 last-of-type:mb-0"
                >
                  <ArrowLink
                    className={
                      selectedSection === index && "text-buttonColor-primary"
                    }
                    variant={selectedSection === index ? "default" : "outline"}
                    onClick={changeListItems(index)}
                    key={`al${listItem._id}`}
                  >
                    {listItem.title}
                  </ArrowLink>
                </li>
              ))}
          </ul>
        </div>

        <div
          className={`mobile-overflow-container  flex overflow-x-scroll pb-10 hide-scroll-bar lg:overflow-x-hidden lg:pb-0 ${
            listType === "card_list" ? "lg:col-span-7" : "lg:col-span-8"
          }`}
        >
          {listType === "card_list" && (
            <div className="flex flex-nowrap lg:grid grid-cols-7 gap-x-3 lg:w-full">
              {selectedListItems.map((listItem, listItemIndex) => {
                return listItem.component == "card" ? (
                  <CardStay
                    key={`${listItem._uid || listItem._id}-${listItemIndex}`}
                    content={listItem}
                    className={`${
                      listItemIndex % 2 === 0
                        ? listItemIndex === 0
                          ? "w-10/12 lg:col-span-4 lg:mt-20"
                          : "lg:col-span-4 lg:mt-14"
                        : "lg:col-span-3 lg:mb-20"
                    }`}
                    variant={listItemIndex % 2 === 0 ? "large" : "small"}
                  />
                ) : (
                  <CardActivity data={{}} />
                );
              })}
            </div>
          )}
          {listType === "image_list" && (
            <DoubleImageSlider
              items={selectedListItems}
              key={blok.list[selectedSection]._uid}
            />
          )}
        </div>
      </div>
      {blok.background === "dark" ? (
        <>
          <div
            className={styles.darkBgPattern}
            style={{
              backgroundImage: `url(${darkBgPattern.src})`,
            }}
          />
          <div className={`${styles.darBgRelief} bottom-0 w-full`}>
            <Image src={reliefShape} alt="" />
          </div>
        </>
      ) : (
        <>
          <div
            className={`${styles.beigeRelief} absolute hidden lg:block bottom-0 w-full`}
          >
            <Image
              src={reliefShapeBeige}
              alt=""
              className="w-full"
              width={800}
              height={200}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default MultListBlock;
