import { storyblokEditable } from "@storyblok/react";
import * as stylesCrossContentBlock from "../CrossContentBlock.module.scss";
import CardActivity from "@/common/components/Card/Activity";
import CardPush from "@/common/components/Card/Push";

const InternalActivities = ({ blok, nestedData, template, className }) => {
  // console.log('internal activities', blok, template);
  const { items } = blok
  const listItems = items || []
  let listingItems = listItems
  if (nestedData) {
    for (let i = 0; i < listItems.length; i++) {
      const listItem = listItems[i]
      if (typeof listItem === 'object') {
        listingItems[i] = listItem
      } else {
        const nestedDataItem = nestedData.find(nestedDataItem => nestedDataItem.uuid === listItem)

        if (nestedDataItem) {
          listingItems[i] = nestedDataItem
        }
      }
    }
  }

  let listingContent = (
    <div
      className={`internalActivities mobile-overflow-container flex ${template === 'grid' ? 'flex-col gap-y-10' : 'gap-x-3'} flex-nowrap lg:grid grid-cols-12 lg:gap-x-3 lg:gap-y-14 ${className}`}
      {...storyblokEditable(blok)}
    >
      {/* <div className={`flex flex-nowrap gap-x-3`}>
      </div> */}
      {listingItems.map((item, index) => (
        <CardActivity
          key={`ia${item}-${index}`}
          data={item}
          className={`${stylesCrossContentBlock.crossContentBlockCard} lg:col-span-4`}
        />
      ))}
    </div>
  );

  if (template === 'slider' || template === 'blocks') {
    listingContent = (
      <div
        className={`internalActivities mobile-overflow-container flex overflow-x-scroll hide-scroll-bar lg:overflow-x-hidden ${template === 'blocks' ? 'lg:col-span-7' : ''}`}
      >
        <div className={`flex flex-nowrap gap-x-3 lg:grid ${template === 'blocks' ? 'lg:grid-cols-7' : 'lg:grid-cols-12'} lg:w-full lg:gap-y-14`}>
          {listingItems.map((item, index) => (
            <CardActivity
              key={`iacp${item}-${index}`}
              data={item}
              className={`${stylesCrossContentBlock.crossContentBlockCard} ${template === 'blocks' ? index % 2 ? 'lg:col-span-3' : 'lg:col-span-4' : 'lg:col-span-4'}`}
            />
          ))}
        </div>
      </div>
    );
  }

  if (template === 'grid') {
    listingContent = <>
      <div
        className={`internalActivities flex flex-col lg:grid gap-x-3 grid-cols-12 gap-y-10 lg:gap-y-20`}
      >
        {listingItems.slice(0, 3).map((item, index) => (
          <CardActivity
            key={`gdf-${item.uuid}`}
            data={item}
            className={`${stylesCrossContentBlock.crossContentBlockCard} col-span-4`}
          />
        ))}
      </div>
      {listingItems.slice(3).length > 0 && (
        <div
          className={`${stylesCrossContentBlock.crossContentSliderWrapper} flex overflow-x-scroll mt-10 lg:mt-16 lg:overflow-hidden`}
        >
          <div
            className={`flex flex-nowrap lg:w-full lg:grid gap-x-3 grid-cols-12`}
          >
            {listingItems.slice(3).map((item, index) => (
              <CardActivity
                key={`gd-${item.uuid}`}
                data={item}
                className={`${stylesCrossContentBlock.crossContentBlockCard} col-span-3`}
              />
            ))}
          </div>
        </div>
      )}
    </>
  }

  if (template === "4_cards") {
    listingContent = (
      <div className="container internalActivities">
        <div
          className={`mobile-overflow-container flex overflow-x-scroll hide-scroll-bar lg:overflow-x-hidden`}
        >
          <div className="flex flex-nowrap gap-x-3 lg:grid lg:grid-cols-12 lg:w-full">
            {listingItems.map((item, index) => (
              <CardPush
                key={`iacp${item}-${index}`}
                data={item}
                className={`${stylesCrossContentBlock.crossContentBlockCard} col-span-3`}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return listingContent;
};

export default InternalActivities;
