import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Text from "../../Core/Text";
import * as stylesStory from '../Story.module.scss';
import * as styles from './TableBlock.module.scss';

const TableBlock = ({ blok }) => {
  return (
    <div
      className={`${stylesStory.column} ${styles.sTableBlock} s-tableBlock w-full`}
      data-id={blok._uid}
      {...storyblokEditable(blok)}
    >
      <div className="flex flex-col lg:gap-x-3">
        <Text as="h5" className="lg:max-w-lg mb-2">{blok.title}</Text>
        <div className="border-t border-filet">
          {blok.entries && blok.entries.map((bodyItem) => <StoryblokComponent blok={bodyItem} key={bodyItem._uid} />)}
        </div>
      </div>
    </div>
  );
};

export default TableBlock;
