import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Text from "../../Core/Text";
import * as styles from './List.module.scss';

const List = ({ blok }) => {
  return (
    <div
      className={`${styles.sList} s-list w-full lg:grid lg:grid-cols-12 lg:gap-x-3`}
      data-id={blok._uid}
      {...storyblokEditable(blok)}
    >
      <div className="lg:col-span-3 py-5 lg:py-6">
        <Text as="ui2" className="uppercase lg:max-w-lg">{blok.titre}</Text>
      </div>

      <div className="pb-6 lg:pt-6 border-b border-filet lg:col-span-9">
        {!!blok.table && <table className="border border-filet w-full text-left mb-4">
          <thead className="text-xs text-gray-700 bg-gray-50">
            <tr>
              {blok.table.thead && blok.table.thead.map((theadItem) =>
                <StoryblokComponent blok={theadItem} key={theadItem._uid} />)}
            </tr>
          </thead>
          <tbody>
            {blok.table.tbody && blok.table.tbody.map((tbodyItem) => <StoryblokComponent blok={tbodyItem} key={tbodyItem._uid} />)}
          </tbody>
        </table>}

        {blok.valeurs && blok.valeurs.map((bodyItem) => <StoryblokComponent blok={bodyItem} key={bodyItem._uid} />)}
      </div>
    </div>
  );
};

export default List;
