import React from "react";
import { storyblokEditable } from "@storyblok/react";
import * as stylesStory from "../Story.module.scss";
import * as styles from "./ForfaitsSkiBlock.module.scss";
import Text from "../../Core/Text";
import Button from "../../Core/Button";
import useTranslation from "next-translate/useTranslation";
import Icon from "@/utils/Icon";
import { storyblokContentRenderOptions } from "../Paragraph";
import { render } from "storyblok-rich-text-react-renderer";

export default function ForfaitsSkiBlock(props) {
  const { blok } = props;
  const { t } = useTranslation("common");

  // console.log("ForfaitsSkiBlock", blok);

  return (
    <div
      className={`${stylesStory.column} ${styles.sForfaitsSkiBlock} column forfaitsSkiBlock`}
      {...storyblokEditable(blok)}
    >
      <div className="container relative z-2 mt-10 mb-4 flex items-end justify-between lg:mb-6">
        <Text as="h3" tag="h2">
          {blok.title}
        </Text>
      </div>

      <div className={`container relative`}>
        <div className="grid lg:grid-cols-12 gap-x-3 gap-y-4">
          {blok.forfait?.map((forfait) => (
            <div
              key={forfait._uid}
              className="flex flex-col py-10 px-6 lg:px-8 lg:py-14 rounded-lg bg-background-beigeDark items-center lg:col-span-4 text-center"
            >
              <Text
                as="ui2"
                className="text-buttonColor-primary uppercase mb-8"
              >
                {forfait.label}
              </Text>
              <Text as="h4" className="mb-4">
                {forfait.titre}
              </Text>
              <Text
                as="body2"
                tag="div"
                className="mb-8 lg:mb-10 text-text-h4 max-w-sm mx-auto"
              >
                {forfait.description
                  ? render(forfait.description, storyblokContentRenderOptions)
                  : ""}
              </Text>

              {forfait.checklist.map((checklist) => (
                <ul key={checklist._uid} className="text-left mb-10 max-w-xs">
                  {checklist.check_item?.map((checklistItem) => (
                    <li key={checklistItem._uid} className="flex mb-4">
                      <Icon
                        icon="icon-check-filled-16"
                        size={16}
                        className="text-buttonColor-primary mr-4 mt-1"
                      />
                      <Text as="body2" className="flex-1">
                        {checklistItem.text}
                      </Text>
                    </li>
                  ))}
                </ul>
              ))}

              <div className="mt-auto">
                <Text as="h4" className="">
                  {forfait.prix}
                </Text>
                <Text as="body3" className="text-text-h4 mt-1">
                  {forfait.label_price}
                </Text>

                {forfait.cta && forfait.cta[0]?.link && (
                  <Button link={forfait.cta[0].link} className="mt-8">
                    {forfait.cta[0]?.label || t("actions.book")}
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
