import React, { useEffect, useState } from "react";
import * as styles from "./Hero.module.scss";
import { StoryblokComponent } from "@storyblok/react";
import Text from "../Core/Text";
import { SEASON_WINTER } from "@/utils/global";
import useTranslation from "next-translate/useTranslation";
import Icon from "@/utils/Icon";
import { getSkyStatus } from "@/utils/weather";
import { upperCaseFirstLetter } from "@/utils/format";
import { useRouter } from "next/router";

export default function Hero(props) {
  const {
    title,
    content,
    alternateContent,
    isPresent,
    onChangeIsPresent,
    weatherNow,
    settings,
  } = props;
  const { t } = useTranslation();
  const { locale } = useRouter();
  const [contentBySeason, setContentBySeason] = useState({});
  const [seasonMode, setSeasonMode] = useState(null);
  const [activeIsPresent, setActiveIsPresent] = useState(true);

  useEffect(() => {
    let sMode = settings.content.saison;
    const image = content
      ? sMode === SEASON_WINTER
        ? content[0]
        : content?.length > 1
        ? content[1]
        : content[0]
      : null;
    setSeasonMode(sMode);
    setContentBySeason(image);
  }, [content]);

  const todaysDate = new Date();
  const todaysDayOfMonth = todaysDate.toLocaleString(locale ?? "en", {
    day: "numeric",
  });
  const todaysDayOfWeek = todaysDate.toLocaleString(locale ?? "en", {
    weekday: "long",
  });
  const todaysMonthAndYear = todaysDate.toLocaleString(locale ?? "en", {
    month: "long",
    year: "numeric",
  });

  let blokContent = null;
  let componentTitle = null;
  if (seasonMode) {
    if (isPresent && alternateContent && alternateContent[0]) {
      blokContent = alternateContent[0];
    } else if (alternateContent && alternateContent[0]) {
      blokContent = contentBySeason;
    } else {
      blokContent = contentBySeason;
    }

    componentTitle = title ?? content[1]?.title ?? content[0]?.title;
    if (seasonMode === SEASON_WINTER && content[0]?.title) {
      componentTitle = content[0]?.title;
    } else if (seasonMode !== SEASON_WINTER && content[1]?.title) {
      componentTitle = content[1]?.title;
    }
  }

  return (
    <div className={`${styles.hero} overflow-hidden`}>
      {blokContent?.component ? (
        isPresent && alternateContent && alternateContent[0] ? (
          <StoryblokComponent
            blok={alternateContent[0]}
            key={alternateContent[0]._uid}
            withToggle={!!alternateContent}
            title={componentTitle}
            setActiveIsPresent={setActiveIsPresent}
          />
        ) : alternateContent && alternateContent[0] ? (
          contentBySeason && seasonMode &&(
            <StoryblokComponent
              blok={contentBySeason}
              key={contentBySeason?._uid}
              withToggle={!!alternateContent}
              title={componentTitle}
              setActiveIsPresent={setActiveIsPresent}
            />
          )
        ) : (
          <StoryblokComponent
            blok={contentBySeason}
            key={contentBySeason?._uid}
            withToggle={!!alternateContent}
            title={componentTitle}
            setActiveIsPresent={setActiveIsPresent}
          />
        )
      ) : (
        <div className={styles.staticTitle}>
          <div
            className={`${styles.bannerImageContent} container mb-6 lg:mb-12 text-white`}
          >
            <Text as="h1" tag="h1" className="w-5/6 lg:w-6/12">
              {title ? title : content[1]?.title ?? content[0]?.title}
            </Text>
          </div>
        </div>
      )}

      {((alternateContent && alternateContent[0]) || weatherNow) && (
        <div
          className={`${styles.staticContent} absolute bottom-0 container mb-6 lg:mb-14 z-3`}
        >
          {alternateContent && alternateContent[0] && activeIsPresent &&(
            <div className={`${styles.bannerToggle} left-0`}>
              <label
                htmlFor="toggleIsPresent"
                className={`${styles.positionToggle} flex cursor-pointer text-white select-none items-center mt-8`}
              >
                <div className="relative mr-4">
                  <input
                    type="checkbox"
                    id="toggleIsPresent"
                    className="sr-only"
                    onChange={onChangeIsPresent}
                    defaultChecked={isPresent}
                  />
                  <div
                    className={`${styles.box} box bg-white block h-6 w-10 rounded-full transition`}
                  ></div>
                  <div
                    className={`${styles.dot} dot absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-buttonColor-primary transition`}
                  ></div>
                </div>

                <Text as="ui2" className="uppercase">
                  {t("home:hero.in-courchevel")}
                </Text>
              </label>
            </div>
          )}
          <div className="hidden lg:inline-block absolute text-center right-10 bottom-0 z-3 bg-blur text-white rounded-md flex-col items-center p-5">
            {weatherNow?.skyStatus && (
              <Icon
                icon={getSkyStatus(weatherNow?.skyStatus)?.icon}
                color="#fff"
                className={`${styles.mainWeatherIcon} mb-1`}
              />
            )}
            {weatherNow?.temperature && (
              <p className={`${styles.mainTemperature} font-light mb-2`}>
                {weatherNow?.temperature?.value}&deg;
              </p>
            )}
            <Text as="ui2" className="uppercase">
              {`${upperCaseFirstLetter(todaysDayOfWeek)} ${
                todaysDayOfMonth < 10
                  ? `0${todaysDayOfMonth}`
                  : todaysDayOfMonth
              } ${upperCaseFirstLetter(todaysMonthAndYear)}`}
            </Text>
          </div>
        </div>
      )}
    </div>
  );
}
