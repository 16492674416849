import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import * as stylesStory from '../Story.module.scss';
import * as styles from './FaqBlock.module.scss';
import Text from "@/common/components/Core/Text";

const FaqBlock = ({ blok }) => {
  const faqItems = blok.faq_item && blok.faq_item.filter((faqItem) => faqItem.question);

  return (
    <div
      className={`${stylesStory.column} ${styles.sFaqBlock} s-FaqBlock relative z-2 bg-background-beige w-full`}
      data-id={blok._uid}
      {...storyblokEditable(blok)}
    >
      {faqItems && <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: faqItems.map((faqItem) => ({
              '@type': 'Question',
              name: faqItem.question,
              acceptedAnswer: {
                '@type': 'Answer',
                text: faqItem.answer,
              },
            })),
          }),
        }}
      ></script>}
      <div className="container flex flex-col lg:flex-row lg:gap-x-3">
        <div className="w-full lg:w-5/12 lg:sticky top-0">
          <Text as="h6" className="lg:max-w-lg">{blok.title}</Text>
        </div>
        <div className="w-full mt-10 lg:mt-0 lg:w-7/12">
          {faqItems && faqItems.map((faqItem, faqItemIndex) => <StoryblokComponent blok={faqItem} key={faqItem._uid} className={`${faqItemIndex === 0 ? 'border-t' : ''}`} />)}
        </div>
      </div>
    </div>
  );
};

export default FaqBlock;
