import { storyblokEditable } from "@storyblok/react";
import Text from "../../../Core/Text";
import * as styles from './TableCol.module.scss';

const TableCol = ({ blok }) => {
  return (
    <td
      data-id={blok._uid}
      {...storyblokEditable(blok)}
      className="px-4 py-3 lg:px-6 lg:py-4"
    >
      <Text
        as="body2"
        className={`${styles.sTableCol} s-tableCol w-full`}
      >
        {blok.value}
      </Text>
    </td>
  );
};

export default TableCol;
