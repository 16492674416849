import React, { useCallback, useEffect, useMemo, useState } from "react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Head from "next/head";
import dynamic from "next/dynamic";
import * as styles from "./HomeHikingPage.module.scss";
// import Hero from "@/common/components/Hero";
// import * as stylesHero from "@/common/components/Hero/Hero.module.scss";
import ImageHeader from "@/public/webcam-bg.png";
import CardHiking from "@/common/components/Card/Hiking";
const DynamicMap = dynamic(() => import("@/common/components/HikingMap"), {
  loading: () => <p>A map is loading...</p>,
  ssr: false,
});
import Icon from "@/utils/Icon";
import Text from "../../../Core/Text";
import Button from "@/common/components/Core/Button";
import Modal from "../../../Core/Modal";
import Checkbox from "../../../Core/Checkbox";
import { PER_PAGE } from "@/hooks/useEvents";
import { useRouter } from "next/router";
import { upperCaseFirstLetter } from "@/utils/format";
import { getLangValue } from "@/utils/global";

import useTranslation from "next-translate/useTranslation";
// import { useLenis } from "@studio-freight/react-lenis";
import Image from "next/image";

import Hero from "@/common/components/Hero";
import * as stylesHero from "@/common/components/Hero/Hero.module.scss";

export default function HomeHikingPage({
  blok,
  settings,
  apidaeData,
  nestedData,
  randomSeed,
}) {
  const { t } = useTranslation();

  let title =
    blok.hero && blok.hero[1]
      ? blok.hero[1]?.title + ` - ${t("common:courchevel")}`
      : blok.hero && blok.hero[0]
        ? blok.hero[0].title + ` - ${t("common:courchevel")}`
        : t("common:courchevel");
  let description =
    blok.hero && blok.hero[1]
      ? blok.hero[1]?.title + ` - ${t("common:courchevel")}`
      : blok.hero && blok.hero[0]
        ? blok.hero[0].title + ` - ${t("common:courchevel")}`
        : t("common:courchevel");
  let robots = "";
  if (blok?.meta_title) {
    title = blok.meta_title;
  }
  if (blok?.meta_description) {
    description = blok.meta_description;
  }
  if (blok?.hide_from_search) {
    robots = blok.hide_from_search ? "noindex, nofollow" : "index, follow";
  }

  // const lenisContext = useLenis();
  const { numFound, objetsTouristiques, query } = apidaeData;
  let empty = false;
  const { locale } = useRouter();
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [allObjects, setAllObjects] = useState(objetsTouristiques || []);

  const [hoverCenter, setHoverCenter] = useState(null);

  // map position
  const center = { lat: 45.4146509, lng: 6.63469 };

  // selected filters
  const [selectedFilters, setSelectedFilters] = useState(new Map());
  const [previousFilters, setPreviousFilters] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const blokFilters = useMemo(() => blok.filters || [], [blok.filters]);
  const hasFilterActive = useMemo(
    () => selectedItems.length > 0,
    [selectedItems]
  );

  // filter functions
  const clearFilters = () => {
    setSelectedItems([]);

    const filters = new Map();
    for (let i = 0; i < blokFilters.length; i++) {
      const filter = blokFilters[i];

      filters.set(filter.unique_identifier, []);
    }

    setSelectedFilters(filters);
  };

  const filteredData = filterContent(allObjects);

  const MAX_FILTERS_TO_SHOW = 6;

  // show all filters
  const [showAllCommunes, setShowAllCommunes] = useState(false);
  const [showAllUnivers, setShowAllUnivers] = useState(false);
  const [showAllTerritoires, setShowAllTerritoires] = useState(false);

  // set filters

  useEffect(() => {
    const filters = new Map();
    for (let i = 0; i < blokFilters.length; i++) {
      const filter = blokFilters[i];

      filters.set(filter.unique_identifier, []);
    }

    setSelectedFilters(filters);
  }, [blokFilters]);

  // Handle modal and filters
  const handleOpenModal = () => {
    setModalOpen(true);
    // lenisContext.stop();
  };
  const handleCloseModal = () => {
    setModalOpen(false);
    if (typeof requestAnimationFrame !== 'undefined') {
      // lenisContext.start();
    }
  };
  const handleApplyFilters = async () => {
    await onFilterHiking();
    handleCloseModal();
  };
  const handleFilterChange = (groupKey, value) => {
    const selectedFiltersCopy = new Map(selectedFilters);
    const selectedFiltersGroup = selectedFiltersCopy.get(groupKey);

    const indexValue = selectedFiltersGroup.findIndex(
      (item) => item.id === value.id
    );

    if (indexValue > -1) {
      selectedFiltersGroup.splice(indexValue, 1);

      const indexItem = selectedItems.findIndex((item) => item.id === value.id);
      setSelectedItems([
        ...selectedItems.slice(0, indexItem),
        ...selectedItems.slice(indexItem + 1),
      ]);
    } else {
      selectedFiltersGroup.push(value);

      setSelectedItems([...selectedItems, value]);
    }
    setSelectedFilters(selectedFiltersCopy);
  };
  const handleRemoveFilter = (groupKey, value) => {
    const selectedFiltersCopy = new Map(selectedFilters);
    const selectedFiltersGroup = selectedFiltersCopy.get(groupKey);

    const indexValue = selectedFiltersGroup.findIndex(
      (item) => item.id === value.id
    );
    if (indexValue > -1) {
      selectedFiltersGroup.splice(indexValue, 1);
      setSelectedFilters(selectedFiltersCopy);
    }

    const indexItem = selectedItems.findIndex((item) => item.id === value.id);
    if (indexItem > -1) {
      setSelectedItems([
        ...selectedItems.slice(0, indexItem),
        ...selectedItems.slice(indexItem + 1),
      ]);
    }

    onFilterHiking();
  };

  // render filters options
  const renderFilterOptions = (options, handleChange, type) => {
    let limit = MAX_FILTERS_TO_SHOW;
    // switch (type) {
    //     case 'difficulty':
    //         limit = showAllCommunes ? options.length : MAX_FILTERS_TO_SHOW;
    //         break;
    //     case 'environment':
    //         limit = showAllUnivers ? options.length : MAX_FILTERS_TO_SHOW;
    //         break;
    //     case 'duration':
    //         limit = showAllTerritoires ? options.length : MAX_FILTERS_TO_SHOW;
    //         break;
    //     default:
    //         break;
    // }
    let visibleOptions = options.slice(0, limit);

    return (
      <div className="flex flex-wrap justify-between w-11/12">
        {visibleOptions.map((item, index) => (
          <Checkbox
            key={`${index}-${type}`}
            item={{
              id: item.id || item._uid,
              component: item.component,
              label: item.name,
              type: item.type,
              type_key: item.type_key || item.critere_key,
              value: item.value,
              parent: type,
            }}
            index={index}
            selectedItems={selectedFilters.get(type)}
            handleChange={handleChange}
            type={type}
            className="md:w-1/2 mt-4"
          />
        ))}
      </div>
    );
  };

  const onItemHover = (position) => {
    if (
      position &&
      (!hoverCenter ||
        (position.lat &&
          position.lng &&
          position.lat !== hoverCenter?.lat &&
          position.lng !== hoverCenter?.lng))
    ) {
      setHoverCenter(position);
    } else if (!position) {
      setHoverCenter(null);
    }
  };

  const onSeeMore = async () => {
    setIsLoading(true);

    try {
      const newPage = page + 1;

      if (newPage * PER_PAGE < numFound) {
        let newObjectsUrl = `/api/apidae/get-activity?first=${newPage * PER_PAGE
          }&selectionIds=["115439"]&territoireIds=82490&responseFields=["@minimal","@informationsObjetTouristique","presentation.descriptifCourt","identifier","illustrations","localisation","prestations.typesClientele"]&locales=${locale},en`
        if (randomSeed) {
          newObjectsUrl += `&order=RANDOM&randomSeed=${randomSeed}`
        }
        const newObjects = await fetch(newObjectsUrl);

        if (!newObjects.ok) {
          throw new Error("onSeeMore", newObjects);
        }

        const newObjectsJson = await newObjects.json();
        const newObjectsTouristiques = newObjectsJson.objetsTouristiques;

        setAllObjects([...allObjects, ...newObjectsTouristiques]);
        setPage(newPage);
      }
    } catch (error) {
      console.error("onSeeMore", error);
    }
    setIsLoading(false);
  };

  const onFilterHiking = async () => {
    const filtersToUse = selectedFilters;

    // Check that the filters are not the same as the previous ones
    if (JSON.stringify(selectedItems) === JSON.stringify(previousFilters)) {
      return;
    }

    setIsLoading(true);

    let criteresQuery = "";
    let criteresCustom = [];
    filtersToUse.forEach((value, key, indexGroup) => {
      if (value.length > 0) {
        if (indexGroup > 0) {
          criteresQuery += "+";
        }
        // criteresQuery += `critere:`;
        for (let i = 0; i < value.length; i++) {
          if (value[i].component === "critere_item") {
            criteresQuery += `critere:${value[i].type_key}_${value[i].id}+`;
          } else if (value[i].component === "critere_item_custom") {
            criteresCustom.push(value[i]);
          }
        }
      }
    });
    //console.log("criteresQuery", criteresQuery);
    // criteresQuery = `"${criteresQuery}"`
    try {
      let newObjects;

      if (criteresCustom.length > 0) {
        newObjects = await fetch(
          `/api/apidae/get-activity?selectionIds=["115439"]&first=${page * PER_PAGE
          }&count=100&locales=${locale},en&territoireIds=82490&responseFields=["@minimal","@informationsObjetTouristique","presentation.descriptifCourt","identifier","illustrations","localisation","prestations.typesClientele"]&criteresQuery=${criteresQuery ? criteresQuery : ""
          }`
        );
      } else {
        newObjects = await fetch(
          `/api/apidae/get-activity?selectionIds=["115439"]&first=${page * PER_PAGE
          }&count=40&locales=${locale},en&territoireIds=82490&responseFields=["@minimal","@informationsObjetTouristique","presentation.descriptifCourt","identifier","illustrations","localisation","prestations.typesClientele"]&criteresQuery=${criteresQuery ? criteresQuery : ""
          }`
        );
      }

      if (!newObjects.ok) {
        throw new Error("onFilterHiking", newObjects);
      }

      const newObjectsJson = await newObjects.json();
      const newObjectsTouristiques = newObjectsJson.objetsTouristiques;

      setAllObjects([...newObjectsTouristiques]);
      setPage(0);
    } catch (error) {
      console.error("onFilterHiking", error);
    }

    setPreviousFilters(selectedItems);

    setIsLoading(false);
  };

  //function
  function filterContent(content) {
    let criteresCustom = [];
    selectedItems.map((value, index) => {
      if (value.component === "critere_item_custom") {
        criteresCustom.push(value);
      }
    });

    const filtered = allObjects.filter((item) => {
      let critereMatch = [];
      for (let i = 0; i < criteresCustom.length; i++) {
        const critere = criteresCustom[i];
        let siblings = [];
        let indexAmongSiblings = null;
        blokFilters.map((filterGroup) => {
          if (filterGroup.unique_identifier === critere.parent) {
            const critereInGroup = filterGroup?.critere_items?.findIndex(
              (item) =>
                item.id && item.id !== ""
                  ? item.id === critere.id
                  : item._uid === critere.id
            );

            if (critereInGroup) {
              siblings = [...filterGroup.critere_items];
              indexAmongSiblings = critereInGroup;
            }
          }
        });

        const parsedValue = parseInt(critere.value);

        if (!isNaN(parsedValue)) {
          if (indexAmongSiblings !== null) {
            let previousCritere = siblings[indexAmongSiblings - 1];
            if (!isNaN(parseInt(previousCritere?.value))) {
              if (
                item?.informationsEquipement?.itineraire[critere.type_key] >
                parseInt(previousCritere?.value) &&
                item?.informationsEquipement?.itineraire[critere.type_key] <=
                parsedValue
              ) {
                critereMatch.push(true);
              }
            } else {
              if (
                item?.informationsEquipement?.itineraire[critere.type_key] <=
                parsedValue
              ) {
                critereMatch.push(true);
              }
            }
          } else {
            if (
              item?.informationsEquipement?.itineraire[critere.type_key] <=
              parsedValue
            ) {
              critereMatch.push(true);
            }
          }
        } else {
          if (
            item?.informationsEquipement?.itineraire[critere.type_key] ===
            critere.value
          ) {
            critereMatch.push(true);
          }
        }
      }

      if (criteresCustom.length > 0) {
        if (critereMatch.length > 0) {
          // If critereMatch array contains all true, then the item is a match
          if (critereMatch.every((item) => item === true)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
    if (filtered.length === 0) {
      return content;
    } else {
      if (filtered.length === 0) {
        empty = true;
      }
      return filtered;
    }
  }

  // const createContentStoryblok = async () => {
  //   // Loop over all the objects and push all the documents into an array
  //   const documents = [];
  //   for (let i = 0; i < allObjects.length; i++) {
  //     const obj = allObjects[i];
  //     const objDoc =
  //       obj?.multimedias?.filter((multimedia) => multimedia.type === "PLAN") ||
  //       [];

  //     // objDoc is an array of documents
  //     // Loop over the array and push all the documents into the documents array
  //     for (let j = 0; j < objDoc.length; j++) {
  //       const doc = objDoc[j];
  //       documents.push(doc);
  //     }
  //   }

  //   // Loop over the documents array and upload each document to Storyblok
  //   for (let i = 0; i < documents.length; i++) {
  //     const doc = documents[i];
  //     // let docFile = null;

  //     for (let j = 0; j < doc.traductionFichiers.length; j++) {
  //       const fichier = doc.traductionFichiers[j];
  //       if (fichier?.url) {
  //         console.log("Upload", fichier?.fileName + "." + fichier?.extension);

  //         const response = await storyblokMapi.post(
  //           `spaces/${process.env.STORYBLOK_SPACE_ID}/assets/`,
  //           {
  //             filename: fichier?.fileName + "." + fichier?.extension,
  //             asset_folder_id: "268293",
  //           }
  //         );

  //         if (!response || !response.status === 200) {
  //           console.error("Error uploading document", response);
  //         }

  //         if (response) {
  //           await fileUpload(response.data, fichier?.url);
  //         }
  //       }
  //     }
  //   }
  // };

  // const fileUpload = (signed_response_object, file) => {
  //   let form = new FormData();
  //   // apply all fields from the signed response object to the second request
  //   for (let key in signed_response_object?.fields) {
  //     form.append(key, signed_response_object?.fields[key]);
  //   }
  //   // also append the file read stream
  //   form.append("file", new ReadableStream(file));
  //   // submit your form
  //   // const request = new XMLHttpRequest()
  //   // request.open('POST', signed_response_object.post_url)
  //   // request.send(form)
  //   fetch(signed_response_object?.post_url, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //     body: new URLSearchParams(form),
  //   }).then((res, err) => {
  //     if (err) throw err;

  //     // 3. finalize the upload
  //     storyblokMapi
  //       .get(
  //         `spaces/${process.env.STORYBLOK_SPACE_ID}/assets/` +
  //         signed_response_object?.id +
  //         "/finish_upload"
  //       )
  //       .then((response) => {
  //         console.log(
  //           "https://a.storyblok.com/" +
  //           signed_response_object?.fields?.key +
  //           " uploaded!"
  //         );
  //       })
  //       .catch((error) => {
  //         throw error;
  //       });
  //     console.log(
  //       "https://a.storyblok.com/" +
  //       signed_response_object?.fields?.key +
  //       " uploaded!"
  //     );
  //   });
  // };

  const SelectedFilters = () => {
    // Loop over the selectedFilters map and create buttons for each filter
    const buttons = [];

    selectedFilters.forEach((value, key) => {
      for (let i = 0; i < value.length; i++) {
        const valueItem = value[i];
        buttons.push(
          <button
            key={`sf-${key}${i}`}
            className={`${styles.filterReset} nowrap border border-track-blue ml-2 rounded-full flex items-center bg-button-h3/[.4] transition hover:opacity-70 py-1 px-2`}
            onClick={() => handleRemoveFilter(key, valueItem)}
          >
            <Icon
              icon="icon-cross-16"
              className="transition-transform duration-300 ease-in-out mr-1"
              size={17}
            />
            <Text as="body3" className="whitespace-nowrap">
              {valueItem?.label}
            </Text>
          </button>
        );
      }
    });

    return buttons;
  };

  // todo: translate and dynamise
  return (
    <div className={styles.homeHikingPage} {...storyblokEditable(blok)}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        {robots && <meta name="robots" content={robots} />}
      </Head>
      <Hero content={blok.hero} {...storyblokEditable(blok.hero)} settings={settings} />
      <div className={stylesHero.heroPlaceholder}></div>
      {settings &&
        settings.content &&
        settings.content.infobar &&
        settings.content.infobar[0] && (
          <StoryblokComponent blok={settings.content.infobar[0]} />
        )}
      <div className={styles.homeHikingPageWrapper}>
        <div
          className={`${styles.hikingPageFilterbar} sticky bg-white border-b`}
        >
          <div className="container pt-4 pb-4 flex justify-between items-center">
            <div className="flex items-center py-1">
              {/* <div className="hidden lg:block mr-6 whitespace-nowrap">
                                {t('common:actions.filter')}
                            </div> */}
              <div className="flex items-center">
                <button
                  onClick={handleOpenModal}
                  className={`${styles.filter} flex items-center mr-6 relative hover:text-track-red`}
                >
                  <Text as="body2" className="mr-3 whitespace-nowrap">
                    {t("common:actions.filter")}
                  </Text>
                  <Icon
                    icon="icon-mountain-24"
                    className={hasFilterActive ? "!fill-track-red" : ""}
                    size={24}
                  />
                  {hasFilterActive ? (
                    <Text
                      as="ui3"
                      className="number absolute rounded-full bg-track-red flex items-center justify-center w-4 h-4 text-white text-xs pointer-events-none right-0 top-0 -translate-y-1 translate-x-3"
                    >
                      {selectedItems.length}
                    </Text>
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </div>
            <div className="items-center flex overflow-auto">
              <SelectedFilters />
            </div>
          </div>
        </div>
        <div className="content-blocks relative bg-white z-10">
          <div
            className={`${styles.hikingPageContent} container px-0 lg:px-10`}
          >
            <div className="lg:flex gap-10">
              {allObjects?.length && (
                <div
                  className={`${styles.map} sticky top-[135px] flex justify-end lg:min-w-[50%] lg:w-1/2`}
                >
                  <div className={`${styles.map__Container}`}>
                    <DynamicMap
                      width={"100%"}
                      height={"100%"}
                      center={center}
                      hoverCenter={hoverCenter}
                      borderRadius={0}
                      markers={filteredData}
                    />
                  </div>
                </div>
              )}
              <div
                className={`${styles.list} rounded-[16px] lg:rounded-none relative flex lg:max-w-[50%] bg-white px-6 lg:px-0 flex-grow flex-col gap-y-4 pt-7 lg:pt-10`}
              >
                {allObjects?.length &&
                  filteredData.map((item) => (
                    <CardHiking
                      key={`${item.id}-card`}
                      data={item}
                      className="lg:col-span-4"
                      onItemHover={onItemHover}
                    />
                  ))}
                {empty && (
                  <div className="flex justify-center items-center h-96">
                    <Text as="h6">{t("common:no-results")}</Text>
                  </div>
                )}

                {numFound > PER_PAGE && allObjects.length < numFound && (
                  <div className="w-full text-center mt-14 mb-14">
                    <Button
                      variant={isLoading ? "disabled" : "phantom"}
                      onClick={onSeeMore}
                    >
                      {t("common:actions.see-more")}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modalOpen} onClose={handleCloseModal}>
        <div className="sticky top-0 bg-white z-1 text-center pt-6 pb-5 px-8 border-b">
          <Text as="nav">{t("common:actions.filter-by-place")}</Text>
          <Icon
            icon="icon-cross-16"
            size={24}
            className="cursor-pointer absolute right-8 bottom-0 top-0 m-auto transition-transform duration-300 ease-in-out mr-1"
            onClick={handleCloseModal}
          />
        </div>
        {blok.filters?.map((filterGroup) => (
          <div
            key={filterGroup._uid}
            className="border-b px-8 pt-7 md:pt-9 pb-8"
          >
            <Text as="h6">{filterGroup.title}</Text>
            {renderFilterOptions(
              filterGroup.critere_items,
              handleFilterChange,
              filterGroup.unique_identifier
            )}
            {/* {communes.length > MAX_FILTERS_TO_SHOW && (
                        <Text as="body2" onClick={() => handleToggleShow('commune')} className="text-button-h4 mt-6 cursor-pointer transition lg:hover:opacity-50">
                            {showAllCommunes ? 'Afficher moins' : 'Afficher plus'}
                        </Text>
                    )} */}
          </div>
        ))}
        <div className="sticky bottom-0 z-1 bg-white flex justify-between items-center border-t px-8 py-4">
          {hasFilterActive ? (
            <Text
              as="button1"
              onClick={clearFilters}
              className={"cursor-pointer transition lg:hover:opacity-50"}
            >
              {t("common:actions.clear-all")}
            </Text>
          ) : (
            ""
          )}
          <Button onClick={handleApplyFilters} className={"mr-0 ml-auto"}>
            {t("common:actions.apply")}
          </Button>
        </div>
      </Modal>
    </div>
  );
}
