import { LRUCache } from "lru-cache";

const context = {
  cache: new LRUCache({
    max: 50,
    maxAge: 3600,
  }),
};

const cache = (handler) => (req, res) => {
  req.cache = context.cache;

  return handler(req, res);
};

export default cache;
