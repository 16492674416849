import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
// import { ReactLenis, useLenis } from "@studio-freight/react-lenis";
import * as styles from "./ActivityFilterDropdown.module.scss";
import { upperCaseFirstLetter } from "@/utils/format";
import { getLangValue } from "@/utils/global";

const { default: Icon } = require("@/utils/Icon");
const { default: Checkbox } = require("../Core/Checkbox");

const ActivityFilterDropdown = ({
  filter,
  selectedFilters,
  onFilterActivities,
  onSelectFilter,
}) => {
  const refFilterDropdown = useRef(null);
  const { locale } = useRouter();
  const [filterOpen, setFilterOpen] = useState(false);

  const langKeyTrad = `libelle${upperCaseFirstLetter(locale)}`;

  const handleClickOutside = useCallback(() => {
    if (filterOpen) {
      setFilterOpen(false);
      // onFilterActivities();
    }
  }, [filterOpen, onFilterActivities]);

  useEffect(() => {
    const handler = (e) => {
      const element = refFilterDropdown.current;
      if (element && !element.contains(e.target)) {
        handleClickOutside();
      }
    };

    document.addEventListener("click", handler);

    return () => {
      document.removeEventListener("click", handler);
    };
  }, [handleClickOutside]);

  const handleClickFilter = () => {
    if (!filterOpen) {
      setFilterOpen(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setFilterOpen(true);
    }
  };

  const handleAddFilter = (value) => {
    onSelectFilter([...selectedFilters, value]);

    onFilterActivities([...selectedFilters, value]);
  };

  const handleToggleFilter = (type, value) => {
    if (selectedFilters.find(filter => filter.id === value.id) || selectedFilters.includes(value)) {
      onSelectFilter(selectedFilters.filter((item) => item.id !== value.id));
      onFilterActivities(selectedFilters.filter((item) => item.id !== value.id));
    } else {
      handleAddFilter(value);
    }

  };

  return (
    <div ref={refFilterDropdown} className="relative">
      <button
        onClick={handleClickFilter}
        onKeyDown={handleKeyDown}
        type="button"
        id="filterMenuButton"
        className={`${styles.dropdownTrigger} flex items-center cursor-pointer gap-3`}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded={filterOpen}
      >
        {/* {t("actions.filter")} */}
        {filter?.label}

        <Icon icon="icon-filter-24" size={24} />
      </button>
      <div
        className={`${styles.dropdownMenu} hidden absolute right-0 p-4 lg:p-6 rounded-md drop-shadow-md z-10`}
        aria-labelledby="filterMenuButton"
      >
        {filter.elementReferences?.map((element, elementIndex) => (
          <Checkbox
            key={`${elementIndex}-${element.elementReferenceType}`}
            item={{
              id: element.id,
              label: getLangValue(element, langKeyTrad),
              elementReferenceType: element.elementReferenceType,
            }}
            index={elementIndex}
            selectedItems={selectedFilters}
            handleChange={handleToggleFilter}
            type={element.elementReferenceType}
            filterSlug={filter.slug}
            className="mt-2"
          />
        ))}
      </div>
    </div>
  );
};

export default ActivityFilterDropdown;
