import { storyblokEditable } from "@storyblok/react";
import * as stylesStory from "../Story.module.scss";
import Text from "../../Core/Text";
import ArrowLink from "../../Core/ArrowLink";
import Image from "next/image";

const ServicesBlock = ({ blok }) => {
  // console.log(blok);
  return (
    <div
      className={`${stylesStory.column} column servicesBlock`}
      {...storyblokEditable(blok)}
    >
      <div className="container relative z-2 lg:mt-10 mb-4">
        <div className="lg:flex justify-between gap-x-3">
          <div className="lg:w-1/2">
            <Text as="h3" tag="h2">
              {blok.title}
            </Text>
            <Text as="body1" className="mt-3 lg:mt-5 text-text-h4 max-w-md">
              {blok.subtitle}
            </Text>

            {blok.cta && blok.cta[0] && blok.cta[0].link && (
              <ArrowLink
                className="mt-4 lg:mt-6 text-buttonColor-primary"
                link={blok?.cta[0]?.link}
              >
                {blok.cta[0].label}
              </ArrowLink>
            )}
          </div>
          <div className="lg:w-1/2 grid grid-cols-2 lg:grid-cols-3 gap-x-3 gap-y-10 lg:gap-y-14 mt-14 lg:mt-0">
            {blok.list &&
              blok.list.map((listItem, listItemIndex) => (
                <div key={`${listItem._uid}-${listItemIndex}`}>
                  <div className="w-12 h-12 mb-4 bg-gray-200">
                    {listItem.icon && (
                      <Image
                        src={listItem.icon.filename}
                        width={48}
                        height={48}
                        alt={listItem.icon.alt}
                      />
                    )}
                  </div>
                  <Text as="button1" className="mb-1">
                    {listItem.title}
                  </Text>
                  <Text as="body3" className="text-text-h4 pr-2 lg:pr-4">
                    {listItem.desc}
                  </Text>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesBlock;
