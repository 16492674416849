import React, { useEffect, useState } from "react";
import Image from "next/image";
import * as styles from "./CardStay.module.scss";
import * as stylesCard from "../Card.module.scss";
import Text from "../../Core/Text";
import ArrowLink from "../../Core/ArrowLink";
import Link from "next/link";
import { SEASON_WINTER } from "@/utils/global";
import useTranslation from "next-translate/useTranslation";
import CustomLink from "../../CustomLink";

export default function CardStay(props) {
  const { t } = useTranslation("common");
  const { content, className, variant } = props;
  const [imageBySeason, setImageBySeason] = useState(null);

  useEffect(() => {
    let seasonMode = SEASON_WINTER;

    if (typeof window !== "undefined") {
      seasonMode = localStorage.getItem("seasonMode");
    }

    const images = content.image?.length > 0 ? content.image : [content.image];
    const image =
      seasonMode === SEASON_WINTER
        ? images[0]
        : images.length > 1
        ? images[1]
        : images[0];

    setImageBySeason(image);
  }, [content]);

  return (
    <CustomLink
      link={content?.link || ""}
      className={`${className} ${stylesCard.card} ${styles.cardStay} ${styles[variant]} relative`}
    >
      <div
        className={`${stylesCard.cardImage} ${styles.cardImage} relative overflow-hidden rounded-xl mb-5 bg-gray-100`}
      >
        {imageBySeason?.filename && (
          <Image
            src={imageBySeason.filename}
            alt={imageBySeason.alt || "missing alt"}
            fill
            sizes="(max-width: 768px) 80vw, (max-width: 1024px) 50vw, 33vw"
            className="object-cover"
          />
        )}
      </div>
      <Text as="h6" tag="h3" className="mb-2">
        {content.title}
      </Text>
      <Text as="body2" className={stylesCard.cardDescription}>
        {content.description}
      </Text>
      {content.link && (
        <ArrowLink className="mt-4 text-buttonColor-primary">
          {t("actions.explore")}
        </ArrowLink>
      )}
    </CustomLink>
  );
}
