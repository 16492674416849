import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";

import Facebook from "@/public/icons/socials/facebook.svg";
import Instagram from "@/public/icons/socials/instagram.svg";
import Twitter from "@/public/icons/socials/twitter.svg";
import Spotify from "@/public/icons/socials/spotify.svg";
import Tiktok from "@/public/icons/socials/tiktok.svg";
import Youtube from "@/public/icons/socials/youtube.svg";
import Pinterest from "@/public/icons/socials/pinterest.svg";
import { useRouter } from "next/router";
import Icon from "@/utils/Icon";
import Text from "../../Core/Text";
export const icons = [
  {
    name: "facebook",
    icon: <Icon icon="icon-facebook" size={20} />,
  },
  {
    name: "instagram",
    icon: <Icon icon="icon-instagram" size={20} />,
  },
  {
    name: "twitter",
    icon: <Icon icon="icon-twitter" size={20} />,
  },
  {
    name: "spotify",
    icon: <Icon icon="icon-spotify" size={20} />,
  },
  {
    name: "tiktok",
    icon: <Icon icon="icon-tiktok" size={20} />,
  },
  {
    name: "youtube",
    icon: <Icon icon="icon-youtube" size={20} />,
  },
  {
    name: "pinterest",
    icon: <Icon icon="icon-pinterest" size={20} />,
  },
];

const Cta = ({
  blok,
  dataindex,
  className,
  textStyle = "body2",
  onMouseEnter,
  role = null,
}) => {
  const { locale } = useRouter();

  const handleMouseEnter = (e) => {
    if (onMouseEnter) {
      onMouseEnter(e);
    }
  };

  const escapedUrl =
    blok.link.linktype === "url" || blok.link.linktype === "asset"
      ? blok.link.url
      : blok.link.cached_url && blok.link.cached_url === "//"
      ? "/"
      : blok.link.cached_url.includes(`/${blok.link.cached_url}`)
      ? blok.link.cached_url
      : blok.link.cached_url.startsWith("/")
      ? blok.link.cached_url
      : `/${blok.link.cached_url}`;

  return (
    <Link
      href={escapedUrl}
      {...storyblokEditable(blok)}
      data-index={dataindex}
      className={className}
      target={blok.link.target}
      rel={blok.link.target === "_blank" ? "noopener noreferrer" : ""}
      onMouseEnter={handleMouseEnter}
      locale={locale}
      aria-label={escapedUrl}
      role={role}
    >
      {blok.label && <Text as={textStyle}>{blok.label}</Text>}
      {blok.icon ? icons.find((icon) => icon.name === blok.icon).icon : null}
      {blok.image && blok.image.filename && (
        <img src={blok.image.filename} alt={blok.image.alt} />
      )}
    </Link>
  );
};
export default Cta;
