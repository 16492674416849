import { useCallback, useEffect, useRef, useState } from "react";
import Icon from "@/utils/Icon";
import Image from "next/image";
import * as styles from "../../Drawer.module.scss";
import PlaceHolderImg from "@/public/card-placeholder.webp";

const WebcamItem = ({
  webcam,
  renderedWebcams,
  onSetRenderedWebcams,
  selectedWebcam,
  isSliding,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [inView, setInView] = useState(false);
  const [viewerIsLoaded, setViewerIsLoaded] = useState(false);
  const webcamRef = useRef(null);

  const onLoadedWebcamViewer = () => {
    console.log("loaded");
  };

  const onLoadWebcamViewer = () => {
    setViewerIsLoaded(webcam);
  };

  const setLoaded = useCallback(() => {
    if (inView) setHasLoaded(true);
  }, [inView, setHasLoaded]);

  // Detech of the element is in the viewport, then call setInView
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !inView) {
          const isRendered = renderedWebcams.includes(webcam.id);
          if (!isRendered) {
            setInView(true);
            onSetRenderedWebcams([...renderedWebcams, webcam.id]);
          }
        }
      },
      {
        rootMargin: "0px 0px 100px 0px",
      }
    );

    const webcamRefCurrent = webcamRef.current;
    observer.observe(webcamRefCurrent);
    return () => {
      observer.unobserve(webcamRefCurrent);
    };
  }, [
    webcam.id,
    selectedWebcam,
    inView,
    onSetRenderedWebcams,
    renderedWebcams,
  ]);

  // const isRendered = renderedWebcams.includes(webcam.id)
  // if (!isRendered && webcam.id === selectedWebcam) {
  //   onSetRenderedWebcams([...renderedWebcams, webcam.id])
  // }

  const webcamName = webcam?.name?.data && webcam?.name?.data[0].data;
  let webcamSlug = webcamName && webcamName.split("-")[0].trim().toLowerCase();

  let webcamIframeBase = "https://www.skaping.com/courchevel/";

  if (webcam.type !== "JPG") {
    const mdUrl = webcam.urls[1].url;
    // Write a regex that isolates a filename at the end of a URL
    const mdFilename = mdUrl.match(/[^/]+$/)[0];
    const mdFilenameNoExt = mdFilename.split(".")[0];

    if (webcam.id === "508") {
      webcamSlug = "https://m.webcam-hd.com/la-tania/chalet-esf";
    }

    if (mdUrl.match(/roundshot/)) {
      webcamSlug = webcamSlug
        .replace("le ", "")
        .replace("la ", "")
        .replace("les ", "")
        .trim()
        .replaceAll(" ", "-");
      webcamIframeBase = `https://courchevel.roundshot.com/${webcamSlug}`;
    } else if (mdFilenameNoExt) {
      webcamIframeBase = `https://app.webcam-hd.com/courchevel-${mdFilenameNoExt
        ?.split("_")[0]
        ?.replace(" ", "-")}/courchevel-${mdFilenameNoExt
          ?.split("_")[0]
          ?.replace(" ", "-")}_${webcamSlug.replaceAll(" ", "-")}`;
    } else {
      webcamSlug = webcamSlug
        .replace("le ", "")
        .replace("la ", "")
        .replace("les ", "");
      webcamIframeBase = `https://app.webcam-hd.com/courchevel/courchevel-${""}_${webcamSlug}`;
    }
  } else {
    // Remove the first word 'la' or 'le' from the webcam name (only if the string starts with 'la' or 'le')
    if (webcamSlug.match(/croisette/)) {
      webcamSlug = webcamSlug.replaceAll(" ", "-");
    } else {
      webcamSlug = webcamSlug
        .replace("le ", "")
        .replace("la ", "")
        .replace("les ", "")
        .trim()
        .replaceAll(" ", "-");
    }
    webcamIframeBase += webcamSlug;
    // This is a hotfix, not the best solution
    if (webcam.id === "508") {
      webcamIframeBase = "https://m.webcam-hd.com/la-tania/chalet-esf";
    }
  }

  return (
    <div
      ref={webcamRef}
      className={`${styles.webcamItem} webcam-item relative rounded-xl overflow-hidden w-full mb-4 embla__slide`}
      onClick={onLoadWebcamViewer}
    >
      <div
        className={`${styles.sliderItem} ${viewerIsLoaded ? "hidden" : ""
          } bg-white-20 ${webcam.id === selectedWebcam
            ? `${styles.sliderItemActive} opacity-100`
            : ""
          } ${isSliding ? styles.isSliding : ""}`}
        key={`${webcam.id}-${webcam.filename}`}
      >
        <Image
          src={inView ? webcam.urls[0].url : PlaceHolderImg}
          alt={""}
          fill
          onLoad={setLoaded}
          sizes="(max-width: 768px) 100vw, 80vw"
          className={`object-cover`}
        />
      </div>

      {/* {viewerIsLoaded && <ReactPanellum id={viewerIsLoaded.id} sceneId="firstScene" imageSource={viewerIsLoaded.urls[2] ? viewerIsLoaded.urls[2].url : viewerIsLoaded.urls[0].url} config={{
      width: '100%',
      height: '100%',
      autoLoad: true,
      onLoad: onLoadedWebcamViewer
    }} className={`${styles.webcamViewer} absolute z-10`} />} */}
      {viewerIsLoaded && (
        <iframe
          src={webcamIframeBase}
          allowFullScreen
          webkitallowfullscreen="true"
          className={`${styles.webcamViewer} absolute z-10`}
        />
      )}

      {!viewerIsLoaded && (
        <button
          type="button"
          className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 py-1 px-8 h-14 flex items-center justify-center rounded-full bg-white text-buttonColor-primary hover:scale-105 transition-transform duration-300 ease-in-out`}
          aria-expanded="false"
        >
          <Icon icon="icon-webcam-24" size={24} />
        </button>
      )}
    </div>
  );
};

export default WebcamItem;
