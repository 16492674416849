import React from "react";
import * as styles from "./Text.module.scss";

const Text = (props) => {
  const {
    children,
    tag = "p",
    as,
    asDesktop,
    transform,
    className = "",
    ...rest
  } = props;
  const TextTag = tag;

  return (
    <TextTag
      className={`${as ? styles[as] : styles[tag]} ${
        asDesktop ? styles[asDesktop] : ""
      } ${transform ? styles[transform] : ""} ${className}`}
      {...rest}
    >
      {children}
    </TextTag>
  );
};

export default Text;
